import React from "react";
import { useQuery, useMutation, flatten_ids } from "../../Graphql.js";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import gql from "graphql-tag";
import { applySearch } from "../../helpers.js";
import { COMPANIES_QUERY } from "../../Graphql/queries";

import { CompanyFragment } from "../../Graphql/fragments.js";
import { CompanyCreatePage } from "./CompanyCreatePage.js";
import { CompanyEditPage } from "./CompanyEditPage.js";
import { Sidebar, SidebarLink } from "../PageElements.js";
import { check_if_local_env } from "../../helpers";

import {
  Whitespace,
  VerticalList,
  TextInput,
  StyledNavLink,
  Card,
  EmptyState,
  Flex,
} from "../Elements.js";
import { getToken } from "../../constants";
import PhosphorIcon from "../phosphor-icons/phosphor-icon.js";

export const CREATE_COMPANY_MUTATION = gql`
  mutation($token: String!, $item: InputCompany!) {
    createCompany(token: $token, item: $item) {
      ...CompanyFragment
    }
  }
  ${CompanyFragment}
`;

let flatten_company = company => {
  return flatten_ids({ ...company, clients: undefined }, object => {
    if (object.__typename === "Topic") {
      return { topicId: object._id };
    }
    if (object.__typename === "HandlingPlan" || object.__typename === "Todo") {
      return { todoId: object._id };
    }
  });
};

export let CompaniesPage = () => {
  let match = useRouteMatch();
  let [searchQuery, set_searchQuery] = React.useState("");

  let {
    data: { companies: items },
  } = useQuery(COMPANIES_QUERY, {
    variables: { token: getToken() },
    suspend: true,
  });

  let [createCompanyMutation] = useMutation(CREATE_COMPANY_MUTATION);

  const filteredItems = items.filter(
    company =>
      searchQuery === "" ||
      applySearch({
        search: searchQuery.toLowerCase(),
        values: [company.name],
      })
  );

  let saveCompany = async (newItem, history) => {
    let { _id, ...item } = newItem;
    let result = await createCompanyMutation({
      variables: { item: flatten_company(item), token: getToken() },
      refetchQueries: [
        { query: COMPANIES_QUERY, variables: { token: getToken() } },
      ],
      awaitRefetchQueries: true,
      // // Updating cache because graphql does not make new requests if there is information already in cache
      // update: (
      //   cache,
      //   { data: { createCompany } },
      // ) => {
      //   // Read the data from our cache for this query.
      //   const data = cache.readQuery({
      //     query: COMPANIES_QUERY,
      //     variables: { token: getToken() },
      //   });
      //   // Add our created company from the mutation to the end.
      //   data.companies.push({
      //     ...createCompany,
      //     clients: [],
      //   });
      //   // Write our data back to the cache.
      //   cache.writeQuery({
      //     query: COMPANIES_QUERY,
      //     variables: { token: getToken() },
      //     data: data,
      //   });
      // },
    });
    const companyId = result.data.createCompany._id;
    history.push(`${match.url}/${companyId}`);
  };

  return (
    <Flex row style={{ height: "100%" }}>
      <Sidebar title="Companies">
        <Card.Content>
          <Flex row>
            <TextInput
              placeholder="Search companies"
              onChange={e => set_searchQuery(e.target.value)}
              value={searchQuery}
            />
            <Whitespace width={8} />
            <StyledNavLink style={{ padding: 8 }} to={`${match.url}/create`}>
              <PhosphorIcon name="plus" className="icon" />
            </StyledNavLink>
          </Flex>
        </Card.Content>

        <Whitespace height={8} />

        <VerticalList>
          {filteredItems.map(item => (
            <SidebarLink
              to={`${match.url}/${item._id}`}
              key={item._id}
              loaclEnv={check_if_local_env()}
            >
              <SidebarLink.Title>{item.name}</SidebarLink.Title>
              <SidebarLink.Text>
                <div>Clients: {item.clients.length}</div>
                <div style={{ color: item.isActive ? "#1b5e20" : "#b00020" }}>
                  {item.isActive ? "active" : "inactive"}
                </div>
              </SidebarLink.Text>
            </SidebarLink>
          ))}
        </VerticalList>
      </Sidebar>

      <Flex column style={{ flex: 1, overflow: "auto" }}>
        <Switch>
          <Route
            exact
            path={`${match.path}`}
            render={() => (
              <EmptyState icon="domain">Select a company</EmptyState>
            )}
          />

          <Route
            path={`${match.path}/create`}
            render={({ history }) => (
              <CompanyCreatePage
                company={{
                  _id: null,
                  isActive: true,
                  showPrivateBusiness: false,
                  topics: [],
                  branding: {
                    brandColor: null,
                    invertedTextColor: null,
                  },
                  isPilot: false,
                }}
                onSave={newItem => saveCompany(newItem, history)}
              />
            )}
          />
          <Route
            path={`${match.path}/:company_id`}
            render={({
              match: {
                params: { company_id },
              },
            }) => {
              const company = items.find(company => company._id === company_id);
              if (company == null) {
                return <Redirect to={match.url} />;
              }

              return <CompanyEditPage key={company._id} company={company} />;
            }}
          />
        </Switch>
      </Flex>
    </Flex>
  );
};
