import React, { useState } from "react";
import { useMutation, useQuery } from "../Graphql";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { HandlingPlanForm } from "../Components/HandlingPlans/HandlingPlanForm";
import { getToken } from "../constants";
import { HandlingPlanFragment } from "../Graphql/fragments";
import { HANDLING_PLAN_QUERY, SUPPLIERS_LIST_QUERY } from "../Graphql/queries";
import mapSupplier from "../mappers/mapSupplier";
import { UpdateConfirmation } from "../Elements/HelpInfo";

let UPDATE_HANDLING_PLAN_MUTATION = gql`
  mutation($token: String!, $id: ID!, $data: HandlingPlanInput!) {
    updateHandlingPlan(token: $token, _id: $id, data: $data) {
      ...HandlingPlanFragment
    }
  }
  ${HandlingPlanFragment}
`;

const EditHandlingPlan = ({ history, match }) => {
  const { id } = match.params;

  const [updateSuccess, set_updateSuccess] = useState(false);

  const { data: suppliersQuery } = useQuery(SUPPLIERS_LIST_QUERY, {
    variables: { token: getToken() },
    suspend: true,
  });

  const { data: handlingPlanQuery } = useQuery(HANDLING_PLAN_QUERY, {
    variables: { token: getToken(), id },
    suspend: true,
  });

  const [updateHandlingPlan] = useMutation(UPDATE_HANDLING_PLAN_MUTATION, {
    variables: { token: getToken(), id },
  });

  const handlingPlan = handlingPlanQuery.handlingPlan;
  const suppliers = suppliersQuery.allSuppliers.map(supplier =>
    mapSupplier(supplier)
  );

  const tabs =
    handlingPlan.steps?.length > 0
      ? ["info", "content", "phases", "qa"]
      : ["info", "content", "phases", "qa"];

  return (
    <Page>
      <Container>
        <PageHeader>
          <PageTitle>
            <h2>Update to do: {handlingPlan.title}</h2>
          </PageTitle>
        </PageHeader>
        <HandlingPlanForm
          tabs={tabs}
          handlingPlan={handlingPlan}
          suppliers={suppliers}
          onSubmit={async handlingPlan => {
            const { data } = await updateHandlingPlan({
              variables: { data: handlingPlan },
            });
            if (data) {
              set_updateSuccess(true);
              setTimeout(() => {
                set_updateSuccess(false);
              }, 1000);
            }
          }}
          buttonText="Update"
        />
      </Container>
      {updateSuccess && (
        <UpdateConfirmation
          successStatus={updateSuccess}
          content={"Todo updated successfully!"}
        />
      )}
    </Page>
  );
};

export default withRouter(EditHandlingPlan);
