import React from "react";
import emoji from "node-emoji";
import { FormRow, Label, MarkupInput } from "./StepForm";

export const SendMessageStep = ({ step, onChange }) => {
  return (
    <FormRow>
      <Label>Example message</Label>
      <MarkupInput
        value={step.exampleMessage}
        onChange={newValue =>
          onChange("exampleMessage", emoji.emojify(newValue))
        }
        placeholder="Give an example: Great choice! Let's start immediately, stay with me!"
      />
    </FormRow>
  );
};
