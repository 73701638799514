import styled from "styled-components";

export const FormLabel = styled.label`
  padding: 0;
  margin: 0 0 6px;
  font-size: 15px;
  font-weight: ${({ light }) => (light ? "normal" : 800)};
  line-height: 1.27;
  color: #2a2135;
`;
