import React from "react";
import Grid from "@material-ui/core/Grid";
import { TextInput, Select } from "../Elements.js";
import Weekdays from "../../Elements/Weekdays.jsx";

export class SupplierContactPerson extends React.Component {
  render() {
    let { onChange, supplier } = this.props;

    const contactPersonInputHandler = key => event => {
      onChange({
        ...supplier,
        contactPerson: { ...supplier.contactPerson, [key]: event.target.value },
      });
    };

    const reachedHoursInputHandler = (index, key) => event => {
      const newReachHours = Object.assign(
        [...supplier.contactPerson.reachHours],
        {
          [index]: Object.assign({}, supplier.contactPerson.reachHours[index], {
            [key]: event.target.value,
          }),
        }
      );

      onChange({
        ...supplier,
        contactPerson: { ...supplier.contactPerson, reachHours: newReachHours },
      });
    };

    const reachedHoursCheckboxHandler = index => event => {
      const newReachHours = Object.assign(
        [...supplier.contactPerson.reachHours],
        {
          [index]: Object.assign({}, supplier.contactPerson.reachHours[index], {
            isOpen: event.target.checked,
          }),
        }
      );

      onChange({
        ...supplier,
        contactPerson: { ...supplier.contactPerson, reachHours: newReachHours },
      });
    };

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              {contactPersonInputs.map(input => (
                <Grid key={input.key} item xs={6}>
                  <label>{input.label}</label>
                  <TextInput
                    value={supplier.contactPerson[input.key] || ""}
                    onChange={contactPersonInputHandler(input.key)}
                  />
                </Grid>
              ))}
              <Grid item xs={6}>
                <label>Gender</label>
                <Select
                  style={{ width: "100%" }}
                  value={supplier.contactPerson.gender}
                  items={[
                    { title: "Unknown", value: null },
                    { title: "Male", value: "male" },
                    { title: "Female", value: "female" },
                  ]}
                  onChange={contactPersonInputHandler("gender")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <label>Reachable hours</label>
            <Weekdays
              weekdays={supplier.contactPerson.reachHours}
              onCheckboxChange={reachedHoursCheckboxHandler}
              onTimeChange={reachedHoursInputHandler}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const contactPersonInputs = [
  {
    key: "firstName",
    label: "First name",
  },
  {
    key: "lastName",
    label: "Last name",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "phoneNumber",
    label: "Phone number",
  },
  {
    key: "other",
    label: "Other",
  },
  {
    key: "eta",
    label: "ETA",
  },
  {
    key: "preferredLanguage",
    label: "Preferred language",
  },
];
