import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { getToken } from "../constants";
import { QuestionAndAnswerForm } from "../Components/QuestionAndAnswers/QuestionAndAnswerForm";
import { QAS_LIST_QUERY } from "./QuestionsAndAnswers";

export class CreateQuestionAndAnswer extends React.Component {
  createQA = mutation => async qa => {
    await mutation({
      variables: {
        token: getToken(),
        data: qa,
      },
      update(cache, { data: { addQuestionAndAnswer: newQA } }) {
        try {
          const { questionsAndAnswers } = cache.readQuery({
            query: QAS_LIST_QUERY,
            variables: { token: getToken() },
          });
          cache.writeQuery({
            query: QAS_LIST_QUERY,
            variables: { token: getToken() },
            data: { questionsAndAnswers: questionsAndAnswers.concat([newQA]) },
          });
        } catch (e) {
          // There is no cache to update since someone directly went to this page.
        }
      },
    });

    this.props.history.push("/questionsAndAnswers");
  };

  render() {
    return (
      <Page>
        <Container>
          <PageHeader>
            <PageTitle>
              <h2>New Q&A</h2>
            </PageTitle>
          </PageHeader>

          <Mutation mutation={CREATE_QA_MUTATION}>
            {addQAMutation => (
              <QuestionAndAnswerForm
                onSubmit={this.createQA(addQAMutation)}
                buttonText="Create"
              />
            )}
          </Mutation>
        </Container>
      </Page>
    );
  }
}

const CREATE_QA_MUTATION = gql`
  mutation($token: String!, $data: AddQuestionAndAnswerInput!) {
    addQuestionAndAnswer(token: $token, data: $data) {
      _id
      question
    }
  }
`;
