import React from "react";
import ReactQuill from "react-quill";
import styled from "styled-components/macro";

require("react-quill/dist/quill.bubble.css");

export class MarkupTextArea extends React.Component {
  allowedFormats = ["bold", "italic", "underline", "link", "list"];
  toolbarButtons = ["bold", "italic", "underline", "link"];

  changeHandler = (content, _delta, _source, editor) => {
    const isEmpty = editor.getText() === "\n";
    const newValue = isEmpty ? "" : content;

    if (content === this.props.value) {
      return;
    }

    this.props.onChange(newValue);
  };

  render() {
    const { label, value, onChange, ...props } = this.props;

    return (
      <Wrapper>
        {label && <InputLabel>{label}</InputLabel>}
        <Editor
          value={value}
          onChange={this.changeHandler}
          theme="bubble"
          hasLabel={Boolean(label)}
          formats={this.allowedFormats}
          modules={{ toolbar: this.toolbarButtons }}
          {...props}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.label`
  display: block;
  border-radius: 4px 4px 0 0;
  position: relative;
  background: rgba(0, 0, 0, 0.09);

  &:hover {
    background-color: rgba(0, 0, 0, 0.13);
  }
`;

const InputLabel = styled.span`
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 6px;
  left: 12px;
  z-index: 1;
  line-height: 1;
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  pointer-events: none;
`;

const Editor = styled(ReactQuill)`
  padding-top: ${({ hasLabel }) => (hasLabel ? "15px" : "inherit")};

  .ql-editor {
    min-height: 100px;
    padding-left: 12px;
    padding-right: 12px;

    p,
    li {
      font-size: 16px;
    }
  }
`;
