import React from "react";
import { SupplierEdit } from "./SupplierEdit.js";
import { Whitespace, Button, MaterialIcon, Card } from "../Elements.js";

export class SupplierCreatePage extends React.Component {
  state = {
    supplier: this.props.supplier,
  };

  render() {
    let { onSave } = this.props;
    let { supplier } = this.state;

    return (
      <Card style={{ display: "flex", flexDirection: "column" }}>
        <Whitespace height={8} />
        <h2>Create new supplier</h2>
        <SupplierEdit
          supplier={supplier}
          onChange={supplier => {
            this.setState({ supplier });
          }}
        />
        <Whitespace height={8} />
        <Button
          style={{ alignSelf: "flex-end" }}
          onClick={() => onSave(supplier)}
        >
          <MaterialIcon name="add" size={18} /> Create
        </Button>
      </Card>
    );
  }
}
