import React from "react";
import styled from "styled-components";
import { Button } from "../Elements";

const BrowserContainer = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  width: 90px;
`;

export const Browser = ({ activeBatch, setActiveBatch, totalBatches }) => {
  return (
    <BrowserContainer>
      <Button
        disabled={activeBatch === 0}
        onClick={() => setActiveBatch(activeBatch - 1)}
        style={{
          width: "40px",
        }}
      >
        {"<"}
      </Button>
      <Button
        disabled={activeBatch === totalBatches - 1}
        onClick={() => setActiveBatch(activeBatch + 1)}
        style={{
          width: "40px",
        }}
      >
        {">"}
      </Button>
    </BrowserContainer>
  );
};
