import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import uniqid from "uniqid";
import { Button, TextArea, MaterialIcon, Whitespace } from "../Elements.js";

export class SupplierProsCons extends React.Component {
  render() {
    let { onChange, supplier } = this.props;

    const inputHandler = (index, isProposal) => event => {
      const entries = supplier.tradeOff.filter(
        x => x.isProposal === isProposal
      );
      const otherEntries = supplier.tradeOff.filter(
        x => x.isProposal !== isProposal
      );
      const newEntries = Object.assign([...entries], {
        [index]: Object.assign({}, entries[index], {
          title: event.target.value,
        }),
      });

      onChange({
        ...supplier,
        tradeOff: [...otherEntries, ...newEntries],
      });
    };

    const addNewEntry = isProposal => {
      onChange({
        ...supplier,
        tradeOff: [
          ...supplier.tradeOff,
          { _id: uniqid(), title: "", isProposal },
        ],
      });
    };

    const removeEntry = (index, isProposal) => {
      const otherEntries = supplier.tradeOff.filter(
        x => x.isProposal !== isProposal
      );
      onChange({
        ...supplier,
        tradeOff: [
          ...otherEntries,
          ...supplier.tradeOff
            .filter(x => x.isProposal === isProposal)
            .slice(0, index),
          ...supplier.tradeOff
            .filter(x => x.isProposal === isProposal)
            .slice(index + 1),
        ],
      });
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h3>Pros</h3>
          {supplier.tradeOff
            .filter(x => x.isProposal)
            .map((step, index) => (
              <Grid key={index} container spacing={2}>
                <Grid item xs={2}>
                  <IconButton onClick={() => removeEntry(index, true)}>
                    <MaterialIcon name="delete" size={18} />
                  </IconButton>
                  {index + 1}.
                </Grid>
                <Grid item xs={10}>
                  <TextArea
                    style={{ height: "auto" }}
                    rows={4}
                    multiline
                    value={step.title || ""}
                    onChange={inputHandler(index, true)}
                  />
                </Grid>
              </Grid>
            ))}

          <Whitespace height={8} />

          <Button onClick={() => addNewEntry(true)}>
            <MaterialIcon name="add" size={18} /> New Pro
          </Button>
        </Grid>
        <Grid item xs={6}>
          <h3>Cons</h3>
          {supplier.tradeOff
            .filter(x => !x.isProposal)
            .map((step, index) => (
              <Grid key={index} container spacing={2}>
                <Grid item xs={2}>
                  {index + 1}.
                  <IconButton onClick={() => removeEntry(index, false)}>
                    <MaterialIcon name="delete" size={18} />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <TextArea
                    style={{ height: "auto" }}
                    rows={4}
                    multiline
                    value={step.title || ""}
                    onChange={inputHandler(index, false)}
                  />
                </Grid>
              </Grid>
            ))}
          <Button onClick={() => addNewEntry(false)}>
            <MaterialIcon name="add" size={18} /> New Con
          </Button>
        </Grid>
      </Grid>
    );
  }
}
