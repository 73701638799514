import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";

import { SupplierProfile } from "./SupplierProfile.js";
import { SupplierContactPerson } from "./SupplierContactPerson.js";
import { SupplierHowTo } from "./SupplierHowTo.js";
import { SupplierProsCons } from "./SupplierProsCons.js";
import { MaterialIcon, Card } from "../Elements.js";
import { RouteTabs } from "../PageElements.js";

import { useMutation, getToken } from "../../Graphql.js";
import {
  SUPPLIERS_QUERY,
  HANDLING_PLANS_LIST_QUERY,
  HANDLING_PLAN_QUERY,
} from "../../Graphql/queries";
import { REMOVE_SUPPLIER_MUTATION } from "../../Graphql/mutations";
import PhosphorIcon from "../phosphor-icons/phosphor-icon.js";

/**
 * Manually remove the supplier from the query cache.
 */
let handleRemovedSupplier = (cache, { data }) => {
  const supplierId = data.removeSupplier.id;

  const { allSuppliers } = cache.readQuery({
    query: SUPPLIERS_QUERY,
    variables: { token: getToken() },
  });

  cache.writeQuery({
    query: SUPPLIERS_QUERY,
    variables: { token: getToken() },
    data: {
      allSuppliers: allSuppliers.filter(
        supplier => supplier._id !== supplierId
      ),
    },
  });

  try {
    const { handlingPlans } = cache.readQuery({
      query: HANDLING_PLANS_LIST_QUERY,
      variables: { token: getToken() },
    });

    const handlingPlansContainingRemovedSupplier = handlingPlans.filter(
      handlingPlan =>
        handlingPlan.suppliers
          ? handlingPlan.suppliers.includes(supplierId)
          : false
    );

    // Update each handling plan which contains removed supplier
    handlingPlansContainingRemovedSupplier.forEach(handlingPlanToUpdate => {
      const { handlingPlan } = cache.readQuery({
        query: HANDLING_PLAN_QUERY,
        variables: {
          token: getToken(),
          id: handlingPlanToUpdate._id,
        },
      });

      cache.writeQuery({
        query: HANDLING_PLAN_QUERY,
        variables: {
          token: getToken(),
          id: handlingPlanToUpdate._id,
        },
        data: {
          handlingPlan: {
            ...handlingPlan,
            suppliers: handlingPlan.suppliers.filter(
              supplier => supplier !== supplierId
            ),
          },
        },
      });
    });

    // Update handling plans list by updating suppliers property
    cache.writeQuery({
      query: HANDLING_PLANS_LIST_QUERY,
      variables: { token: getToken() },
      data: {
        handlingPlans: handlingPlans.map(handlingPlan =>
          handlingPlan.suppliers
            ? {
                ...handlingPlan,
                suppliers: handlingPlan.suppliers.filter(
                  supplier => supplier !== supplierId
                ),
              }
            : handlingPlan
        ),
      },
    });
  } catch (err) {
    console.log("Handling plans queries were not loaded yet!");
  }
};

export let SupplierEdit = ({ supplier, onChange }) => {
  let match = useRouteMatch();

  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  weekdays.forEach(weekday => {
    const newWeekday = {
      name: weekday,
      isOpen: false,
      from: "00:00",
      till: "00:00",
    };

    if (supplier.openingTimes.some(x => x.name === weekday) === false) {
      supplier.openingTimes.push({ ...newWeekday });
    }

    if (
      supplier.contactPerson.reachHours.some(x => x.name === weekday) === false
    ) {
      supplier.contactPerson.reachHours.push({ ...newWeekday });
    }
  });

  let [removeSupplierMutation] = useMutation(REMOVE_SUPPLIER_MUTATION, {
    update: handleRemovedSupplier,
  });
  let removeSupplier = ({ _id, supplier }) => {
    if (!window.confirm(`Are you sure you want to delete: ${supplier}`)) {
      return;
    }

    removeSupplierMutation({
      variables: {
        token: getToken(),
        id: _id,
      },
    });
  };

  return (
    <React.Fragment>
      <RouteTabs
        tabs={[
          {
            label: "Profile",
            to: "profile",
            icon: <MaterialIcon name="face" size={18} />,
          },
          {
            label: "Contact person",
            to: "contact-person",
            icon: <MaterialIcon name="contact_phone" size={18} />,
          },
          {
            label: "How to",
            to: "how-to",
            icon: <MaterialIcon name="help" size={18} />,
          },
          {
            label: "Pros & Cons",
            to: "pros-and-cons",
            icon: <MaterialIcon name="assignment" size={18} />,
          },
          {
            label: "Delete",
            to: "delete",
            icon: (
              <PhosphorIcon
                name="trash"
                style={{ fontSize: 18 }}
              />
            ),
          },
        ]}
      />

      <Card.Content>
        <Switch>
          <Route
            path={`${match.path}/profile`}
            render={() => (
              <SupplierProfile supplier={supplier} onChange={onChange} />
            )}
          />
          <Route
            path={`${match.path}/contact-person`}
            render={() => (
              <SupplierContactPerson supplier={supplier} onChange={onChange} />
            )}
          />
          <Route
            path={`${match.path}/how-to`}
            render={() => (
              <SupplierHowTo supplier={supplier} onChange={onChange} />
            )}
          />
          <Route
            path={`${match.path}/pros-and-cons`}
            render={() => (
              <SupplierProsCons supplier={supplier} onChange={onChange} />
            )}
          />
          <Route
            path={`${match.path}/delete`}
            render={() => (
              <div>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    console.log("Delete", supplier);
                    console.log(`removeSupplier:`, removeSupplier);
                    removeSupplier(supplier);
                  }}
                >
                  <PhosphorIcon name="trash" />
                </IconButton>
              </div>
            )}
          />
          <Redirect from={`${match.url}/`} to={`${match.url}/profile`} />
        </Switch>
      </Card.Content>
    </React.Fragment>
  );
};
