import styled from "styled-components";
import { Input } from "../Styled/Input";

export const EditingBox = styled("div")`
  border-radius: 8px;
  background-color: #fafafa;
  padding: 20px;
`;

export const EditingBoxTitle = styled("h4")`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const EditingError = styled("span")`
  color: "#c20000";
`;

export const EditingInput = styled(Input)`
  font-size: 16px;
  padding: 12px;
  height: 40px;
`;

export const EditingRequired = styled("span")`
  font-size: 10px;
  color: #ed5565;
`;

export const EditingFieldTitle = styled("h4")`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const EditingBoxFormGroupRow = styled("div")`
  display: flex;
  gap: 20px;
`;
