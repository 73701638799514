import { FormControlLabel, Switch } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Select } from "../Elements";
import { FileUpload } from "../FileUpload";
import { RowControls } from "../RowControls";
import { getToken } from "../../constants";
import { uploadFile } from "../../requests/uploadFile";

export const StoryPageEditForm = ({
  page,
  onPageChange,
  onFileChange,
  storyId = null,
  index,
  total,
  onUp,
  onDown,
  onDelete,
  lastSubmittedFile = null,
}) => {
  const [localFileUrl, setLocalFileUrl] = useState(null);
  const [localFile, setLocalFile] = useState(lastSubmittedFile);

  useEffect(() => {
    // Using effect so that object URL can be revoked during cleanup
    if (!localFile) return;
    const objectUrl = URL.createObjectURL(localFile);
    setLocalFileUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [localFile]);

  const handleFileChange = async file => {
    const isFileUploaded = await uploadFile(
      `${storyId}/${file.name}`,
      file,
      getToken()
    );
    if (!isFileUploaded) {
      console.error("Error uploading file");
    } else {
      onPageChange({ ...page, fileName: file.name });
      onFileChange({ oldFile: localFile, newFile: file });
      setLocalFile(file);
    }
  };

  return (
    <EditFormContainer>
      <RowControls
        index={index}
        total={total}
        onUp={onUp}
        onDown={onDown}
        onDelete={onDelete}
      />

      <FileUploadContainer>
        {localFileUrl && <ThumbnailPreview src={localFileUrl} />}
        {!localFileUrl && page.filePath != null && (
          <ThumbnailPreview src={page.filePath} />
        )}

        <FieldsContainer>
          <FormControlLabel
            control={
              <Switch
                checked={page.isActive}
                onChange={() =>
                  onPageChange({ ...page, isActive: !page.isActive })
                }
                value="enabled"
                color="primary"
              />
            }
            label="Activate page"
            className="input input__switch"
          />

          <Select
            label="Alignment"
            value={page.alignment}
            onChange={event => {
              onPageChange({
                ...page,
                alignment: event.target.value,
              });
            }}
            items={[
              { title: "Center", value: "CENTER" },
              { title: "Top", value: "TOP" },
              { title: "Bottom", value: "BOTTOM" },
            ]}
          />

          <FileUpload
            name={`file-upload-${page._id}`}
            onChange={handleFileChange}
            createLabel="Add image"
            updateLabel="Replace image"
            previousFileKey={page.fileName}
          />
        </FieldsContainer>
      </FileUploadContainer>
    </EditFormContainer>
  );
};

const EditFormContainer = styled("div")`
  display: flex;
  gap: 10px;
`;

const FileUploadContainer = styled("div")`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const ThumbnailPreview = styled("img")`
  width: 250px;
  min-height: 400px;
  border-radius: 20px;
`;

const FieldsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
