import React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { getToken } from "../constants";
import { QuestionAndAnswerForm } from "../Components/QuestionAndAnswers/QuestionAndAnswerForm";

export class EditQuestionAndAnswer extends React.Component {
  updateQA = mutation => async qa => {
    await mutation({
      variables: {
        token: getToken(),
        id: this.props.match.params.id,
        data: qa,
      },
    });

    this.props.history.push("/questionsAndAnswers");
  };

  render() {
    const id = this.props.match.params.id;

    return (
      <Page>
        <Container>
          <PageHeader>
            <PageTitle>
              <h2>Update Q&A</h2>
            </PageTitle>
          </PageHeader>

          <Query query={QA_QUERY} variables={{ token: getToken(), id }}>
            {({ loading, data: questionAndAnswerQuery }) => {
              if (loading) return "<p>Loading</p>";
              return (
                <Mutation mutation={UPDATE_QA_MUTATION}>
                  {updateQAMutation => (
                    <QuestionAndAnswerForm
                      qa={questionAndAnswerQuery.questionAndAnswer}
                      onSubmit={this.updateQA(updateQAMutation)}
                      buttonText="Update"
                    />
                  )}
                </Mutation>
              );
            }}
          </Query>
        </Container>
      </Page>
    );
  }
}

const QA_FRAGMENT = gql`
  fragment QuestionAndAnswerFragment on QuestionAndAnswer {
    _id
    question
    answer
  }
`;

const QA_QUERY = gql`
  query($token: String!, $id: String!) {
    questionAndAnswer(token: $token, _id: $id) {
      ...QuestionAndAnswerFragment
    }
  }
  ${QA_FRAGMENT}
`;

const UPDATE_QA_MUTATION = gql`
  mutation($token: String!, $id: String!, $data: QuestionAndAnswerInput!) {
    updateQuestionAndAnswer(token: $token, _id: $id, data: $data) {
      ...QuestionAndAnswerFragment
    }
  }
  ${QA_FRAGMENT}
`;
