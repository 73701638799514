import styled from "styled-components";

export const Button = styled.button`
  padding: 12px 18px;
  border: 0;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  background: #42c175;
  cursor: pointer;
`;
