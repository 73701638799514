import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Description } from "./index";
import AppBar from "@material-ui/core/AppBar";
import { ROADMAP_TYPE } from "../Company/CompanyRoadmap";
import Tooltip from "@material-ui/core/Tooltip";
import PhosphorIcon from "../phosphor-icons/phosphor-icon";

const SideBarContainer = styled.div`
  border-right: 1px solid #e5e5e5;
  min-height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 60px;
  width: 350px;
`;

const InnerTitleContainer = styled.div`
  flex: 1;
  padding-left: 10px;
`;

const Title = styled.div`
  padding-top: 15px;
  vertical-align: bottom;
`;
const Subtitle = styled.div`
  font-size: 13px;
  vertical-align: top;
  opacity: 0.8;
`;

const SettingsIcon = styled.div`
  cursor: pointer;
  line-height: 60px;
  margin-right: 10px;
  vertical-align: middle;
  width: 15px;
`;

const TodoContainer = styled.div`
  padding: 0 5px;
  width: 100%;

  &:nth-child(odd) {
    background-color: #f5f5f5;
  }
`;

const TodoChevron = styled.div`
  cursor: pointer;
  line-height: 50px;
  padding-right: 5px;
  width: 15px;
  vertical-align: middle;
`;

const TodoTitle = styled.p`
  flex: 1;
  margin: auto;
  margin-left: 5px;
`;

const TodoTitleContainer = styled.div`
  display: flex;
  min-height: 50px;
`;

export const WarningSign = React.forwardRef((props, ref) => (
  <div {...props} ref={ref} style={{ display: "inline" }}>
    <PhosphorIcon
      name="warning"
      style={{ marginRight: "5px" }}
    />
  </div>
));

export const SideBar = ({
  activeBatch,
  allowances,
  batch,
  onTodoChange,
  roadmapType,
  setIsSettings,
  setIsSelector,
  setVisibleTodo,
  visibleTodo,
}) => {
  const { from, till, items } = batch;
  return (
    <SideBarContainer>
      <AppBar position="static" color="default">
        <TitleContainer>
          <InnerTitleContainer>
            <Title>Period {activeBatch + 1}</Title>
            <Subtitle>
              {roadmapType !== ROADMAP_TYPE.COMPANY
                ? `${moment(from).format("D MMMM YYYY")} to ${moment(
                    till
                  ).format("D MMMM YYYY")}`
                : `${moment(from).format("D MMMM")} to ${moment(till).format(
                    "D MMMM"
                  )}`}
            </Subtitle>
          </InnerTitleContainer>
          <SettingsIcon
            onClick={() => {
              setIsSettings(true);
            }}
          >
            <PhosphorIcon icon="cog" />
          </SettingsIcon>
        </TitleContainer>
      </AppBar>
      {items.map((todo, index) => {
        const _id = todo.todo?._id ?? todo.item._id;
        const isActive = visibleTodo === _id;
        return (
          <TodoContainer key={_id}>
            <TodoTitleContainer>
              <TodoChevron
                onClick={() =>
                  isActive ? setVisibleTodo(null) : setVisibleTodo(_id)
                }
              >
                {isActive ? (
                  <PhosphorIcon name="caret-up" />
                ) : (
                  <PhosphorIcon name="caret-right" />
                )}
              </TodoChevron>
              <TodoTitle>
                {todo.item?._id && (
                  <Tooltip
                    title={
                      "This item is deprecated and will be removed on 28/10/2020 a new todo needs to be created"
                    }
                    placement="top-start"
                  >
                    <WarningSign />
                  </Tooltip>
                )}

                {todo.todo?.title ?? todo.item.title}
              </TodoTitle>
            </TodoTitleContainer>
            {isActive && (
              <Description
                allowances={allowances}
                todo={todo}
                onTodoChange={prop => onTodoChange(index, prop)}
                roadmapType={roadmapType}
              />
            )}
          </TodoContainer>
        );
      })}
      <TodoContainer>
        <TodoTitleContainer>
          <TodoChevron
            onClick={() => {
              setIsSelector(true);
              setVisibleTodo(null);
            }}
          >
            <PhosphorIcon name="plus" />
          </TodoChevron>
          <TodoTitle>Add / remove todo</TodoTitle>
        </TodoTitleContainer>
      </TodoContainer>
    </SideBarContainer>
  );
};
