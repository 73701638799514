import React from "react";
import styled from "styled-components";
import immer from "immer";
import uniqid from "uniqid";
import { Button, TextArea, MaterialIcon } from "../Elements.js";
import { RowControls } from "../RowControls";

export const SupplierHowTo = ({ onChange, supplier }) => {
  const roadmapInputHandler = index => event => {
    const newRoadmap = Object.assign([...supplier.roadmap], {
      [index]: Object.assign({}, supplier.roadmap[index], {
        title: event.target.value,
      }),
    });
    onChange({ ...supplier, roadmap: newRoadmap });
  };

  const addNewRoadmap = () => {
    onChange({
      ...supplier,
      roadmap: [...supplier.roadmap, { _id: uniqid(), title: "" }],
    });
  };

  const removeRoadmapElement = index => {
    onChange({
      ...supplier,
      roadmap: [
        ...supplier.roadmap.slice(0, index),
        ...supplier.roadmap.slice(index + 1),
      ],
    });
  };

  const moveRoadmapElement = (index, direction) => {
    const newRoadmap = immer(supplier.roadmap, draft => {
      const [item] = draft.splice(index, 1);
      const newIndex = index + direction;

      draft.splice(newIndex, 0, item);
    });

    onChange({ ...supplier, roadmap: newRoadmap });
  };

  return (
    <section>
      <Steps>
        {supplier.roadmap.map((step, index) => (
          <Step key={step._id || index}>
            <RowControls
              index={index}
              total={supplier.roadmap.length}
              onUp={() => moveRoadmapElement(index, -1)}
              onDown={() => moveRoadmapElement(index, 1)}
              onDelete={() => removeRoadmapElement(index)}
            />
            <TextArea
              style={{ height: "auto" }}
              rows={4}
              multiline
              value={step.title || ""}
              onChange={roadmapInputHandler(index)}
            />
          </Step>
        ))}
      </Steps>

      <Button onClick={addNewRoadmap}>
        <MaterialIcon name="add" size={18} /> New step
      </Button>
    </section>
  );
};

const Steps = styled.div`
  margin-bottom: 40px;
`;

const Step = styled.div`
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;
