import config from "../config";

export const deleteFiles = async (folderName, token) => {
  try {
    const response = await fetch(
      `${config.backendHost}/file-delete`,
      {
        method: "POST",
        body: JSON.stringify({ folderName }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: token,
        },
      }
    );

    if (!response.ok) {
      return false;
    }
  } catch (error) {
    console.error("Error:", error);
    return false;
  }

  return true;
};
