import React from "react";
import styled from "styled-components/macro";
import { useRouteMatch, Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { Card, StyledNavLink } from "./Elements";

let SidebarStyle = styled(Card)`
  height: 100%;
  width: 250px;
  flex: 0 0 250px;
  border-radius: 0;
`;
export let Sidebar = ({ title, children, ...props }) => {
  return (
    <SidebarStyle>
      {title && <Card.Title>{title}</Card.Title>}
      {children}
    </SidebarStyle>
  );
};

export let SidebarLink = styled(StyledNavLink)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0;
  border-bottom: solid 1px lightgray;

  &.active {
    background-color: ${props =>
      props.loaclEnv ? "#dd8d3e" : "rgb(66, 193, 117)"};
    position: sticky;
    top: 0;
    bottom: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;
SidebarLink.Title = styled.div`
  font-weight: bold;
`;
SidebarLink.Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 14px;
  padding-top: 4px;

  & + & {
    padding-top: 0;
  }
`;

export let RouteTabs = ({ tabs }) => {
  let basematch = useRouteMatch();
  let match = useRouteMatch(`${basematch.path}/:page`);

  let matching_index = match
    ? tabs.findIndex(tab => tab.to === match.params.page)
    : -1;

  return (
    <AppBar
      position="static"
      color="default"
      style={{ position: "sticky", top: 0 }}
    >
      <Tabs
        value={matching_index === -1 ? false : matching_index}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            component={Link}
            to={`${basematch.url}/${tab.to}`}
            label={tab.label}
            icon={tab.icon}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};
