import React, { Component } from "react";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

class TableContent extends Component {
  render() {
    const { data, columns, navigate } = this.props;

    return (
      <TableBody>
        {data.map(row => {
          return (
            <TableRow
              component={Link}
              to={`/${navigate}/${row._id}`}
              key={row._id}
            >
              {columns.map(column => {
                let value = row[column.key];
                value = column.type === Boolean ? String(value) : value;
                value =
                  column.type === Date
                    ? DateTime.fromISO(value).toFormat("dd-LL-yyyy HH:mm")
                    : value;
                return (
                  <TableCell key={row._id + column.key}>{value}</TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
}

TableContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
};

export default TableContent;
