import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useTimeoutFn } from "react-use";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

export const DeleteButton = ({ color = "black", onClick, style = {} }) => {
  const [clicked, setClicked] = useState(false);

  useTimeoutFn(
    () => {
      if (clicked) setClicked(false);
    },
    clicked ? 5000 : 0
  );

  return (
    <DeleteIcon
      name="trash"
      style={{ ...style }}
      clicked={clicked}
      initialColor={color}
      onClick={() => (clicked ? onClick() : setClicked(true))}
    />
  );
};

const pulse = keyframes`
  0% {
    filter: drop-shadow(0 0 0 red);
  }
  
  50% {
    filter: drop-shadow(0 0 2px red);
  }
  
  100% {
    filter: drop-shadow(0 0 0 red);
  }
`;

const DeleteIcon = styled(({ clicked, initialColor, ...rest }) => (
  <PhosphorIcon {...rest} />
))`
  cursor: pointer;
  color: ${({ initialColor, clicked }) => (clicked ? "red" : initialColor)}
    ${({ clicked }) =>
      clicked
        ? css`
            animation: ${pulse} 1s linear infinite;
          `
        : ""};
`;
