import React from "react";
import styled from "styled-components";

const HandleContainer = styled.div`
  cursor: col-resize;
  width: 20px;

  &:hover {
    background-color: #42c175;
    border-radius: 0 3px 3px 0;
  }
`;

let FullscreenLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000000;
`;

export const Handle = ({ onMove, onBlur }) => {
  let [current_drag, set_current_drag] = React.useState(null);

  return (
    <>
      {current_drag != null && (
        <FullscreenLayer
          onMouseMove={e => {
            onMove(e.clientX - current_drag.start);
          }}
          onMouseUp={e => {
            onBlur(e.clientX - current_drag.start);
            set_current_drag(null);
          }}
        />
      )}
      <HandleContainer
        onMouseDown={e => {
          set_current_drag({
            start: e.clientX,
          });
        }}
      />
    </>
  );
};
