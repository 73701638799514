import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Icon as MaterialUIIcon } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import {
  TextField as MaterialTextField,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  Select as MaterialSelect,
  MenuItem as MaterialMenuItem,
} from "@material-ui/core";

export let MaterialIcon = ({ name, ...props }) => (
  <MaterialUIIcon children={name} {...props} />
);

export let Whitespace = ({ height, width }) => {
  return (
    <div
      style={{
        height: height,
        width: width,
        minHeight: height,
        minWidth: width,
      }}
    />
  );
};

let EmptyStateText = styled.div`
  background-color: #56565626;
  color: transparent;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;

  font-size: 50px;
  font-weight: 900;
`;
export let EmptyState = ({ children, icon }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {icon && (
        <EmptyStateText>
          <MaterialIcon
            name={icon}
            style={{ display: "block", fontSize: 80 }}
          />
        </EmptyStateText>
      )}

      <EmptyStateText>{children}</EmptyStateText>
    </div>
  );
};

export let styled_selector = condition_fn => {
  return props => (condition_fn(props) ? "&" : "null");
};

export let VerticalList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;

  flex: 1;

  /* border: solid gray 1px; */
`;

export let DisabledLabel = styled.label`
  color: #aaa;
`;

export let InpageContainer = styled.div`
  /* Reset padding if accidentally nested */
  padding-left: 16px;
  padding-right: 16px;

  display: flex;
  flex-direction: column;

  & & {
    padding-left: 0;
    padding-right: 0;
  }
`;

export let InlineButton = styled.button`
  border: none;
  background-color: transparent;

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;

  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;

export let StyledNavLink = styled(NavLink)`
  color: inherit;
  padding: 12px;
  border-radius: 3px;

  transition: all 0.2s;

  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover:not(.active) {
    background-color: #e6ecf0;
  }

  &.active {
    background-color: #42c175;
    color: white;
  }
`;

export let Card = styled.div`
  background-color: white;
  /* box-shadow:
        0 2px 2px 0 rgba(0,0,0,.14),
        0 3px 1px -2px rgba(0,0,0,.2),
        0 1px 5px 0 rgba(0,0,0,.12); */
  border: solid 1px #e5e5e5;
  border-radius: 3px;

  display: flex;
  flex-direction: column;
`;
Card.Content = styled.div`
  padding-top: 16px;
  padding: 12px;
`;
Card.Title = styled.h1`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;

  & + ${Card.Content} {
    padding-top: 0;
  }
`;

export let HorizontalList = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: scroll;

  flex: 1;

  /* border: solid gray 1px; */
`;

HorizontalList.EmptyState = styled.div`
  font-size: 19px;
  align-self: center;
  color: transparent;
  text-shadow: 0px 0px 1px #00000017;
`;

export let ListItem = styled.div`
  padding: 12px;
  border-radius: 5px;

  display: flex;
  align-items: stretch;

  ${styled_selector(p => p.onClick != null)} {
    cursor: pointer;

    &:hover,
    ${styled_selector(p => p.active)} {
      background-color: #aaa;
    }
  }

  /* Veeerryyyy nice https://www.styled-components.com/docs/advanced#referring-to-other-components */
  ${HorizontalList} > & {
    /* background-color: red; */
  }
`;

export let Button = styled.button`
  background: none;
  border: none;

  transition: all 0.2s;

  font-size: inherit;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;

  border-radius: 3px;
  cursor: pointer;

  color: white;
  background-color: #42c175;

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  &:hover {
    background-color: #339c5d;
  }

  ${styled_selector(p => p.disabled)} {
    background-color: #eee;
    cursor: not-allowed;
    color: rgb(125, 125, 125);

    &:hover {
      background-color: #eee;
    }
  }
`;

export let TextInput = styled(MaterialTextField).attrs(props => {
  if (props.label) {
    return {
      label: props.label,
      placeholder: props.placeholder,
    };
  } else {
    return {
      label: props.placeholder,
      placeholder: "",
    };
  }
})`
  ${styled_selector(p => p.invalid)} {
    outline: #e35959 auto 5px;
    outline-offset: -2px;
  }

  ${styled_selector(p => p.disabled)} {
    background-color: #eee;
    cursor: not-allowed;
    color: rgb(125, 125, 125);

    &:hover {
      background-color: #eee;
    }
  }
`;
TextInput.defaultProps = {
  variant: "filled",
  fullWidth: true,
};

export let Flex = styled.div`
  display: flex;
  flex-direction: ${p => (p.column ? "column" : "row")};
  align-items: ${p => p.alignItems || "stretch"};
`;

export class NumberInput extends React.Component {
  state = {
    visible_value: this.props.value,
  };
  input_ref = null;

  // componentDidUpdate() {
  //   let { visible_value } = this.state;
  //   let { integer } = this.props;
  //
  //   let number = Number(visible_value);
  //   console.log(`number:`, number)
  //   let is_invalid = visible_value === "" || Number.isNaN(number) || (integer && !Number.isInteger(number));
  //
  //   console.log(`is_invalid:`, is_invalid)
  //   console.log(`this.input_ref.setCustomValidity:`, this.input_ref.setCustomValidity)
  //
  //   if (is_invalid) {
  //     this.input_ref.setCustomValidity("Please enter a valid number");
  //   } else {
  //     this.input_ref.setCustomValidity("");
  //   }
  // }

  isValidNumber(string) {
    let { integer } = this.props;

    let number = Number(string);
    return (
      string !== "" &&
      !Number.isNaN(number) &&
      (!integer || Number.isInteger(number))
    );
  }

  render() {
    let { visible_value } = this.state;
    // Destructure 'integer' to remove it from props
    let { value, onChange, integer, ...props } = this.props;

    return (
      <TextInput
        {...props}
        invalid={!this.isValidNumber(visible_value) ? "true" : null}
        type="number"
        ref={ref => {
          this.input_ref = ref;
        }}
        value={visible_value}
        onChange={event => {
          this.setState({ visible_value: event.target.value });

          if (!this.isValidNumber(event.target.value)) {
            return;
          }

          onChange({
            target: {
              value: Number(event.target.value),
            },
          });
        }}
      />
    );
  }
}

export let TextArea = styled(TextInput)``;
TextArea.defaultProps = {
  multiline: true,
  rows: 3,
  rowsMax: Infinity,
};

export let Select = ({ label, value, items, onChange, style, ...props }) => {
  let valueIndex = items.findIndex(x => x.value === value);

  return (
    <MaterialFormControl style={{ width: "100%", style }} variant="filled">
      <MaterialInputLabel>{label}</MaterialInputLabel>

      <MaterialSelect
        fullWidth
        variant="filled"
        value={valueIndex === -1 ? "" : valueIndex}
        onChange={event => {
          let nextValueIndex = Number(event.target.value);
          let nextValue = items[nextValueIndex].value;
          onChange({
            target: {
              value: nextValue,
            },
          });
        }}
        {...props}
      >
        {items.map((item, i) => (
          <MaterialMenuItem key={i} value={i}>
            {item.title}
          </MaterialMenuItem>
        ))}
      </MaterialSelect>
    </MaterialFormControl>
  );
};

const StyledDatePicker = styled(KeyboardDatePicker)`
  width: 100%;

  /* Not sure why, but this date picker gets the "Mui-disabled" class attached... even though it's not disabled... */
  .MuiFilledInput-root.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.09);
  }
  .MuiFilledInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }
`;
export const DatePicker = props => {
  return (
    <StyledDatePicker
      autoOk={true}
      inputVariant="filled"
      className="date-picker"
      format={"dd MMMM yyyy"}
      InputProps={{ disabled: true }}
      variant="filled"
      {...props}
    />
  );
};

let ErrorStyle = styled.div`
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 12px;
  color: #c20000;
  border: solid 3px currentColor;
`;
export let GraphqlErrorWell = ({ error }) => {
  if (error.graphQLErrors && error.graphQLErrors.length !== 0) {
    return (
      <ErrorStyle>
        {error.graphQLErrors.map(error => (
          <div>{error.message}</div>
        ))}
      </ErrorStyle>
    );
  } else if (error.networkError?.result?.errors) {
    let useful_errors = error.networkError.result.errors.filter(
      x => x.name !== "ServerError"
    );
    return (
      <ErrorStyle>
        {useful_errors.map(error => (
          <div>{error.message}</div>
        ))}
      </ErrorStyle>
    );
  } else {
    return <ErrorStyle>{error.message}</ErrorStyle>;
  }
};
