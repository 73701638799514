import React from "react";
import { debounce } from "lodash";

// This properly keeps the returned callback the same,
// even if the "dependencies" of `fn` change.
// I do get why the React team hasn't added this yet,
// as when this is used for something other than a actual event callback, it screws things up.
let useEventHandler = fn => {
  let actual_fn = React.useRef(fn);

  // handle_fn will never change, always be the same
  // thing pointing to `actual_fn.current` (which will change)
  let handle_fn = React.useRef((...args) => actual_fn.current(...args));

  React.useEffect(() => {
    actual_fn.current = fn;
  });

  return handle_fn.current;
};

export let useDebouncedUpdate = ({
  value: parent_value,
  delay = 1000,
  onUpdate: save_callback,
}) => {
  let [local_value, set_local_value] = React.useState(parent_value);

  // Just a small "loading" like thing to show the debounce in action
  let [is_in_sync, set_is_in_sync] = React.useState(false);

  // If we pass a `React.useCallback` to debounce, it will not update when the `React.useCallback` gets a different function.
  // (React.useCallback is basically React.useMemo).
  // With useEventHandler the same returned function (`handle_save` here) will point to the last passed in function
  let handle_save = useEventHandler(async () => {
    await save_callback(local_value);
    set_is_in_sync(true);
  });

  let debounce_ref = React.useRef();
  React.useEffect(() => {
    debounce_ref.current = debounce(handle_save, delay);
    return () => debounce_ref.current.flush();
  }, []);

  let debounced_set_local_value = new_value => {
    set_local_value(new_value);
    set_is_in_sync(false);
    debounce_ref.current();
  };

  return [
    is_in_sync ? parent_value : local_value,
    is_in_sync,
    debounced_set_local_value,
  ];
};
