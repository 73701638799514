import gql from "graphql-tag";
import {
  ClientFragment,
  SimpleClientFragment,
  CompanyFragment,
  TimelineSuggestionFragment,
  HandlingPlanFragment,
  SupplierFullFragment,
  CoreStoryFields,
} from "./fragments";

export const ADMIN_CLIENTS_QUERY = gql`
  query($token: String!) {
    adminClients(token: $token) {
      ...SimpleClientFragment
    }
  }
  ${SimpleClientFragment}
`;

export const ADMIN_CLIENT_QUERY = gql`
  query($clientId: ID!, $token: String!) {
    adminClient(token: $token, clientId: $clientId) {
      ...ClientFragment
    }
  }
  ${ClientFragment}
`;

export const COMPANIES_QUERY = gql`
  query($token: String!) {
    companies(token: $token) {
      ...CompanyFragment
      clients {
        firstName
        lastName
        paidUntil
        createdAt
        role
        _id
      }
    }
  }
  ${CompanyFragment}
`;

export const SIMPLE_COMPANIES_QUERY = gql`
  query($token: String!) {
    companies(token: $token) {
      ...CompanyFragment
    }
  }
  ${CompanyFragment}
`;

export const SUPPLIERS_LIST_QUERY = gql`
  query($token: String!) {
    allSuppliers(token: $token) {
      _id
      supplier
    }
  }
`;

export const SUPPLIERS_QUERY = gql`
  query($token: String!) {
    allSuppliers(token: $token) {
      _id
      supplier
      link
      city
      tags {
        name
      }
    }
  }
`;

export const SUPPLIER_QUERY = gql`
  query($token: String!, $supplierId: String!) {
    supplier(token: $token, supplierId: $supplierId) {
      ...SupplierFullFragment
    }
  }
  ${SupplierFullFragment}
`;

export const HANDLING_PLANS_LIST_QUERY = gql`
  query($token: String!) {
    handlingPlans(token: $token) {
      _id
      title
      region
      enabled
      isBasic
      suppliers
    }
  }
`;

export const HANDLING_PLAN_QUERY = gql`
  query($token: String!, $id: String!) {
    handlingPlan(token: $token, _id: $id) {
      ...HandlingPlanFragment
    }
  }
  ${HandlingPlanFragment}
`;

export const TAGS_LIST_QUERY = gql`
  query($token: String!) {
    allTags(token: $token) {
      _id
      name
      createdBy
    }
  }
`;

export const STORIES_LIST_QUERY = gql`
  query($token: String!) {
    stories(token: $token) {
      ...CoreStoryFields
    }
  }
  ${CoreStoryFields}
`;

export const STORY_QUERY = gql`
  query($token: String!, $_id: ID!) {
    story(token: $token, _id: $_id) {
      ...CoreStoryFields
      thumbnailFileName
      thumbnailFilePath
      pages {
        _id
        alignment
        isActive
        fileName
        filePath
      }
    }
  }
  ${CoreStoryFields}
`;
