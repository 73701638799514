import React, { useEffect, useState } from "react";
import { FormGroup, MarkupInput } from "../Steps/StepForm";
import { Button, Switch } from "@material-ui/core";
import uuid from "uuid";
import emoji from "node-emoji";

import { Table } from "../ComposableTable/Table";
import { ClampedText } from "../ClampedText";
import DOMPurify from "dompurify";
import styled from "styled-components";
import {
  EditingBox,
  EditingBoxTitle,
  EditingError,
  EditingFieldTitle,
  EditingInput,
  EditingRequired,
} from "./EditingBox";
import { EditingActions } from "./EditingActions";

export const ChecklistTasks = ({
  checklistTasks,
  onTasksUpdate,
  onEditingUpdate,
}) => {
  const [error, setError] = useState();
  const [newChecklistTask, setNewChecklistTask] = useState();
  const [localChecklistTasks, setLocalChecklistTasks] = useState(
    checklistTasks
  );

  useEffect(() => {
    return () => {
      onEditingUpdate(false);
    };
  }, []);

  const createNewChecklistTask = () => {
    onEditingUpdate(true);
    setNewChecklistTask({
      _id: uuid.v4(),
      description: "",
      title: "",
      isInactive: true,
    });
  };

  const handleEdit = checklistTask => {
    onEditingUpdate(true);
    setNewChecklistTask(checklistTask);
  };

  const handleDeleteChecklistTask = async id => {
    const result = window.confirm("Are you sure?");

    if (!result) {
      return;
    }

    const updatedChecklistTasks = localChecklistTasks.filter(
      ({ _id }) => _id !== id
    );

    updateChecklistTasks(updatedChecklistTasks);
  };

  const handleSaveNewChecklistTask = async () => {
    if (
      typeof newChecklistTask.title !== "string" ||
      newChecklistTask.title.replace(/\s*/, "") === ""
    ) {
      return setError("Title cannot be empty");
    }

    let updatedChecklistTasks = [...localChecklistTasks] ?? [];
    const idx = updatedChecklistTasks.findIndex(
      checklistTask => checklistTask._id === newChecklistTask._id
    );
    if (idx > -1) {
      updatedChecklistTasks[idx] = newChecklistTask;
    } else {
      updatedChecklistTasks.push(newChecklistTask);
    }

    updateChecklistTasks(updatedChecklistTasks);

    onEditingUpdate(false);
    setError(null);
    setNewChecklistTask(null);
  };

  const handleSortItems = async result => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const updatedChecklistTasks = Array.isArray(localChecklistTasks)
      ? [...localChecklistTasks]
      : [];
    const [removed] = updatedChecklistTasks.splice(result.source.index, 1);
    updatedChecklistTasks.splice(result.destination.index, 0, removed);

    updateChecklistTasks(updatedChecklistTasks);
  };

  const updateChecklistTasks = updatedChecklistTasks => {
    setLocalChecklistTasks(updatedChecklistTasks);

    // strip any fields that shouldn't be a part of the mutation, like __fieldName
    const checklistTasks = updatedChecklistTasks.map(
      ({ _id, description, title, isInactive }) => ({
        _id,
        description,
        title,
        isInactive,
      })
    );

    onTasksUpdate(checklistTasks);
  };

  return (
    <>
      {localChecklistTasks.length > 0 && !newChecklistTask ? (
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell backgroundColor="#fafafa" />
              <Table.Cell backgroundColor="#fafafa">Title</Table.Cell>
              <Table.Cell backgroundColor="#fafafa">Description</Table.Cell>
              <Table.Cell backgroundColor="#fafafa" />
              <Table.Cell backgroundColor="#fafafa" />
              <Table.Cell backgroundColor="#fafafa" />
            </Table.Row>
          </Table.Head>

          <Table.SortableBody onSort={handleSortItems}>
            {localChecklistTasks.map((checklistTask, index) => (
              <Table.SortableRow
                key={checklistTask._id}
                draggableId={checklistTask._id}
                index={index}
                highlightColor="#f5f5f5"
              >
                <Table.SortHandleCell
                  width="30px"
                  id={checklistTask._id}
                  backgroundColor="#fafafa"
                />
                <Table.Cell width="3000px" backgroundColor="#fafafa">
                  {checklistTask.title}
                </Table.Cell>
                <Table.Cell width="6000px" backgroundColor="#fafafa">
                  <ClampedText linesToClamp={1}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(checklistTask.description),
                      }}
                    ></div>
                  </ClampedText>
                </Table.Cell>
                <Table.Cell backgroundColor="#fafafa">
                  <Switch
                    onChange={e => {
                      const updatedTasks = [...localChecklistTasks];
                      updatedTasks[index] = {
                        ...updatedTasks[index],
                        isInactive: !e.target.checked,
                      };
                      updateChecklistTasks(updatedTasks);
                    }}
                    value={!checklistTask.isInactive}
                    checked={!checklistTask.isInactive}
                    color="primary"
                  />
                </Table.Cell>
                <Table.ActionCell
                  backgroundColor="#fafafa"
                  onClick={() => {
                    handleEdit(checklistTask);
                  }}
                  iconName="pencil"
                />
                <Table.ActionCell
                  backgroundColor="#fafafa"
                  onClick={() => {
                    handleDeleteChecklistTask(checklistTask._id);
                  }}
                  iconName="trash"
                />
              </Table.SortableRow>
            ))}
          </Table.SortableBody>
        </Table>
      ) : null}

      <div>
        {newChecklistTask ? (
          <DarkerEditingBox>
            <EditingBoxTitle>
              Editing task (only visible in portal)
            </EditingBoxTitle>
            <FormGroup noPadding>
              <EditingFieldTitle>
                Title <EditingRequired>* required</EditingRequired>
              </EditingFieldTitle>

              <EditingInput
                onChange={e =>
                  setNewChecklistTask({
                    ...newChecklistTask,
                    title: e.target.value,
                  })
                }
                placeholder="Title"
                type="text"
                value={newChecklistTask.title}
              />

              {error ? <EditingError>{error}</EditingError> : null}
            </FormGroup>

            <FormGroup noPadding>
              <EditingFieldTitle>Description</EditingFieldTitle>

              <MarkupInput
                value={newChecklistTask.description}
                onChange={newValue =>
                  setNewChecklistTask({
                    ...newChecklistTask,
                    description: emoji.emojify(newValue),
                  })
                }
                placeholder="Description"
              />
            </FormGroup>

            <EditingActions
              onCancel={() => {
                onEditingUpdate(false);
                setNewChecklistTask(null);
              }}
              onConfirm={handleSaveNewChecklistTask}
              confirmLabel="Set task"
            />
          </DarkerEditingBox>
        ) : (
          <Button
            variant="outlined"
            onClick={createNewChecklistTask}
            style={{
              margin: checklistTasks.length > 0 ? "10px 10px 0 0" : undefined,
            }}
          >
            Add task
          </Button>
        )}
      </div>
    </>
  );
};

const DarkerEditingBox = styled(EditingBox)`
  background-color: #f5f5f5;
  margin-top: 10px;
  margin-bottom: 10px;
`;
