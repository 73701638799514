import React from "react";
import styled from "styled-components";
import moment from "moment";
import uniqid from "uniqid";
import { DraggableBar } from "./index";
import AppBar from "@material-ui/core/AppBar";

const CalendarWrapper = styled.div`
  min-height: 500px;
  position: absolute;
  top: 0;
  right: 0;
  left: 350px;
`;

const CalendarContainer = styled.div`
  min-height: 500px;
  position: relative;
  overflow-x: scroll;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  min-width: ${({ range }) => `${range * 10}px`};
`;

const HeaderContainer = styled.div`
  width: ${({ range }) => `${range * 10}px`};
`;

const CalendarHeader = styled.div`
  display: flex;
  flex: 1;
`;

const MonthTitle = styled.p`
  font-size: 12px;
  flex-shrink: 0;
  line-height: 60px;
  text-align: center;
  vertical-align: middle;
  width: ${({ range }) => `${range * 10}px`};
`;

const CalendarBody = styled.div`
  min-width: ${({ range }) => `${range * 10}px`};
`;

const Row = styled.div`
  min-width: ${({ range }) => `${range * 10}px`};

  &:nth-child(even) {
    background-color: #f5f5f5;
  }
`;

const Footer = styled.div`
  height: 50px;
`;

const TimelineContainer = styled.div`
  height: ${({ expand }) => (expand ? "550px" : "50px")};
  padding: 15px 0;
  position: relative;
  max-width: ${({ range }) => `${range * 10}px`};

  &:nth-child(even) {
    background-color: #f5f5f5;
  }
`;

export const Calendar = ({ batch, onTodoChange, visibleTodo }) => {
  const { from = null, items = null, till = null } = batch;

  const calendarStart = moment(from).startOf("month");
  const calendarEnd = moment(till).endOf("month");
  const dayRange = moment(calendarEnd).diff(moment(calendarStart), "days");

  const months = [];
  let currentMonth = null;

  for (let i = 0; i <= dayRange; i += 1) {
    const date = moment(calendarStart).add(i, "days");
    const month = date.format("MMM");
    if (month !== currentMonth) {
      currentMonth = month;
      months.push({ id: uniqid(), month, daysInMonth: 1 });
    } else {
      months[months.length - 1].daysInMonth += 1;
    }
  }

  return (
    <CalendarWrapper>
      <CalendarContainer>
        <HeaderWrapper range={dayRange}>
          <AppBar position="static" color="default">
            <HeaderContainer range={dayRange}>
              <CalendarHeader>
                {months.map(({ id, month, daysInMonth }) => (
                  <MonthTitle key={id} range={daysInMonth}>
                    {month}
                  </MonthTitle>
                ))}
              </CalendarHeader>
            </HeaderContainer>
          </AppBar>
        </HeaderWrapper>
        <CalendarBody range={dayRange}>
          {items.map((todo, index) => {
            const _id = todo.item?._id ?? todo.todo._id;
            return (
              <Row key={_id} range={dayRange}>
                <TimelineContainer
                  expand={visibleTodo === _id}
                  range={dayRange}
                >
                  <DraggableBar
                    calendarStart={calendarStart}
                    calendarEnd={calendarEnd}
                    batchStart={from}
                    batchEnd={till}
                    todo={todo}
                    onTodoChange={prop => onTodoChange(index, prop)}
                  />
                </TimelineContainer>
              </Row>
            );
          })}
          <Row>
            <Footer />
          </Row>
        </CalendarBody>
      </CalendarContainer>
    </CalendarWrapper>
  );
};
