import immer from "immer";
import { useMutation as useApolloMutation, Mutation } from "react-apollo";
import { useQuery as useApolloQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import omitDeep from "omit-deep-lodash";

import { getToken } from "./constants";

export { Mutation };
export { getToken };
export { gql };

// useMutation from react-apollo, but with some small twists
// - Automatically throw any errors in the mutations so they can be caught in a React error boundary
//   EXCEPT when we pass `dontThrow` as an option, which allows us to handle the error locally
export let useMutation = (query, { dontThrow, ...options } = {}) => {
  let [mutation, results] = useApolloMutation(query, options);

  if (dontThrow) {
    return [mutation, results];
  } else {
    if (results.error) {
      throw results.error;
    } else {
      return [mutation, results];
    }
  }
};

export let useQuery = (query, { dontThrow, ...options } = {}) => {
  let results = useApolloQuery(query, options);

  if (dontThrow) {
    return results;
  } else {
    if (results.error) {
      throw results.error;
    } else {
      return results;
    }
  }
};

// Small query drop-in that can React Suspense
export let Query = ({ query, children, ...props }) => {
  let result = useQuery(query, {
    ...props,
  });
  return children(result);
};

export const flatten_ids = (graphql_variable_in, map_id = () => {}) => {
  let flatten_ids_mutable = (input, map_id) => {
    if (typeof input !== "object") {
      return input;
    }
    if (input == null) {
      return input;
    }

    for (let [key, value] of Object.entries(input)) {
      if (key === "__typename" || key === "_id") {
        delete input[key];
        continue;
      }

      if (value == null) {
        delete input[key];
        continue;
      }
      if (typeof value !== "object") {
        continue;
      }

      let properties = map_id(value, key);
      if (properties != null) {
        delete input[key];
        Object.assign(input, properties);
      } else {
        flatten_ids_mutable(value, map_id);
      }
    }
  };

  let with_ids_flattened = immer(graphql_variable_in, graphql_variable_out => {
    return flatten_ids_mutable(graphql_variable_out, map_id);
  });

  return with_ids_flattened;
  // return omitDeep(with_ids_flattened, ['_id', '__typename'])
};

export let prepare_for_mutation = object => {
  return omitDeep(object, ["_id", "__typename"]);
};

export let updateQuery = (cache, query, map_fn) => {
  try {
    const data = cache.readQuery(query);
    let new_data = map_fn(data);
    cache.writeQuery({
      ...query,
      data: new_data,
    });
  } catch (error) {}
};
