import TextField from "@material-ui/core/TextField";
import React from "react";
import { QuestionContexts } from "../constants";
import styled from "styled-components/macro";

export const QuestionsStepForm = ({ step }) => {
  const questionContexts = [
    QuestionContexts.PROFILE_RELATED,
    QuestionContexts.REQUEST_RELATED,
    QuestionContexts.PREFERENCES,
  ];

  const getQuestionsByContext = (context, step) => {
    return step.questions.filter(question => question.context === context);
  };

  return (
    <div>
      <div style={{ marginBottom: "15px" }}>(Questions step)</div>
      {questionContexts.map(context => (
        <div key={context}>
          <ContextTitle>{context}</ContextTitle>

          <div style={{ marginBottom: "20px" }}>
            {getQuestionsByContext(context, step).map((question, index) => (
              <div key={index} style={{ display: "flex" }}>
                <QuestionInput
                  value={question.label}
                  variant="filled"
                  fullWidth={true}
                  margin="dense"
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const ContextTitle = styled.h5`
  font-size: 16px;
  color: hsl(164, 6%, 40%);
`;

const QuestionInput = styled(TextField)`
  input {
    padding-top: 12px;
    padding-bottom: 9px;
  }
`;
