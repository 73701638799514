import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export const HelpInfo = ({ description, style }) => {
  return (
    <Tooltip title={description} placement="top-start">
      <HelpOutlineIcon style={style} />
    </Tooltip>
  );
};

export const UpdateConfirmation = ({ successStatus, content }) => {
  const [open, setOpen] = useState(successStatus);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        {content}
      </Alert>
    </Snackbar>
  );
};
