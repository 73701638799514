import { Table } from "./Table";
import { TableBody } from "./TableBody";
import { TableCell } from "./TableCell";
import { TableHead } from "./TableHead";
import { SortableTableRow } from "./SortableTableRow";
import { TableRow } from "./TableRow";
import { SortHandleTableCell } from "./SortHandleTableCell";
import { ActionTableCell } from "./ActionTableCell";
import { SortableTableBody } from "./SortableTableBody";

export default Object.assign(Table, {
  ActionCell: ActionTableCell,
  SortableBody: SortableTableBody,
  SortableRow: SortableTableRow,
  Body: TableBody,
  Cell: TableCell,
  Head: TableHead,
  Row: TableRow,
  SortHandleCell: SortHandleTableCell,
});
