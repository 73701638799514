import gql from "graphql-tag";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import NavLinkAdapter from "../Components/NavLinkAdapter";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { getToken, Query } from "../Graphql";
import { Mutation } from "react-apollo";
import PhosphorIcon from "../Components/phosphor-icons/phosphor-icon";

export class QuestionsAndAnswers extends React.Component {
  removeQA = (mutation, { _id, question }) => () => {
    if (!window.confirm(`Are you sure you want to delete: "${question}"`)) {
      return;
    }

    mutation({
      variables: {
        token: getToken(),
        id: _id,
      },
    });
  };

  /**
   * Manually remove the Q&A from the query cache.
   */
  handleRemovedQA = (cache, { data }) => {
    const qaId = data.removeQuestionAndAnswer.id;

    const { questionsAndAnswers } = cache.readQuery({
      query: QAS_LIST_QUERY,
      variables: { token: getToken() },
    });

    cache.writeQuery({
      query: QAS_LIST_QUERY,
      variables: { token: getToken() },
      data: {
        questionsAndAnswers: questionsAndAnswers.filter(qa => qa._id !== qaId),
      },
    });
  };

  render() {
    return (
      <Page>
        <Container>
          <PageHeader>
            <PageTitle>
              <h2>Questions & Answers</h2>
              <Button
                variant="contained"
                size="small"
                component={NavLinkAdapter}
                to="/questionsAndAnswers/create"
              >
                Add
              </Button>
            </PageTitle>
          </PageHeader>

          <Query
            suspend
            query={QAS_LIST_QUERY}
            variables={{ token: getToken() }}
          >
            {({ data: { questionsAndAnswers } }) => (
              <Table style={{ background: "white" }}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {/* edit buttons */}
                    <TableCell>Question</TableCell>
                    <TableCell />
                    {/* delete buttons */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {questionsAndAnswers.map(qa => (
                    <TableRow key={qa._id}>
                      <TableCell style={{ width: 50 }}>
                        <IconButton
                          aria-label="edit"
                          component={NavLinkAdapter}
                          to={`/questionsAndAnswers/${qa._id}/edit`}
                        >
                          <PhosphorIcon name="pencil" />
                        </IconButton>
                      </TableCell>

                      <TableCell>{qa.question}</TableCell>

                      <TableCell style={{ width: 50 }}>
                        <Mutation
                          mutation={REMOVE_QA_MUTATION}
                          update={this.handleRemovedQA}
                        >
                          {mutation => (
                            <IconButton
                              aria-label="delete"
                              onClick={this.removeQA(mutation, qa)}
                            >
                              <PhosphorIcon name="trash" />
                            </IconButton>
                          )}
                        </Mutation>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Query>
        </Container>
      </Page>
    );
  }
}

export const QAS_LIST_QUERY = gql`
  query($token: String!) {
    questionsAndAnswers(token: $token) {
      _id
      question
    }
  }
`;

const REMOVE_QA_MUTATION = gql`
  mutation($token: String!, $id: String!) {
    removeQuestionAndAnswer(token: $token, _id: $id) {
      id
    }
  }
`;
