import React from "react";
import { omit } from "lodash";
import gql from "graphql-tag";
import { uniqBy } from "lodash";

import {
  Grid,
  Checkbox,
  Select,
  FormControl,
  Input,
  MenuItem,
  Chip,
} from "@material-ui/core";

import { useQuery } from "../../Graphql.js";
import { getToken } from "../../constants";
import Weekdays from "../../Elements/Weekdays.jsx";
import { TextInput, TextArea } from "../Elements.js";

const ALL_TAGS_QUERY = gql`
  query($token: String!) {
    allTags(token: $token) {
      _id
      name
    }
  }
`;

let omit_typename = object => omit(object, ["__typename"]);

export let SupplierProfile = ({ onChange, supplier }) => {
  const inputHandler = key => event => {
    onChange({
      ...supplier,
      [key]: event.target.value === "" ? null : event.target.value,
    });
  };

  const checkboxHandler = key => event => {
    onChange({ ...supplier, [key]: event.target.checked });
  };

  const tagSelectHandler = allTags => event => {
    const tags = allTags
      .filter(x => event.target.value.includes(x.name))
      .map(x => omit_typename(x));
    onChange({ ...supplier, tags });
  };

  const openingTimesInputHandler = (index, key) => event => {
    const newOpeningTimes = Object.assign([...supplier.openingTimes], {
      [index]: Object.assign({}, supplier.openingTimes[index], {
        [key]: event.target.value,
      }),
    });
    onChange({ ...supplier, openingTimes: newOpeningTimes });
  };

  const openingTimesCheckboxHandler = index => event => {
    const newOpeningTimes = Object.assign([...supplier.openingTimes], {
      [index]: Object.assign({}, supplier.openingTimes[index], {
        isOpen: event.target.checked,
      }),
    });
    onChange({ ...supplier, openingTimes: newOpeningTimes });
  };

  let {
    data: { allTags },
  } = useQuery(ALL_TAGS_QUERY, {
    variables: { token: getToken() },
    suspend: true,
  });

  const uniqueTags = uniqBy(allTags, x => x.name);

  const selectedTagsNames = supplier.tags.map(x => x.name);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          {supplierProfileInputs.map(input => (
            <Grid key={input.key} item xs={6}>
              <TextInput
                label={input.label}
                value={supplier[input.key] || ""}
                onChange={inputHandler(input.key)}
              />
            </Grid>
          ))}
          <Grid item xs={6}>
            <label>Rating</label>
            <FormControl style={{ width: "100%" }}>
              <Select
                variant="filled"
                value={supplier.rating || ""}
                onChange={inputHandler("rating")}
                inputProps={{
                  name: "rating",
                  id: "rating",
                }}
              >
                <MenuItem value="">
                  <em>Not set</em>
                </MenuItem>

                {["1", "2", "3", "4", "5"].map(rating => (
                  <MenuItem key={rating} value={rating}>
                    {rating}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <label>Preferred by uButler</label>
            <Checkbox
              checked={supplier.ubutlerPreferred}
              onChange={checkboxHandler("ubutlerPreferred")}
            />
          </Grid>
          <Grid item xs={6}>
            <label>Archived</label>
            <Checkbox
              checked={supplier.archived}
              onChange={checkboxHandler("archived")}
            />
          </Grid>
          <Grid item xs={12}>
            <label>Tags</label>
            <FormControl style={{ width: "100%" }}>
              <Select
                multiple
                value={selectedTagsNames}
                onChange={tagSelectHandler(uniqueTags)}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={tags => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {tags.map(tag => (
                      <Chip key={tag} label={tag} style={{ margin: 2 }} />
                    ))}
                  </div>
                )}
              >
                {uniqueTags.map(tag => {
                  const fontWeight =
                    supplier.tags
                      .map(selectedTag => selectedTag.name)
                      .indexOf(tag.name) === -1
                      ? 400
                      : 700;
                  return (
                    <MenuItem
                      key={tag._id}
                      value={tag.name}
                      style={{ fontWeight }}
                    >
                      {tag.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <label>Opening hours</label>
        <Weekdays
          weekdays={supplier.openingTimes}
          onCheckboxChange={openingTimesCheckboxHandler}
          onTimeChange={openingTimesInputHandler}
        />
      </Grid>
      <Grid item xs={12}>
        <label>Notes</label>
        <TextArea
          style={{ height: "auto" }}
          rows={8}
          value={supplier.notes || ""}
          onChange={inputHandler("notes")}
        />
      </Grid>
    </Grid>
  );
};

const supplierProfileInputs = [
  {
    key: "supplier",
    label: "Supplier",
  },
  {
    key: "estimatedResponseTime",
    label: "Estimated response time",
  },
  {
    key: "handlingDuration",
    label: "Handling duration",
  },
  {
    key: "link",
    label: "Link",
  },
  {
    key: "zipcode",
    label: "Zip code",
  },
  {
    key: "country",
    label: "Country",
  },
  {
    key: "address",
    label: "Address",
  },
  {
    key: "city",
    label: "City",
  },
];
