import React, { useState } from "react";
import { ClientForm } from "./ClientForm";
import { Whitespace, Button, MaterialIcon, Card } from "../Elements.js";
import Swal from "sweetalert2";
import { getToken, gql, useMutation } from "../../Graphql";

export const ClientCreatePage = ({ addClient }) => {
  const initClient = {
    birthDate: null,
    company: null,
    email: "",
    firstName: "",
    initiationId: "",
    lastName: "",
    nickname: "",
    serviceStartDate: null,
    paidUntil: null,
    phone: "",
    role: null,
    cluster: "",
    profileType: "",
    currency: null,
    relocatingTo: null,
  };

  const [client, setClient] = useState(initClient);
  const [validateClientEmail] = useMutation(
    gql`
      mutation validateClientEmail($token: String!, $email: String!) {
        validateClientEmail(token: $token, email: $email) {
          errorMessage
          isValid
        }
      }
    `);

  const validateEmail = async (email) => {
    if (!Boolean(client.email)) {
      return Swal.fire({
        icon: "error",
        text: "You must fill in the email of the GE",
        backdrop: false,
      });
    }

    const queryResult = await validateClientEmail({ variables: {
        email: email,
        token: getToken()
      }});

    if (Boolean(queryResult?.data?.validateClientEmail.errorMessage)) {
      return Swal.fire({
        icon: "error",
        text: queryResult.data.validateClientEmail.errorMessage,
        backdrop: false,
      });
    }
  }

  return (
    <Card styled={{ marginTop: 16 }}>
      <Card.Content>
        <h2>Create new client</h2>
        <Whitespace height={8} />
        <ClientForm client={client} onClientChange={setClient} />
        <Whitespace height={8} />
        <Button
          style={{ alignSelf: "flex-end" }}
          onClick={async () => {
            if (await validateEmail(client.email)) {
              return;
            }

            if (!Boolean(client.firstName)) {
              return Swal.fire({
                icon: "error",
                text: "You must fill in the first name of the GE",
                backdrop: false,
              });
            }
            if (!Boolean(client.lastName)) {
              return Swal.fire({
                icon: "error",
                text: "You must fill in the last name of the GE",
                backdrop: false,
              });
            }
            if (!Boolean(client.company)) {
              return Swal.fire({
                icon: "error",
                text: "You must fill in the company of the GE",
                backdrop: false,
              });
            }
            if (!Boolean(client.relocatingTo)) {
              return Swal.fire({
                icon: "error",
                text: "You must select the GE relocation destination",
                backdrop: false,
              });
            }
            if (!Boolean(client.currency)) {
              return Swal.fire({
                icon: "error",
                text: "You must select the preferred GE currency",
                backdrop: false,
              });
            }
            if (!Boolean(client.cluster)) {
              return Swal.fire({
                icon: "error",
                text: "You must fill in the cluster/RC name of the GE",
                backdrop: false,
              });
            }

            addClient(client);
          }}
        >
          <MaterialIcon name="add" size={18} /> Create
        </Button>
      </Card.Content>
    </Card>
  );
};
