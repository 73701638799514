import React from "react";
import { Handle } from "./index";
import styled from "styled-components";
import moment from "moment";
import { isEqual } from "lodash";

const TimeContainer = styled.div.attrs(({ left, right }) => {
  return { style: { left, right } };
})`
  background-color: #42c175;
  border-radius: 3px;
  height: 20px;
  min-width: 40px;
  position: absolute;

  &:hover {
    background-color: #339c5d;
  }
`;

const TimeContainerInner = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
`;

const DateContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  vertical-align: middle;
  z-index: 10;

  ${({ float }) => {
    if (float === "right") {
      return "right: -90px";
    }
    if (float === "left") {
      return "left: -90px";
    }
  }}
`;

export const DraggableBar = ({
  batchStart,
  batchEnd,
  calendarStart,
  calendarEnd,
  onTodoChange,
  todo,
}) => {
  const { from, till } = todo;
  let [current_drag, set_current_drag] = React.useState(null);

  let actualFrom = React.useMemo(() => {
    if (current_drag === null || current_drag.side !== "from") {
      return from;
    }
    const newFrom = moment(from)
      .add(current_drag.deltaDays, "days")
      .toDate();
    if (
      moment(newFrom).isBefore(calendarStart) ||
      moment(newFrom).isAfter(till) ||
      moment(newFrom).isBefore(batchStart)
    ) {
      return moment(from)
        .add(0, "days")
        .toDate();
    }
    return newFrom;
  }, [current_drag, from]);
  let actualTill = React.useMemo(() => {
    if (current_drag === null || current_drag.side !== "till") {
      return till;
    }
    const newTill = moment(till)
      .add(current_drag.deltaDays, "days")
      .toDate();
    if (
      moment(newTill).isAfter(calendarEnd) ||
      moment(newTill).isBefore(from) ||
      moment(newTill).isAfter(batchEnd)
    ) {
      return moment(till)
        .add(0, "days")
        .toDate();
    }
    return newTill;
  }, [current_drag, till]);

  const leftDistance = React.useMemo(() => {
    return moment(actualFrom).diff(moment(calendarStart), "days") * 10;
  }, [actualFrom, calendarStart]); // initialise how far left you want your absolute element to be in px
  const rightDistance = React.useMemo(() => {
    return moment(calendarEnd).diff(moment(actualTill), "days") * 10;
  }, [actualTill, calendarEnd]); // this will only be updated when the updateHandler is called onMouseUp

  return (
    <TimeContainer left={leftDistance} right={rightDistance}>
      <TimeContainerInner>
        <DateContainer float="left">
          {current_drag && moment(actualFrom).format("DD/MM/YYYY")}
        </DateContainer>
        <Handle
          onMove={deltaX => {
            let days = Math.round(deltaX / 10);
            let new_current_drag = { side: "from", deltaDays: days };
            if (!isEqual(current_drag, new_current_drag)) {
              set_current_drag(new_current_drag);
            }
          }}
          onBlur={() => {
            onTodoChange({ from: actualFrom });
            set_current_drag(null);
          }}
        />
        <Handle
          onMove={deltaX => {
            let days = Math.round(deltaX / 10);
            let new_current_drag = { side: "till", deltaDays: days };
            if (!isEqual(current_drag, new_current_drag)) {
              set_current_drag(new_current_drag);
            }
          }}
          onBlur={() => {
            onTodoChange({ till: actualTill });
            set_current_drag(null);
          }}
        />
        <DateContainer float="right">
          {current_drag && moment(actualTill).format("DD/MM/YYYY")}
        </DateContainer>
      </TimeContainerInner>
    </TimeContainer>
  );
};
