import React from "react";
import { capitalize } from "lodash";

import Checkbox from "@material-ui/core/Checkbox";
import { TextInput, Whitespace } from "../Components/Elements.js";

let Weekdays = ({ weekdays, onCheckboxChange, onTimeChange }) => {
  return (
    <React.Fragment>
      {weekdays.map((day, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Whitespace height={8} />
          <div>
            <Checkbox
              checked={day.isOpen}
              onChange={onCheckboxChange(index)}
              value="isOpen"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
            {capitalize(day.name)}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <TextInput
                size="small"
                label="From"
                type="time"
                value={day.from || ""}
                onChange={onTimeChange(index, "from")}
              />
            </div>
            <Whitespace width={16} />
            <div>
              <TextInput
                size="small"
                label="Till"
                type="time"
                value={day.till || ""}
                onChange={onTimeChange(index, "till")}
              />
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default Weekdays;
