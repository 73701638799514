import React from "react";
import Button from "@material-ui/core/Button";
import uuid from "uuid";
import { MarkupTextArea } from "./MarkupTextArea";
import { ImageInput } from "./ImageInput";
import { RowControls } from "./RowControls";
import { YouTubeInput } from "./YouTubeInput";

const contentTypes = {
  TEXT: "text",
  IMAGE: "image",
  YOUTUBE: "youtube",
};

export class ContentForm extends React.Component {
  addContent = type => () => {
    let newContent = {
      _id: uuid.v4(), // This id is not sent to the server, only used as a unique key for React.
      type,
      weight: this.props.content.length,
    };

    switch (type) {
      case contentTypes.TEXT:
        newContent.body = "";
        break;
      case contentTypes.IMAGE:
        newContent.url = "";
        break;
      case contentTypes.YOUTUBE:
        newContent.videoId = "";
        break;
      default:
        throw new Error(`Unknown type: ${type}`);
    }

    this.props.onChange([...this.props.content, newContent]);
  };

  handleChange = (content, newValues) => {
    this.props.onChange(
      this.props.content.map(oldContent => {
        if (oldContent._id === content._id) {
          return { ...content, ...newValues };
        }

        return oldContent;
      })
    );
  };

  deleteContentRow = id => {
    this.props.onChange(
      this.props.content.filter(oldContent => oldContent._id !== id)
    );
  };

  moveContent = (row, direction) => {
    const newContent = this.props.content.map(newRow => {
      if (newRow._id === row._id) {
        return {
          ...newRow,
          weight: row.weight + direction,
        };
      }

      if (newRow.weight === row.weight + direction) {
        return {
          ...newRow,
          weight: newRow.weight + direction * -1,
        };
      }

      return { ...newRow };
    });

    this.props.onChange(newContent);
  };

  renderTextInput = content => {
    return (
      <MarkupTextArea
        value={content.body}
        onChange={newValue => this.handleChange(content, { body: newValue })}
      />
    );
  };

  renderImageInput = content => {
    return (
      <ImageInput
        value={content.url}
        onChange={newValue => this.handleChange(content, { url: newValue })}
      />
    );
  };

  renderYouTubeInput = content => {
    return (
      <YouTubeInput
        value={content.videoId}
        onChange={newValue => this.handleChange(content, { videoId: newValue })}
      />
    );
  };

  renderContentRow = content => {
    switch (content.type) {
      case contentTypes.TEXT:
        return this.renderTextInput(content);
      case contentTypes.IMAGE:
        return this.renderImageInput(content);
      case contentTypes.YOUTUBE:
        return this.renderYouTubeInput(content);
      default:
        throw new Error(
          `No render method found for content of type: ${content.type}`
        );
    }
  };

  render() {
    const content = this.props.content.sort((a, b) => a.weight - b.weight);

    return (
      <div style={{ width: "100%" }}>
        {content.map((contentRow, index) => (
          <div
            key={contentRow._id}
            style={{ minHeight: 115, marginBottom: 20, position: "relative" }}
          >
            <div style={{ position: "absolute", left: -45 }}>
              <RowControls
                index={index}
                total={content.length}
                onUp={() => this.moveContent(contentRow, -1)}
                onDown={() => this.moveContent(contentRow, 1)}
                onDelete={() => this.deleteContentRow(contentRow._id)}
              />
            </div>
            {this.renderContentRow(contentRow)}
          </div>
        ))}

        <div>
          <Button
            size="small"
            variant="outlined"
            onClick={this.addContent(contentTypes.TEXT)}
            style={{ marginRight: "10px" }}
          >
            Add Text
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={this.addContent(contentTypes.IMAGE)}
            style={{ marginRight: "10px" }}
          >
            Add Image
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={this.addContent(contentTypes.YOUTUBE)}
          >
            Add YouTube Video
          </Button>
        </div>
      </div>
    );
  }
}
