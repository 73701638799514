import styled from "styled-components/macro";

const FormInfo = styled.div`
  width: 300px;
  padding-right: 30px;
  margin-right: 30px;
  flex-shrink: 0;

  h3 {
    color: hsl(164, 6%, 40%);
    margin-bottom: 10px;
    font-size: 18px;
  }

  p {
    color: hsl(164, 6%, 40%);
  }
`;

export default FormInfo;
