import React from "react";
import immer from "immer";

import { TextInput, TextArea, Select } from "../Elements.js";
import { FormControlLabel, Switch } from "@material-ui/core";

export class CompanyEdit extends React.Component {
  render() {
    let { company, onChange } = this.props;

    let inputHandler = key => event => {
      onChange({ ...company, [key]: event.target.value });
    };

    return (
      <div
        style={{
          display: "grid",
          gridGap: 8,
          gridTemplate: `
            "name  isActive"
            "address showPrivateBusiness"
            "other  other"
            "brand-color brand-text"
            "is-pilot is-pilot"
            /1fr   1fr
          `,
        }}
      >
        <div
          style={{
            gridArea: "name",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextInput
            label="Name"
            value={company.name || ""}
            onChange={inputHandler("name")}
          />
        </div>

        <div
          style={{
            gridArea: "isActive",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Select
            label="Is active?"
            value={company.isActive}
            items={[
              { title: "Active", value: true },
              { title: "Inactive", value: false },
            ]}
            onChange={inputHandler("isActive")}
          />
        </div>

        <div
          style={{
            gridArea: "showPrivateBusiness",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Select
            label="Show Private/Business in the payment flow"
            value={company.showPrivateBusiness}
            items={[
              { title: "Do not show", value: false },
              { title: "Show", value: true },
            ]}
            onChange={inputHandler("showPrivateBusiness")}
          />
        </div>

        <div
          style={{
            gridArea: "address",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextInput
            label="Address"
            value={company.address || ""}
            onChange={inputHandler("address")}
          />
        </div>

        <div
          style={{
            gridArea: "other",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextArea
            label="Other"
            value={company.other || ""}
            rows={4}
            onChange={inputHandler("other")}
          />
        </div>

        <div
          style={{
            gridArea: "brand-color",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextInput
            label="Brand color"
            placeholder="#3l37e4"
            value={company.branding.brandColor || ""}
            onChange={({ target }) => {
              onChange(
                immer(company => {
                  company.branding.brandColor =
                    target.value === "" ? null : target.value;
                })
              );
            }}
          />
        </div>
        <div
          style={{
            gridArea: "brand-text",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextInput
            label="Inverted text color"
            placeholder="#3l37e4"
            value={company.branding.invertedTextColor || ""}
            onChange={({ target }) => {
              onChange(
                immer(company => {
                  company.branding.invertedTextColor =
                    target.value === "" ? null : target.value;
                })
              );
            }}
          />
        </div>

        <div
          style={{
            gridArea: "is-pilot",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControlLabel
            key="isPilot"
            control={
              <Switch
                checked={company.isPilot}
                onChange={(e, value) =>
                  onChange(
                    immer(company => {
                      company.isPilot = value;
                    })
                  )
                }
                color="primary"
              />
            }
            label="Is a pilot company"
          />
        </div>
      </div>
    );
  }
}
