import React from "react";
import { useMutation, useQuery } from "../Graphql";
import { useHistory, useParams } from "react-router-dom";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { getToken } from "../constants";
import { STORIES_LIST_QUERY, STORY_QUERY } from "../Graphql/queries";
import { StoryEditOrCreateForm } from "../Components/Stories/StoryEditOrCreateForm";
import { UPDATE_STORY_MUTATION } from "../Graphql/mutations";

export const EditStory = () => {
  const { id } = useParams();
  const history = useHistory();

  const { data } = useQuery(STORY_QUERY, {
    variables: { token: getToken(), _id: id },
    fetchPolicy: "no-cache",
  });

  const [
    updateStoryMutation,
    { loading: loadingUpdate, called: calledUpdate },
  ] = useMutation(UPDATE_STORY_MUTATION);

  const handleSubmit = async submitData => {
    await updateStoryMutation({
      variables: {
        token: getToken(),
        _id: id,
        data: {
          connectedRegions: submitData.story.connectedRegions,
          isActive: submitData.story.isActive,
          title: submitData.story.title,
          thumbnailFileName: submitData.story.thumbnailFileName,
          pages: submitData.story.pages.map(page => {
            return {
              _id: page._id,
              alignment: page.alignment,
              isActive: page.isActive,
              fileName: page.fileName,
            };
          }),
        },
      },
      update: (cache, { data }) => {
        const updatedStory = data.updatedStory;

        const { stories } = cache.readQuery({
          query: STORIES_LIST_QUERY,
          variables: { token: getToken() },
        });

        const updatedStories = stories.map(story => {
          if (story._id === updatedStory?._id) {
            return updatedStory;
          }
          return story;
        });
        cache.writeQuery({
          query: STORIES_LIST_QUERY,
          variables: { token: getToken() },
          data: {
            stories: updatedStories,
          },
        });
      },
    });

    history.push("/stories", { updateSuccessful: true });
  };

  return (
    <Page>
      <Container>
        <PageHeader>
          <PageTitle>
            <h2>Edit: {data?.story?.title}</h2>
          </PageTitle>
        </PageHeader>

        {data?.story && (
          <StoryEditOrCreateForm
            story={data.story}
            onSubmit={handleSubmit}
            isDisabled={loadingUpdate || calledUpdate}
          />
        )}
      </Container>
    </Page>
  );
};
