import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import FormInfo from "../Styled/FormInfo";
import FormRow from "../Styled/FormRow";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { LOCATIONS } from "../Client/ClientForm";
import NavLinkAdapter from "../NavLinkAdapter";
import { FileUpload } from "../FileUpload";
import styled from "styled-components";
import { StoryPageEditForm } from "./StoryPageEditForm";
import uuid from "uuid";
import { getToken } from "../../constants";
import { uploadFile } from "../../requests/uploadFile";

const move = (array, from, to) => {
  return (
    (array = array.slice()),
    array.splice(to, 0, ...array.splice(from, 1)),
    array
  );
};

export const StoryEditOrCreateForm = ({ story: storyProp, onSubmit, isDisabled }) => {
  const initialStory = storyProp ?? {
    isActive: false,
    title: "",
    connectedRegions: [],
    thumbnailFileName: null,
    thumbnailFilePath: null,
    pages: [],
  };
  const [story, setStory] = useState(initialStory);

  const [localThumbnailFile, setLocalThumbnailFile] = useState(null);
  const [localThumbnailUrl, setLocalThumbnailUrl] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState([]);

  useEffect(() => {
    // Using effect so that object URL can be revoked during cleanup
    if (!localThumbnailFile) return;
    const objectUrl = URL.createObjectURL(localThumbnailFile);
    setLocalThumbnailUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [localThumbnailFile]);

  const handleThumbnailChange = async file => {
    const isFileUploaded = await uploadFile(
      `${story._id}/${file.name}`,
      file,
      getToken()
    );

    if (!isFileUploaded) {
      console.error("Error uploading file");
    } else {
      setStory(prevState => {
        return { ...prevState, thumbnailFileName: file.name };
      });

      setLocalThumbnailFile(file);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit({ story });
  };

  const handlePageChange = (newPage, index) => {
    setStory(prevState => {
      const pages = [...prevState.pages];
      pages[index] = newPage;
      return { ...prevState, pages };
    });
  };

  const handleFileChange = ({ oldFile, newFile }) => {
    setFilesToUpload(prevState => {
      if (oldFile) {
        const prevFileIndex = prevState.findIndex(
          file => file.name === oldFile.name
        );
        if (prevFileIndex !== -1) {
          prevState.splice(prevFileIndex, 1);
        }
      }
      if (newFile) {
        const newFileIndex = prevState.findIndex(
          file => file.name === newFile.name
        );
        if (newFileIndex === -1) {
          prevState.push(newFile);
        }
      }
      return prevState;
    });
  };

  const handleMoveUp = index => {
    setStory(prevState => {
      const pages = [...prevState.pages];
      return {
        ...prevState,
        pages: move(pages, index, index - 1),
      };
    });
  };

  const handleMoveDown = index => {
    setStory(prevState => {
      const pages = [...prevState.pages];
      return {
        ...prevState,
        pages: move(pages, index, index + 1),
      };
    });
  };

  const handleStoryPageDelete = index => {
    if (!window.confirm(`Are you sure you want to delete this story page?`)) {
      return;
    }

    setStory(prevState => {
      const pages = [...prevState.pages];
      pages.splice(index, 1);
      return {
        ...prevState,
        pages,
      };
    });
  };

  const handleAddNewPage = async file => {
    const isFileUploaded = await uploadFile(
      `${story._id}/${file.name}`,
      file,
      getToken()
    );

    if (!isFileUploaded) {
      console.error("Error uploading file");
    } else {
      setFilesToUpload(prevState => [...prevState, file]);
      setStory(prevState => {
        return {
          ...prevState,
          pages: [
            ...prevState.pages,
            { _id: uuid.v4(), isActive: false, fileName: file.name },
          ],
        };
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <FormInfo>
          <h3>General</h3>
          <p>
            Here you can update all the related content for creating &
            displaying stories within the mobile application.
          </p>
        </FormInfo>
        <div className="inputs">
          {storyProp && (
            <FormControlLabel
              control={
                <Switch
                  checked={story.isActive}
                  onChange={() =>
                    setStory(prevState => {
                      return { ...prevState, isActive: !story.isActive };
                    })
                  }
                  value="enabled"
                  color="primary"
                />
              }
              label="Activate story"
              className="input input__switch"
            />
          )}

          <TextField
            className="input input__text"
            label="Title"
            required
            fullWidth
            autoFocus
            variant="filled"
            value={story.title}
            onChange={event => {
              event.persist();

              setStory(prevState => {
                return { ...prevState, title: event.target.value };
              });
            }}
          />
          <FormControl variant="filled" fullWidth>
            <InputLabel>Regions</InputLabel>
            <Select
              required
              multiple
              value={story.connectedRegions}
              onChange={event =>
                setStory(prevState => {
                  return { ...prevState, connectedRegions: event.target.value };
                })
              }
              renderValue={selected => selected.join(", ")}
            >
              {LOCATIONS.map(region => (
                <MenuItem key={region} value={region}>
                  <Checkbox
                    checked={story.connectedRegions.indexOf(region) > -1}
                  />
                  <ListItemText primary={region} />
                </MenuItem>
              ))}
            </Select>
            <RegionsSubtitle>
              Select regions that will see this story
            </RegionsSubtitle>
          </FormControl>

          {storyProp && (
            <FileUploadContainer>
              {localThumbnailUrl && (
                <ThumbnailPreview src={localThumbnailUrl} />
              )}
              {!localThumbnailUrl && story.thumbnailFilePath != null && (
                <ThumbnailPreview src={story.thumbnailFilePath} />
              )}

              <FileUpload
                name={`file-upload-thumbnail`}
                onChange={handleThumbnailChange}
                createLabel="Add thumbnail"
                updateLabel="Replace thumbnail"
                previousFileKey={story.thumbnailFileName}
              />
            </FileUploadContainer>
          )}
        </div>
      </FormRow>

      {storyProp && (
        <FormRow>
          <FormInfo>
            <h3>Create pages</h3>
            <p>
              Here you can upload the images that will be displayed in the
              story.
            </p>
          </FormInfo>
          <StoryPagesContainer className="inputs">
            {story.pages.map((page, index) => (
              <StoryPageEditForm
                key={page._id}
                page={page}
                onPageChange={changedPage => {
                  handlePageChange(changedPage, index);
                }}
                onFileChange={handleFileChange}
                storyId={story._id ?? null}
                index={index}
                total={story.pages.length}
                onUp={() => {
                  handleMoveUp(index);
                }}
                onDown={() => {
                  handleMoveDown(index);
                }}
                onDelete={() => {
                  handleStoryPageDelete(index);
                }}
                lastSubmittedFile={filesToUpload[filesToUpload.length - 1]}
              />
            ))}
            <AddPageButtonContainer addMargin={story.pages.length > 0}>
              <FileUpload
                name={`file-upload-new-page`}
                onChange={handleAddNewPage}
                createLabel="Add image"
                updateLabel="Add image"
              />
            </AddPageButtonContainer>
          </StoryPagesContainer>
        </FormRow>
      )}

      <FormRow>
        <ButtonsContainer>
          <Button variant="outlined" component={NavLinkAdapter} to="/stories">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >
            Save
          </Button>
        </ButtonsContainer>
      </FormRow>
    </form>
  );
};

const FileUploadContainer = styled("div")`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 20px;
  min-height: 75px;
`;

const ThumbnailPreview = styled("img")`
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
`;

const RegionsSubtitle = styled("p")`
  color: rgba(0, 0, 0, 0.54);
  margin-top: 5px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  gap: 10px;
`;

const StoryPagesContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const AddPageButtonContainer = styled("div")`
  ${({ addMargin }) => addMargin && `margin-left: 48px;`}
`;
