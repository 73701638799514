import React from "react";
import FormInfo from "../../Styled/FormInfo";
import FormRow from "../../Styled/FormRow";
import MultiSelect from "../../MultiSelect";
import { SubmitForm } from "../HandlingPlanForm";

export const Supplier = props => {
  return (
    <form>
      <FormRow>
        <FormInfo>
          <h3>Suppliers</h3>
          <p>
            These suppliers are displayed in the request when this handling plan
            is selected.
          </p>
        </FormInfo>
        <div className="inputs">
          <MultiSelect
            options={props.options}
            optionValueAttribute="_id"
            optionLabelAttribute="name"
            selectedOptions={props.selectedOptions}
            onUpdate={props.onUpdate}
            onDelete={props.onDelete}
          />
        </div>
      </FormRow>
      <SubmitForm handleSubmit={props.handleSubmit} />
    </form>
  );
};
