import React from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";

export const EditingActions = ({
  onCancel,
  onConfirm,
  confirmLabel,
  isDisabled,
}) => {
  return (
    <EditingActionsContainer>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        onClick={onConfirm}
        variant="outlined"
        disabled={isDisabled}
        style={{
          backgroundColor: isDisabled ? undefined : "#42c175",
          color: isDisabled ? undefined : "#ffffff",
          maxWidth: "fit-content",
        }}
      >
        {confirmLabel}
      </Button>
    </EditingActionsContainer>
  );
};

const EditingActionsContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
