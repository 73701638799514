import TextField from "@material-ui/core/TextField";
import React from "react";

export const YouTubeInput = ({
  autofocus = false,
  label = "YouTube video id",
  onChange,
  required = false,
  value = "",
}) => {
  return (
    <div>
      <TextField
        className="input input__text"
        label={label}
        required={required}
        fullWidth={true}
        autoFocus={autofocus}
        variant="filled"
        value={value}
        onChange={event => onChange(event.target.value)}
        style={{ marginBottom: 20 }}
      />
      {value.trim() !== "" && (
        <iframe
          title="YouTube video preview"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${value}`}
          frameBorder="0"
          allowFullScreen
        />
      )}
    </div>
  );
};
