import React from "react";
import styled from "styled-components";
import immer from "immer";
import uuid from "uuid";
import emoji from "node-emoji";
import { Input } from "../Styled/Input";
import { FormRow, Label, MarkupInput } from "./StepForm";
import { DeleteButton } from "./CreateQuestionStep";
import { ProposalOption } from "./StepCard";

export const ProposalOptionsForm = ({ options, onChange }) => {
  const updateStep = (index, key, value) => {
    const nextOptions = immer(options, draftOptions => {
      draftOptions[index][key] = value;
    });

    onChange("options", nextOptions);
  };

  const addOption = () => {
    const nextOptions = immer(options, draft => {
      draft.push({
        _id: uuid.v4(),
        name: "",
        description: "",
        url: "",
        adviceForClient: "",
      });
    });

    onChange("options", nextOptions);
  };

  const deleteOption = index => {
    const updatedOptions = immer(options, draft => {
      draft.splice(index, 1);
    });

    onChange("options", updatedOptions);
  };

  return (
    <OptionsWrapper>
      {options.map((option, index) => (
        <ProposalOption key={option._id}>
          <FormRow>
            <Label>Name</Label>
            <Input
              type="text"
              value={option.name}
              onChange={e => updateStep(index, "name", e.target.value)}
            />
          </FormRow>
          <FormRow>
            <Label>Description</Label>
            <MarkupInput
              value={option.description}
              onChange={newValue =>
                updateStep(index, "description", emoji.emojify(newValue))
              }
            />
          </FormRow>
          <FormRow>
            <Label>Url</Label>
            <Input
              type="text"
              value={option.url}
              onChange={e => updateStep(index, "url", e.target.value)}
            />
          </FormRow>
          <FormRow>
            <Label>Advice For Client</Label>
            <MarkupInput
              value={option.adviceForClient || ""}
              onChange={newValue =>
                updateStep(index, "adviceForClient", emoji.emojify(newValue))
              }
            />
          </FormRow>
          <DeleteButton onClick={() => deleteOption(index)}>
            Delete
          </DeleteButton>
        </ProposalOption>
      ))}

      <AddOption onClick={() => addOption()}>Add Option</AddOption>
    </OptionsWrapper>
  );
};

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddOption = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 3px 9px;
  margin-right: 5px;
  margin-top: 10px;
  font-size: 0.8125rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  max-width: 217px;
  text-align: center;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
