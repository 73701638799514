const get_config = () => {
  const REACT_APP_GRAPHQL_HOST = process.env.REACT_APP_GRAPHQL_HOST
  const config =  {
    backendHost: `https://${REACT_APP_GRAPHQL_HOST}`,
    graphqlHttpUrl: `https://${REACT_APP_GRAPHQL_HOST}/graphql`,
    graphqlWsUrl: `wss://${REACT_APP_GRAPHQL_HOST}/subscriptions`,
  };

  if (process.env.NODE_ENV === "development") {
    const REACT_APP_LOCAL_GRAPHQL_HOST = `${window.location.hostname}:4008`
    config.backendHost = `http://${REACT_APP_LOCAL_GRAPHQL_HOST}`
    config.graphqlHttpUrl = `http://${REACT_APP_LOCAL_GRAPHQL_HOST}/graphql`
    config.graphqlWsUrl = `ws://${REACT_APP_LOCAL_GRAPHQL_HOST}/subscriptions`
  }

  return config;
};

export default get_config();
