import React from "react";
import { isEqual } from "lodash";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import gql from "graphql-tag";

import TableWrapper from "../../Elements/Table/TableWrapper/tableWrapper";
import { RouteTabs } from "../PageElements.js";
import { CompanyEdit } from "./CompanyEdit.js";
import { useMutation, flatten_ids, getToken } from "../../Graphql.js";

import {
  Whitespace,
  Button,
  TextInput,
  MaterialIcon,
  Card,
} from "../Elements.js";
import { applySearch } from "../../helpers.js";
import { CompanyFragment } from "../../Graphql/fragments.js";

import { CompanyTopics } from "./CompanyTopics.js";
import { CompanyRoadmap } from "./CompanyRoadmap";

let getClientsToDisplay = (company, clientFilterQuery) => {
  return company.clients.filter(
    client =>
      clientFilterQuery === "" ||
      applySearch({
        search: clientFilterQuery,
        values: [
          client.firstName,
          client.lastName,
          client.phone,
          client.nickname,
          client.email,
        ],
      })
  );
};

let flatten_company = company => {
  return flatten_ids(
    { ...company, clients: undefined, batches: undefined },
    object => {
      if (object.__typename === "Topic") {
        return { topicId: object._id };
      }
      if (
        object.__typename === "HandlingPlan" ||
        object.__typename === "Todo"
      ) {
        return { todoId: object._id };
      }
    }
  );
};

const UPDATE_COMPANY_MUTATION = gql`
  mutation($token: String!, $id: ID!, $item: InputCompany!) {
    updateCompany(id: $id, token: $token, item: $item) {
      ...CompanyFragment
    }
  }
  ${CompanyFragment}
`;

export let CompanyProfileWithButton = ({
  company: initial_company,
  onSave,
}) => {
  let [company, set_company] = React.useState(initial_company);

  return (
    <React.Fragment>
      <CompanyEdit
        company={company}
        onChange={company => {
          set_company(company);
        }}
      />
      <Whitespace height={8} />
      {/* "b2c" is a special "virtual" company that we use for all company-less people */}
      {company._id !== "b2c" && (
        <Button
          style={{ alignSelf: "flex-end" }}
          disabled={isEqual(company, initial_company)}
          onClick={() => {
            onSave(company);
          }}
        >
          Update
        </Button>
      )}
    </React.Fragment>
  );
};

export let CompanyEditPage = ({ company }) => {
  let match = useRouteMatch();

  let [clientFilterQuery, set_clientFilterQuery] = React.useState("");

  let [updateCompanyMutation] = useMutation(UPDATE_COMPANY_MUTATION);

  let onSave = changes => {
    let { _id, ...item } = changes;
    updateCompanyMutation({
      variables: {
        item: flatten_company(item),
        id: _id,
        token: getToken(),
      },
      optimisticResponse: {
        updateCompany: {
          ...company,
          _id: _id,
          ...item,
        },
      },
    });
  };

  return (
    <Card>
      <Card.Title>{company.name}</Card.Title>
      <RouteTabs
        tabs={[
          {
            label: "Profile",
            to: "profile",
            icon: <MaterialIcon name="domain" size={18} />,
          },
          {
            label: `Clients: ${company.clients.length}`,
            to: "clients",
            icon: <MaterialIcon name="group" size={18} />,
          },
          {
            label: `Knowledge base`,
            to: "Knowledgebase",
            icon: <MaterialIcon name="folder" size={18} />,
          },
          {
            to: "roadmap",
            label: `Roadmap`,
            icon: <MaterialIcon name="date_range" size={18} />,
          },
        ]}
      />
      <Whitespace height={4} />
      <Card.Content>
        <React.Suspense fallback={<Whitespace height={32} />}>
          <Switch>
            <Route
              path={`${match.path}/profile`}
              render={() => (
                <CompanyProfileWithButton
                  company={company}
                  onSave={company => onSave(company)}
                />
              )}
            />

            <Route
              path={`${match.path}/clients`}
              render={() => (
                <>
                  <TextInput
                    placeholder="Search clients"
                    onChange={e => set_clientFilterQuery(e.target.value)}
                    value={clientFilterQuery}
                  />
                  <TableWrapper
                    data={getClientsToDisplay(company, clientFilterQuery)}
                    columns={clientTableColumns}
                    navigate={"clients"}
                  />
                </>
              )}
            />
            <Route
              path={`${match.path}/Knowledgebase`}
              render={() => (
                <CompanyTopics
                  company={company}
                  onChange={company => {
                    onSave(company);
                  }}
                />
              )}
            />
            <Route
              path={`${match.path}/roadmap`}
              render={() => <CompanyRoadmap company={company} />}
            />
            <Redirect
              exact
              from={`${match.path}/`}
              to={`${match.url}/profile`}
            />
          </Switch>
        </React.Suspense>
      </Card.Content>
    </Card>
  );
};

const clientTableColumns = [
  {
    key: "firstName",
    label: "First name",
    type: String,
  },
  {
    key: "lastName",
    label: "Last name",
    type: String,
  },
  {
    key: "role",
    label: "Role",
    type: String,
  },
  {
    key: "paidUntil",
    label: "Paid until",
    type: Date,
  },
  {
    key: "createdAt",
    label: "Created at",
    type: Date,
  },
];
