import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { TOKEN } from "../../constants";

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import "./login.css";

const ADMIN_LOGIN = gql`
  mutation($username: String!, $password: String!) {
    adminLogin(username: $username, password: $password) {
      _id
      success
    }
  }
`;

const COMPLETE_SMS_VERIFICATION_MUTATION = gql`
  mutation($verificationId: String!, $smsCode: String!) {
    completeVerification(_id: $verificationId, code: $smsCode) {
      success
      token
    }
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationId: null,
      smsCode: null,
    };
  }

  reloadPage() {
    window.location.reload(true);
  }

  async submitHandler(adminLogin) {
    const adminVal = await adminLogin({
      variables: {
        username: this.state.username,
        password: this.state.password,
      },
    });

    this.setState({
      adminSuccess: adminVal.data.adminLogin.success,
      verificationId: adminVal.data.adminLogin._id,
    });
  }
  async codeHandler(completeVerification) {
    const { smsCode, verificationId } = this.state;
    const codeVal = await completeVerification({
      variables: { verificationId, smsCode },
    });

    this.setState({
      codeSuccess: codeVal.data.completeVerification.success,
      token: codeVal.data.completeVerification.token,
    });

    localStorage.setItem(TOKEN, this.state.token);

    const period = new Date();
    period.setHours(period.getHours() + 8);

    if (this.state.codeSuccess) {
      this.reloadPage();
    }
  }

  render() {
    return (
      <main className="login">
        <article className="paper">
          <section className="logo">
            <div>
              <img src="./assets/images/title-image.png" alt="Logo uButler" />
            </div>
          </section>
          {!this.state.verificationId ? (
            <section>
              <Mutation mutation={ADMIN_LOGIN} key={1}>
                {(adminLogin, { loading, called }) => (
                  <div>
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        this.submitHandler(adminLogin);
                      }}
                    >
                      <label htmlFor="username">
                        <TextField
                          className="input"
                          type="text"
                          label="Username"
                          id="username"
                          name="username"
                          required
                          ref={input => (this.username = input)}
                          onChange={e =>
                            this.setState({ username: e.target.value })
                          }
                        />
                      </label>
                      <label htmlFor="password">
                        <TextField
                          className="input"
                          type="password"
                          id="password"
                          label="Password"
                          name="password"
                          required
                          ref={input => (this.password = input)}
                          onChange={e =>
                            this.setState({ password: e.target.value })
                          }
                        />
                      </label>
                      {!loading && (
                        <Button
                          type="submit"
                          variant="contained"
                          className="button"
                          color="primary"
                        >
                          Get code
                        </Button>
                      )}
                    </form>
                    {loading && <CircularProgress className="loader" />}
                    {!loading && called && !this.state.adminSuccess && (
                      <p className="error-message">
                        Your password or username is incorrect
                      </p>
                    )}
                  </div>
                )}
              </Mutation>
            </section>
          ) : (
            <section>
              <Mutation mutation={COMPLETE_SMS_VERIFICATION_MUTATION} key={2}>
                {(completeVerification, { loading, called }) => (
                  <div>
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        this.codeHandler(completeVerification);
                      }}
                    >
                      <label htmlFor="smsCode">
                        <TextField
                          autoFocus
                          className="input"
                          type="text"
                          label="SMS code"
                          id="smsCode"
                          name="smsCode"
                          required
                          ref={input => (this.smsCode = input)}
                          onChange={e =>
                            this.setState({ smsCode: e.target.value })
                          }
                        />
                      </label>
                      {!loading && (
                        <Button
                          type="submit"
                          variant="contained"
                          className="button"
                          color="primary"
                        >
                          Log in
                        </Button>
                      )}
                    </form>
                    {loading && <CircularProgress className="loader" />}
                    {!loading && called && !this.state.codeSuccess && (
                      <p className="error-message">
                        Your code is invalid. Please try again
                      </p>
                    )}
                  </div>
                )}
              </Mutation>
            </section>
          )}
        </article>
      </main>
    );
  }
}

export default Login;
