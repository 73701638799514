import React from "react";
import MaskedInput, { conformToMask } from "react-text-mask";
import { zip, flatten } from "lodash";

let mask = (strings, ...values) => {
  let elements = flatten(zip(strings, values)).filter(x => x != null);
  return flatten(
    elements.map(element => {
      if (typeof element === "string") {
        return [...element];
      } else {
        return [element];
      }
    })
  );
};

// prettier-ignore
let dutch_mobile_phone_number = mask`+${/[1-9]/}${/\d/} ${/[1-9]/} ${/\d/}${/\d/}${/\d/}${/\d/}${/\d/}${/\d/}${/\d/}${/\d/}`
// prettier-ignore
let generic_phone_format = mask`+${/[1-9]/}${/\d/}${/[0-9]/} ${/\d/} ${/\d/}${/\d/} ${/\d/}${/\d/} ${/\d/}${/\d/} ${/\d/}${/\d/} ${/\d/}${/\d/}`;
// prettier-ignore
let american_phone_format = mask`+${/[1-9]/} (${/\d/}${/\d/}${/\d/}) ${/\d/}${/\d/}${/\d/}-${/\d/}${/\d/}${/\d/}${/\d/}`

export let TelephoneMask = ({ inputRef, value, onChange, ...props }) => {
  let mask = value.startsWith("+1")
    ? american_phone_format
    : value.startsWith("+316")
    ? dutch_mobile_phone_number
    : generic_phone_format;

  let { conformedValue } = conformToMask(value, mask, {
    guide: false,
  });

  let one_char_less_conformed = conformedValue.replace(/[^+0-9]+$/, "");

  return (
    <MaskedInput
      value={one_char_less_conformed}
      mask={mask}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onChange={event => {
        let new_value = event.target.value.replace(/[^+0-9]/g, "");
        onChange({
          target: { value: new_value },
        });
      }}
      showMask={false}
      guide={false}
      {...props}
    />
  );
};
