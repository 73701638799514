import { Button } from "@material-ui/core";
import emoji from "node-emoji";
import React, { useEffect, useState } from "react";

import { FormGroup, MarkupInput } from "../Steps/StepForm";
import { CHECKLIST_PHASE, ROUTE_PHASE } from "../HandlingPlans/Sections/Phases";
import { RoutePhase } from "./RoutePhase";
import { Checklist } from "./Checklist";
import { EditingFieldTitle, EditingRequired } from "./EditingBox";

export const PhaseEditor = ({
  checklistItems,
  onSave,
  phase: initialValue,
  routes,
}) => {
  const [phase, setPhase] = useState(initialValue);
  const [isEditingChild, setIsEditingChild] = useState(false);

  useEffect(() => {
    setPhase(initialValue);
  }, [initialValue]);

  const disableSaveButton =
    phase.title.trim() === "" ||
    phase.description.trim() === "" ||
    isEditingChild;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <FormGroup noPadding>
        <EditingFieldTitle>
          Description <EditingRequired>*Required</EditingRequired>
        </EditingFieldTitle>

        <MarkupInput
          value={phase.description}
          onChange={newValue =>
            setPhase({ ...phase, description: emoji.emojify(newValue) })
          }
          placeholder="What is the goal of this phase? e.g: create the perfect search query to start home hunting"
        />
      </FormGroup>

      {phase.title === ROUTE_PHASE ? (
        <RoutePhase
          checklistItems={checklistItems}
          routes={routes}
          onEditingUpdate={setIsEditingChild}
        />
      ) : (
        <Checklist
          checklistItems={checklistItems}
          isChecklistPhase={phase.title === CHECKLIST_PHASE}
          phaseId={phase._id}
          routes={routes}
          onEditingUpdate={setIsEditingChild}
        />
      )}

      <div
        style={{
          alignSelf: "flex-end",
          display: "flex",
        }}
      >
        <Button
          onClick={() => {
            onSave(phase);
            setPhase({ _id: "", title: "", description: "" });
          }}
          disabled={disableSaveButton}
          variant="outlined"
          style={{
            backgroundColor: disableSaveButton ? undefined : "#42c175",
            color: disableSaveButton ? undefined : "#ffffff",
            maxWidth: "256px",
            margin: "10px 0",
          }}
        >
          Save phase
        </Button>
      </div>
    </div>
  );
};
