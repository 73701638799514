import React from "react";
import { ProposalOptionsForm } from "./ProposalOptionsForm";
import { FormRow } from "./StepForm";

export const CreateProposalStep = ({ step, onChange }) => {
  return (
    <FormRow>
      <ProposalOptionsForm options={step.options} onChange={onChange} />
    </FormRow>
  );
};
