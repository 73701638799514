import React from "react";
import gql from "graphql-tag";
import matchSorter from "match-sorter";

import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";

import { useQuery, getToken } from "../Graphql.js";

const filterOptions = (options, { inputValue, ...stuff }) => {
  for (let option of options) {
    option.__temp_for_matchSorter = `${option.title} @ ${option.region}`;
  }
  return matchSorter(options, inputValue, { keys: ["__temp_for_matchSorter"] });
};

let HANDLING_PLANS_LIST_QUERY = gql`
  query($token: String!) {
    handlingPlans(token: $token) {
      _id
      title
      region
      enabled
    }
  }
`;

export let HandlingplanSearch = ({ value, onChange, label, ...props }) => {
  let {
    data: { handlingPlans },
  } = useQuery(HANDLING_PLANS_LIST_QUERY, {
    variables: {
      token: getToken(),
    },
    suspend: true,
  });

  return (
    <Autocomplete
      options={handlingPlans}
      getOptionLabel={option => `${option.title} @ ${option.region}`}
      filterOptions={filterOptions}
      // style={{ width: 300 }}
      renderInput={params => (
        <TextField {...params} label={label} variant="filled" fullWidth />
      )}
      onChange={(event, value) => {
        onChange(value);
      }}
      value={value}
      {...props}
    />
  );
};
