import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React from "react";
import FormInfo from "../Styled/FormInfo";
import FormRow from "../Styled/FormRow";
import { MarkupTextArea } from "../MarkupTextArea";

export class QuestionAndAnswerForm extends React.Component {
  state = {
    question: "",
    answer: "",
  };

  componentDidMount() {
    const { qa } = this.props;

    // Overwrite state if qa has that property.
    if (qa != null) {
      this.setState(
        Object.entries(this.state).reduce((newState, [key, value]) => {
          newState[key] = qa[key] || value;
          return newState;
        }, {})
      );
    }
  }

  handleChange = (name, targetProperty = "value") => value => {
    this.setState({
      [name]: value.target ? value.target[targetProperty] : value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    this.props.onSubmit({
      ...this.state,
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormRow>
          <FormInfo>
            <h3>Question</h3>
            <p>This is also shown on the overview page.</p>
          </FormInfo>

          <div className="inputs">
            <TextField
              className="input input__text"
              label="Question"
              required
              fullWidth={true}
              autoFocus={true}
              variant="filled"
              value={this.state.question}
              onChange={this.handleChange("question")}
            />
          </div>
        </FormRow>

        <FormRow>
          <FormInfo>
            <h3>Answer</h3>
            <p>42?</p>
          </FormInfo>

          <div className="inputs">
            <MarkupTextArea
              value={this.state.answer}
              onChange={this.handleChange("answer")}
            />
          </div>
        </FormRow>

        <FormRow>
          <Button type="submit" variant="contained" color="primary">
            {this.props.buttonText || "Save"}
          </Button>
        </FormRow>
      </form>
    );
  }
}
