import React from "react";
import styled from "styled-components";
import { Browser } from "./index";
import { Button } from "../Elements";

const NavigationContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 10px;
`;

export const Navigation = ({
  activeBatch,
  setActiveBatch,
  setIsCreator,
  totalBatches,
}) => {
  return (
    <NavigationContainer>
      {totalBatches >= 1 && (
        <Browser
          activeBatch={activeBatch}
          setActiveBatch={setActiveBatch}
          totalBatches={totalBatches}
        />
      )}
      <Button
        onClick={() => setIsCreator(true)}
        style={{
          height: "40px",
          display: "block",
          width: "200px",
        }}
      >
        Add a period
      </Button>
    </NavigationContainer>
  );
};
