import React, { useState } from "react";
import styled from "styled-components";

import { StepCard } from "./StepCard";
import { StepForm } from "./StepForm";

export const StepType = {
  CREATE_PROPOSAL: "create_proposal",
  DO: "do",
  QUESTION: "question",
  SEND_MESSAGE: "send_message",
  SEND_PROPOSAL: "send_proposal",
};

export const StepProps = {
  previousStepId: null,
  title: "",
  maxDuration: {
    minutes: 0,
    hours: 0,
    days: 0,
  },
  needETA: false,
  timeFrame: {
    minutes: 0,
    hours: 0,
    days: 0,
  },
  responsibility: "chatter",
  adviceForVB: "", //WWMD
  showConclusionInOverview: true,
  //Step specific attributes
  instruction: "",
  exampleMessage: "",
  questions: [],
  options: [],
  comment: null,
  createProposalStepId: null,
};

export const Step = ({
  allSteps,
  number,
  onChange,
  UpdateSteps,
  onDelete,
  onMoveDown,
  onMoveUp,
  step,
}) => {
  const [editing, set_editing] = useState(step.title === ""); // New steps should start in form mode
  return (
    <article style={{ display: "flex", marginBottom: 12 }}>
      <StepNumber>{number}</StepNumber>

      {!editing && (
        <StepCard
          allSteps={allSteps}
          onEdit={() => set_editing(true)}
          onMoveDown={onMoveDown}
          onMoveUp={onMoveUp}
          step={step}
          style={{ marginBottom: 12 }}
        />
      )}

      {editing && (
        <StepForm
          allSteps={allSteps}
          onChange={onChange}
          onClose={() => {
            UpdateSteps();
            set_editing(false);
          }}
          onDelete={onDelete}
          step={step}
        />
      )}
    </article>
  );
};

export const StepNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  margin: 21px 22px 0 0;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background: #42c175;
`;

Step.defaultProps = StepProps;
