import React from "react";
import MuiTableCell from "@material-ui/core/TableCell";
import styled from "styled-components";

export const TableCell = ({
  children,
  width,
  isCentered,
  backgroundColor = "white",
}) => {
  return (
    <StyledTableCell
      width={width}
      isCentered={isCentered}
      backgroundColor={backgroundColor}
    >
      {children}
    </StyledTableCell>
  );
};

const StyledTableCell = styled(({ isCentered, backgroundColor, ...props }) => (
  <MuiTableCell {...props} />
))`
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ width }) => width && `width: ${width};`};
  ${({ isCentered }) =>
    isCentered &&
    `padding-left: 0!important; padding-right: 0!important; text-align: center!important;`};
`;
