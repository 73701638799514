import React from "react";
import styled from "styled-components";
import dompurify from "dompurify";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { questionType, getQuestionsOfType } from "./CreateQuestionStep";

const find_linked_step_title = (steps, linkedStepId) => {
  const step = steps.find(step => step._id === linkedStepId);
  return step.title;
};

export const StepCard = ({
  allSteps,
  number,
  onEdit,
  onMoveDown,
  onMoveUp,
  step,
  style = {},
}) => {
  const {
    type,
    title,
    maxDuration,
    responsibility,
    adviceForVB,
    showConclusionInOverview,
    needETA,

    instruction,
    exampleMessage,
    questions,
    options,
    createProposalStepId,
  } = step;

  const timeFrame = step.timeFrame ?? { days: 0, hours: 0, minutes: 0 };

  return (
    <Card>
      <StepInfo>
        <TextInfo>
          <Title>{title}</Title>
          <Type>{type}</Type>
          <InfoGroup>
            <InfoLabel>Max duration: </InfoLabel>
            <InfoValue>
              {maxDuration.hours !== 0 && maxDuration.hours + "h "}
              {maxDuration.minutes} min
            </InfoValue>
          </InfoGroup>

          {needETA && (
            <InfoGroup>
              <InfoLabel>Complete within: </InfoLabel>
              <InfoValue>
                {timeFrame.days !== 0 && timeFrame.days + " days "}
                {timeFrame.hours} hours {timeFrame.minutes} minutes
              </InfoValue>
            </InfoGroup>
          )}

          <InfoGroup>
            <InfoLabel>Responsibility: </InfoLabel>
            <InfoValue>{responsibility}</InfoValue>
          </InfoGroup>

          {adviceForVB && (
            <InfoGroup>
              <InfoLabel>
                WWMD: 🚧(won't show on the portal for now)🚧
              </InfoLabel>
              <InfoValue
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(adviceForVB),
                }}
              />
            </InfoGroup>
          )}

          {instruction && (
            <InfoGroup>
              <InfoLabel>Instruction: </InfoLabel>
              <InfoValue
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(instruction),
                }}
              />
            </InfoGroup>
          )}

          {exampleMessage && (
            <InfoGroup>
              <InfoLabel>Example Message: </InfoLabel>
              <InfoValue
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(exampleMessage),
                }}
              />
            </InfoGroup>
          )}

          {questions.length > 0 && <Questions questions={questions} />}

          {options.length > 0 && <ProposalOptions options={options} />}

          {createProposalStepId && (
            <div>
              <InfoLabel>linked to step: </InfoLabel>
              <InfoValue>
                {find_linked_step_title(allSteps, createProposalStepId)}
              </InfoValue>
            </div>
          )}
        </TextInfo>

        <CheckMarks>
          {needETA && (
            <HasETA>
              <CheckCircleOutlineIcon
                style={{ color: "#42C175", fontSize: 14, marginRight: "3px" }}
              />
              <div> This step should have an ETA</div>
            </HasETA>
          )}
          {showConclusionInOverview && (
            <KeyInformation>
              <CheckCircleOutlineIcon
                style={{ color: "#42C175", fontSize: 14, marginRight: "3px" }}
              />
              <div>This step result will show as Key Information </div>
            </KeyInformation>
          )}
        </CheckMarks>
      </StepInfo>

      <StepButtons>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <UpButton onClick={onMoveUp} disabled={number === 1}>
            Move up
          </UpButton>
          <DownButton
            onClick={onMoveDown}
            disabled={number === allSteps.length}
          >
            Move down
          </DownButton>
        </div>

        <CardButton onClick={onEdit}>edit</CardButton>
      </StepButtons>
    </Card>
  );
};

const Questions = ({ questions }) => {
  return (
    <>
      <InfoLabel>Questions:</InfoLabel>
      {Object.values(questionType).map(type => (
        <>
          {getQuestionsOfType(questions, type).length > 0 && (
            <h3 style={{ textTransform: "capitalize" }}>{type} Related</h3>
          )}
          {getQuestionsOfType(questions, type).map(question => (
            <Question>
              <Required>
                {question.required && (
                  <Tooltip
                    title="Required. 
                    can't complete the step without 
                    answering this question"
                    placement="top-start"
                  >
                    <ErrorOutlineIcon />
                  </Tooltip>
                )}
              </Required>
              <QuestionInfo>
                <div>
                  <InfoLabel>Key: </InfoLabel>
                  <span>{question.key}</span>
                </div>
                {question.exampleQuestion && (
                  <div>
                    <InfoLabel>Example question: </InfoLabel>
                    <span>{question.exampleQuestion}</span>
                  </div>
                )}
              </QuestionInfo>
            </Question>
          ))}
        </>
      ))}
    </>
  );
};

const ProposalOptions = ({ options }) => {
  return (
    <>
      <InfoLabel>Proposal Options:</InfoLabel>
      {options.map(option => (
        <ProposalOption key={option._id}>
          <InfoGroup>
            <InfoLabel>Name: </InfoLabel>
            {option.name}
          </InfoGroup>
          <InfoGroup>
            <InfoLabel>Description: </InfoLabel>
            <InfoValue
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(option.description),
              }}
            />
          </InfoGroup>
          <InfoGroup>
            <InfoLabel>Url: </InfoLabel>
            {option.url}
          </InfoGroup>
          {option.adviceForClient && (
            <InfoGroup>
              <InfoLabel>Advice: </InfoLabel>
              <InfoValue
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(option.adviceForClient),
                }}
              />
            </InfoGroup>
          )}
        </ProposalOption>
      ))}
    </>
  );
};

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 21px;
  color: #2a2135;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
`;

const StepInfo = styled.div`
  display: flex;
  flex: 1;
  padding-right: 21px;
  font-size: 13px;
  font-weight: bold;
`;

const StepButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
`;

const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 21px;
`;

const CheckMarks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
`;

const Type = styled.span`
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: bold;
  color: #657786;
`;
const InfoGroup = styled.div`
  margin-bottom: 9px;

  :last-child {
    margin-bottom: 0;
  }
`;

const InfoLabel = styled.span`
  color: #657786;
`;

const InfoValue = styled.span``;

const KeyInformation = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
`;

const HasETA = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
`;
const Question = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  padding: 12px;
  margin: 10px 0;
`;

const Required = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const QuestionInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProposalOption = styled.div`
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  padding: 12px;
  margin: 10px 0;
`;

export const CardButton = styled.button`
  border: 0;
  font-size: 13px;
  font-weight: bold;
  color: #657786;
  text-align: right;
  background: none;
  cursor: pointer;

  &[disabled] {
    color: #9cadba;
    cursor: initial;
  }
`;

export const UpButton = styled(CardButton)`
  margin-bottom: 9px;

  :after {
    display: inline-block;
    content: "\";
    border: 6px solid transparent;
    border-top: 0 none;
    border-bottom: 7px solid #657786;
    margin-left: 9px;
  }
  
  &[disabled]:after {
    border-bottom-color: #9cadba;
  }
`;

export const DownButton = styled(UpButton)`
  :after {
    border-top: 7px solid #657786;
    border-bottom: 0 none;
  }

  &[disabled]:after {
    border-top-color: #9cadba;
  }
`;
