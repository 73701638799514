import styled from "styled-components/macro";

const PageTitle = styled.div`
  display: flex;

  h2 {
    text-transform: capitalize;
    color: hsl(164, 6%, 40%);
    font-size: 24px;
    margin-right: 40px;
  }

  button {
    margin-right: 20px;
  }
`;

export default PageTitle;
