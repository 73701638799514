import React from "react";
import FormRow from "../../Styled/FormRow";
import { QuestionAndAnswerForm } from "../../QuestionAndAnswerForm.js";
import { SubmitForm } from "../HandlingPlanForm";

export const QA = props => {
  return (
    <form>
      <FormRow>
        <QuestionAndAnswerForm
          value={props.questionsAndAnswers}
          onChange={props.handleChange("questionsAndAnswers")}
        />
      </FormRow>
      <SubmitForm handleSubmit={props.handleSubmit} />
    </form>
  );
};
