import React from "react";
import { isEqual } from "lodash";
import { Whitespace, Button, Card } from "../Elements.js";
import { SupplierEdit } from "./SupplierEdit.js";

export class SupplierEditPage extends React.Component {
  state = {
    supplier: this.props.supplier,
  };

  render() {
    let { supplier: old_supplier, onSave } = this.props;
    let { supplier } = this.state;

    return (
      <Card>
        <Card.Title>{supplier.supplier}</Card.Title>

        <SupplierEdit
          supplier={supplier}
          onChange={supplier => {
            this.setState({ supplier });
          }}
        />
        <Whitespace height={8} />

        <Card.Content>
          <Button
            style={{ alignSelf: "flex-end" }}
            disabled={isEqual(supplier, old_supplier)}
            onClick={() => onSave(supplier)}
          >
            Update
          </Button>
        </Card.Content>
      </Card>
    );
  }
}
