import React from "react";
import styled from "styled-components";
import emoji from "node-emoji";
import { ChooseOption } from "../ChooseOption";
import { DurationForm } from "../DurationForm";
import { Button } from "../Styled/Button";
import { Input } from "../Styled/Input";
import { MarkupTextArea } from "../MarkupTextArea";
import { CreateProposalStep } from "./CreateProposalStep";
import { CreateQuestionStep } from "./CreateQuestionStep";
import { SendMessageStep } from "./SendMessageStep";
import { SendProposalStep } from "./SendProposalStep";
import { StepType } from "./Step";
import { HelpInfo } from "../../Elements/HelpInfo";

const RESPONSIBLE = {
  CHATTER: "chatter",
  HANDLER: "handler",
};

const renderSpecificStep = (specificStep, onChange, allSteps = []) => {
  switch (specificStep.type) {
    case StepType.SEND_MESSAGE:
      return <SendMessageStep step={specificStep} onChange={onChange} />;
    case StepType.DO:
      return;
    case StepType.CREATE_PROPOSAL:
      return <CreateProposalStep step={specificStep} onChange={onChange} />;
    case StepType.SEND_PROPOSAL:
      return (
        <SendProposalStep
          step={specificStep}
          onChange={onChange}
          allSteps={allSteps}
        />
      );
    case StepType.QUESTION:
      return <CreateQuestionStep step={specificStep} onChange={onChange} />;
    default:
      return (
        <div style={{ padding: 18 }}>
          Oops! We could not find the right step type for this step.
        </div>
      );
  }
};

export const StepForm = ({ allSteps, onChange, onClose, onDelete, step }) => {
  const {
    type,
    title,
    maxDuration,
    responsibility,
    showConclusionInOverview = true,
    needETA = false,
  } = step;
  const timeFrame = step.timeFrame ?? { days: 0, hours: 0, minutes: 0 };

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <Type>{type}</Type>
        <DeleteButton onClick={onDelete}>Delete</DeleteButton>
      </div>

      <div style={{ display: "flex" }}>
        <FormGroup>
          <FormRow>
            <Label>Title</Label>
            <Input
              onChange={e => onChange("title", e.target.value)}
              placeholder="Title"
              type="text"
              value={title}
            />
          </FormRow>

          <FormRow>
            <HelpInfo
              description="How long will it take to complete this step?"
              style={{ color: "#657786", fontSize: 16, marginRight: "5px" }}
            />
            <Label>Max duration</Label>
            <DurationForm
              duration={maxDuration}
              onChangeDuration={duration => onChange("maxDuration", duration)}
            />
          </FormRow>

          <FormRow>
            <Label>Needs ETA</Label>
            <input
              type="checkbox"
              checked={needETA}
              onChange={e => onChange("needETA", e.target.checked)}
            />
          </FormRow>

          {needETA && (
            <FormRow>
              <Label>Time frame</Label>
              <DurationForm
                showDays
                duration={timeFrame}
                onChangeDuration={duration => onChange("timeFrame", duration)}
              />
            </FormRow>
          )}

          <FormRow>
            <Label>Responsibility</Label>
            <ChooseOption
              onSelect={option => onChange("responsibility", option.key)}
              options={[
                { key: RESPONSIBLE.CHATTER, label: "Chatter" },
                { key: RESPONSIBLE.HANDLER, label: "Handler" },
              ]}
              value={responsibility}
            />
          </FormRow>

          <FormRow>
            <Label>Step result is key information</Label>
            <input
              type="checkbox"
              checked={showConclusionInOverview}
              onChange={e =>
                onChange("showConclusionInOverview", e.target.checked)
              }
            />
          </FormRow>

          <FormRow>
            <Label>WWMD - What Would Martijn Do?</Label>
            <div>
              <span>Tips and tricks to help vb's be extra sharp</span>
              <MarkupInput
                onChange={markup =>
                  onChange("adviceForVB", emoji.emojify(markup))
                }
                value={step.adviceForVB}
                placeholder="e.g. The expat center in Amsterdam is close to ABN AMRO. Schedule meetings for BSN and bank account on the same day "
              />
            </div>
          </FormRow>

          <FormRow>
            <Label>Instruction</Label>
            <MarkupInput
              value={step.instruction}
              onChange={newValue =>
                onChange("instruction", emoji.emojify(newValue))
              }
              placeholder="e.g. send a validation message to the client. This message should include all the information on which you base your search for options and the ETA for the options."
            />
          </FormRow>

          <div style={{ height: 5 }} />

          {renderSpecificStep(step, onChange, allSteps)}
        </FormGroup>

        <Buttons>
          <Button onClick={onClose}>Close</Button>
        </Buttons>
      </div>
    </Card>
  );
};

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 21px;
  color: #2a2135;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  margin: 10px 0;
`;

const Type = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
`;

const DeleteButton = styled.button`
  border: 0;
  font-size: 13px;
  font-weight: bold;
  color: #657786;
  text-align: right;
  background: none;
  cursor: pointer;
`;

export const FormGroup = styled.fieldset`
  flex: 1;
  padding-right: ${({ noPadding }) => (noPadding ? 0 : "50px")};
  border: 0;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 10px;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 7px;
`;

export const Label = styled.span`
  display: block;
  flex-shrink: 0;
  margin-right: 20px;
  color: #657786;
`;

export const MarkupInput = styled(MarkupTextArea)`
  min-width: 300px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #d9e4eb;
  font-size: 12px;
  font-weight: 500;
  color: #2a2135;
`;

const Buttons = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-end;
`;
