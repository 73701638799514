export const StepTypes = {
  QUESTIONS: "questions",
  TEXT: "text",
  WAYPOINT: "waypoint",
};

export const QuestionContexts = {
  PROFILE_RELATED: "profile",
  REQUEST_RELATED: "request",
  PREFERENCES: "preferences",
};

export const HandlingPlanPhases = {
  PROPOSAL: "proposal",
  HANDLING: "handling",
};
