import React, { useState } from "react";
import styled from "styled-components";
import immer from "immer";
import uuid from "uuid";
import { Step, StepProps, StepType } from "./Step";

export const StepsList = ({ steps: propSteps, onStepsChange }) => {
  const [steps, setSteps] = useState(propSteps);
  return (
    <>
      <main>
        {steps.map((step, index) => (
          <Step
            number={index + 1}
            key={step._id}
            allSteps={steps}
            onChange={(key, value) => {
              const nextSteps = immer(steps, draftSteps => {
                if (value === "<p><br></p>") {
                  draftSteps[index][key] = "";
                } else {
                  draftSteps[index][key] = value;
                }
              });
              setSteps(nextSteps);
            }}
            UpdateSteps={() => onStepsChange(steps)}
            onDelete={() => {
              const { _id } = step;
              const unlinkedSteps = steps.map(step => {
                if (step.createProposalStepId === _id) {
                  return {
                    ...step,
                    createProposalStepId: null,
                  };
                } else {
                  return step;
                }
              });
              const nextSteps = immer(unlinkedSteps, draftSteps => {
                draftSteps.splice(index, 1);
              });
              setSteps(nextSteps);
            }}
            onMoveDown={() => {
              if (index === steps.length - 1) return;
              const nextSteps = immer(steps, draftSteps => {
                draftSteps[index] = steps[index + 1];
                draftSteps[index + 1] = steps[index];
              });
              setSteps(nextSteps);
            }}
            onMoveUp={() => {
              if (index === 0) return;
              const nextSteps = immer(steps, draftSteps => {
                draftSteps[index] = steps[index - 1];
                draftSteps[index - 1] = steps[index];
              });
              setSteps(nextSteps);
            }}
            step={step}
          />
        ))}
      </main>

      <footer style={{ paddingLeft: 43 }}>
        <StepsButtonsWrapper>
          {Object.values(StepType).map(type => (
            <StepTypeButton
              key={type}
              onClick={e => {
                e.preventDefault();
                const nextSteps = immer(steps, draftSteps => {
                  draftSteps.push({
                    _id: uuid.v4(),
                    type,
                    ...StepProps,
                  });
                });
                setSteps(nextSteps);
              }}
            >
              {type}
            </StepTypeButton>
          ))}
        </StepsButtonsWrapper>
        <UpdateButton onClick={() => onStepsChange(steps)}>Update</UpdateButton>
      </footer>
    </>
  );
};

const StepsButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StepTypeButton = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 3px 9px;
  margin-right: 5px;
  font-size: 0.8125rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
export const UpdateButton = styled.div`
  color: ${props => (props.disabled ? "#657786" : "#ffffff")};
  background-color: ${props => (props.disabled ? "#eef2f5" : "#42c175")};
  padding: 6px 16px;
  font-weight: bold;
  min-width: 64px;
  max-width: 95px;
  margin: 10px 0;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${props => (props.disabled ? "#eef2f5" : "#319b61")};
  }
`;
