import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  height: 28px;
  padding: 0 10px;
  border-radius: 5px;
  border: solid 1px #d9e4eb;
  font-size: 12px;
  font-weight: 500;
  color: #2a2135;
  box-shadow: ${({ pending }) =>
    pending ? "0 0 0 3px rgba(237, 137, 54, 0.5)" : "none"};
`;
