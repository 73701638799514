import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";

import { useQuery, useMutation } from "../Graphql.js";
import NavLinkAdapter from "../Components/NavLinkAdapter";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { getToken } from "../constants";
import {
  REMOVE_STORY_MUTATION,
  UPDATE_STORY_MUTATION,
} from "../Graphql/mutations.js";
import { STORIES_LIST_QUERY } from "../Graphql/queries.js";
import Table from "../Components/ComposableTable";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory, useLocation } from "react-router-dom";
import { UpdateConfirmation } from "../Elements/HelpInfo.js";
import { deleteFiles } from "../requests/deleteFile.js";

export const Stories = () => {
  const { state } = useLocation();
  const { replace } = useHistory();

  useEffect(() => {
    if (state?.updateSuccessful || state?.createSuccessful) {
      replace({ state: {} });
    }
  }, [state, replace]);

  const [removeStoryMutation] = useMutation(REMOVE_STORY_MUTATION, {
    variables: {
      token: getToken(),
    },
    update: (cache, { data }) => {
      const storyId = data.removeStory.id;

      const { stories } = cache.readQuery({
        query: STORIES_LIST_QUERY,
        variables: { token: getToken() },
      });

      cache.writeQuery({
        query: STORIES_LIST_QUERY,
        variables: { token: getToken() },
        data: {
          stories: stories.filter(story => story._id !== storyId),
        },
      });
    },
  });

  const { data } = useQuery(STORIES_LIST_QUERY, {
    variables: { token: getToken() },
  });

  const [updateHandlingPlanMutation, { loading: loadingUpdate }] = useMutation(
    UPDATE_STORY_MUTATION
  );

  const handeStoryUpdate = (_id, isActive) => {
    updateHandlingPlanMutation({
      variables: {
        token: getToken(),
        _id,
        data: { isActive },
      },
    });
  };

  const handleStoryDelete = async (_id, title) => {
    if (
      !window.confirm(`Are you sure you want to delete the story ${title}?`)
    ) {
      return;
    }

    await removeStoryMutation({
      variables: {
        _id,
      },
    });

    if (!await deleteFiles(_id, getToken())) {
      console.error("Error deleting files");
    }
  };

  return (
    <Page>
      <Container>
        <PageHeader>
          <PageTitle>
            <h2>Stories</h2>
            <Button
              variant="contained"
              size="small"
              component={NavLinkAdapter}
              to="/stories/create"
            >
              Add
            </Button>
          </PageTitle>
        </PageHeader>

        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell width="33%">Title</Table.Cell>
              <Table.Cell>Connected to regions</Table.Cell>
              <Table.Cell>Created at</Table.Cell>
              <Table.Cell isCentered>Active</Table.Cell>
              <Table.Cell isCentered />
              <Table.Cell isCentered />
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {data?.stories?.map(story => (
              <Table.Row key={story._id}>
                <Table.Cell width="33%">{story.title}</Table.Cell>
                <Table.Cell>
                  {story.connectedRegions.length} region
                  {story.connectedRegions?.length !== 1 ? "s" : ""} connected
                </Table.Cell>
                <Table.Cell>
                  <Tooltip
                    title={moment(story.createdAt).format("DD-MM-YYYY HH:mm")}
                    placement="top"
                  >
                    <span>{moment(story.createdAt).fromNow()}</span>
                  </Tooltip>
                </Table.Cell>
                <Table.Cell isCentered>
                  <Switch
                    checked={story.isActive}
                    onChange={() =>
                      handeStoryUpdate(story._id, !story.isActive)
                    }
                    disabled={loadingUpdate}
                    value="enabled"
                    color="primary"
                  />
                </Table.Cell>
                <Table.ActionCell
                  to={`stories/${story._id}/edit`}
                  iconName="pencil"
                />
                <Table.ActionCell
                  onClick={() => handleStoryDelete(story._id, story.title)}
                  iconName="trash"
                />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>

      <UpdateConfirmation
        successStatus={state?.updateSuccessful}
        content={"Story updated successfully!"}
      />

      <UpdateConfirmation
        successStatus={state?.createSuccessful}
        content={"Story created successfully!"}
      />
    </Page>
  );
};
