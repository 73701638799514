import React from "react";
import immer from "immer";
import uuid from "uuid";
import emoji from "node-emoji";
import styled from "styled-components";
import { MarkupInput } from "./StepForm";
import { Input } from "../Styled/Input";
import AutoSizeTextarea from "react-textarea-autosize";
import { HelpInfo } from "../../Elements/HelpInfo";

export const questionType = {
  PROFILE: "profile",
  REQUEST: "request",
  PREFERENCES: "preferences",
};

export const getQuestionsOfType = (questions, type) =>
  questions.filter(question => question.type === type);

export const CreateQuestionStep = ({ step, onChange }) => {
  const { questions = [] } = step;

  const onChangeHandler = (id, attribute, value) => {
    const newQuestions = immer(questions, draftQuestions => {
      let updatedQuestion = draftQuestions.find(
        question => question._id === id
      );

      updatedQuestion[attribute] = value;
    });

    onChange("questions", newQuestions);
  };

  const addQuestion = type => {
    const newQuestions = immer(questions, draft => {
      draft.push({
        _id: uuid.v4(),
        type,
        key: "",
        required: false,
        exampleQuestion: "",
      });
    });

    onChange("questions", newQuestions);
  };

  const deleteQuestion = id => {
    const newQuestions = immer(questions, draft => {
      const questionIndex = draft.findIndex(question => question._id === id);

      draft.splice(questionIndex, 1);
    });

    onChange("questions", newQuestions);
  };

  return (
    <section>
      {Object.values(questionType).map(type => (
        <TypeGroup key={type}>
          <h2 style={{ textTransform: "capitalize" }}>{type}</h2>

          {getQuestionsOfType(questions, type).map(question => (
            <QuestionGroup key={question._id}>
              <InputGroup>
                <InputLabel>Key:</InputLabel>
                <Input
                  type="text"
                  placeholder="e.g Full name"
                  value={question.key}
                  onChange={e =>
                    onChangeHandler(question._id, "key", e.target.value)
                  }
                />
              </InputGroup>

              <InputGroup>
                <HelpInfo
                  description="If it's required vb's can't complete the step without 
              answering this question"
                  style={{ color: "#657786", fontSize: 16, marginRight: "5px" }}
                />
                <InputLabel>Required:</InputLabel>
                <input
                  type="checkbox"
                  checked={question.required}
                  onChange={e =>
                    onChangeHandler(question._id, "required", e.target.checked)
                  }
                />
              </InputGroup>

              <InputGroup>
                <InputLabel>Example question:</InputLabel>
                <TextArea
                  value={question.exampleQuestion || ""}
                  onChange={e =>
                    onChangeHandler(
                      question._id,
                      "exampleQuestion",
                      emoji.emojify(e.target.value)
                    )
                  }
                />
                {/* <MarkupInput
                  value={question.exampleQuestion || ""}
                  onChange={newValue =>
                    onChangeHandler(
                      question._id,
                      "exampleQuestion",
                      emoji.emojify(newValue)
                    )
                  }
                /> */}
              </InputGroup>

              <DeleteButton onClick={() => deleteQuestion(question._id)}>
                Delete
              </DeleteButton>
            </QuestionGroup>
          ))}

          <AddQuestion onClick={() => addQuestion(type)}>
            Add {type} question
          </AddQuestion>
        </TypeGroup>
      ))}
    </section>
  );
};

export const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 75px;
  padding: 5px;
  border-radius: 4px;
  margin-left: 12px;
  color: red;

  &:hover {
    background-color: red;
    color: #ffffff;
    cursor: pointer;
  }
`;

const AddQuestion = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 3px 9px;
  margin-right: 5px;
  margin-top: 10px;
  font-size: 0.8125rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  max-width: 217px;
  text-align: center;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
`;

const TypeGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const QuestionGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin: 12px 0;
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const InputLabel = styled.div`
  margin: 0 4px 0 10px;
  color: #657786;
`;

const TextArea = styled(AutoSizeTextarea)`
  min-width: 300px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 12px;
  border: solid 1px #d9e4eb;
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #2a2135;
`;
