import React from "react";
import { CompanyEdit } from "./CompanyEdit.js";
import { Whitespace, Button, MaterialIcon, Card } from "../Elements.js";

export let CompanyCreatePage = ({ company, onSave }) => {
  let [local_company, set_local_company] = React.useState(company);

  return (
    <Card>
      <Card.Content>
        <h2>Create new company</h2>
        <CompanyEdit company={local_company} onChange={set_local_company} />
        <Whitespace height={8} />
        <Button
          style={{ alignSelf: "flex-end" }}
          onClick={() => onSave(local_company)}
        >
          <MaterialIcon name="add" size={18} /> Create
        </Button>
      </Card.Content>
    </Card>
  );
};
