import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components/macro";
import { TOKEN } from "../../constants";
import { CreateTopic } from "../../Pages/CreateTopic";
import EditHandlingPlan from "../../Pages/EditHandlingPlan";
import { EditTopic } from "../../Pages/EditTopic";
import { Topics } from "../../Pages/Topics";
import { QuestionsAndAnswers } from "../../Pages/QuestionsAndAnswers";
import { CreateQuestionAndAnswer } from "../../Pages/CreateQuestionAndAnswer";
import { EditQuestionAndAnswer } from "../../Pages/EditQuestionAndAnswer";

import Welcome from "../Welcome/Welcome";
import { ClientsListPage } from "../Client/ClientsListPage.js";
import { CompaniesPage } from "../Company/CompaniesListPage.js";
import HandlingPlans from "../../Pages/HandlingPlans";
import Tags from "../../Pages/Tags";
import CreateHandlingPlan from "../../Pages/CreateHandlingPlan";
import { SuppliersPage } from "../Supplier/SuppliersListPage.js";

import Button from "@material-ui/core/Button";
import { Switch, Route, NavLink } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import { VerticalList, MaterialIcon, EmptyState, Flex } from "../Elements.js";
import { check_if_local_env } from "../../helpers";

import { Stories } from "../../Pages/Stories";
import { EditStory } from "../../Pages/EditStory";
import { AddStory } from "../../Pages/AddStory";
import PhosphorIcon from "../phosphor-icons/phosphor-icon.js";

let PortalStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: var(--main-background);
  margin-left: 0;
  transition: all 0.4s cubic-bezier(0.07, 0.56, 0.35, 0.96);

  header.header {
    display: flex;
  }
  header.header h1 {
    flex-grow: 1;
    margin-left: var(--basic-margin);
  }
  header.header .button {
    color: white;
    font-weight: bold;
    text-transform: none;
  }
  header.header .loader {
    color: white;
    margin-right: var(--basic-margin);
    width: 20px !important;
    height: 20px !important;
  }
  header.header .icon-button {
    color: white;
    font-size: 1.1rem;
    margin-right: var(--basic-margin);
    margin-left: -9px;
  }
  header.header .icon-button svg {
    width: 1.1rem;
    height: 1.1rem;
  }
`;

let MenuitemStyle = styled(NavLink)`
  color: rgba(0, 0, 0, 0.75);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 8px;

  &:hover {
    background-color: rgb(219, 219, 219);
  }

  &.active,
  &.active:hover {
    background-color: ${props =>
      props.loaclEnv ? "#dd8d3e" : "rgb(66, 193, 117)"};
  }

  .MuiIcon-root {
    /* Material UI normally sets font size to 1.5 *r*em, which discards my set font size :/ */
    font-size: 1.5em;
  }
`;
let Menuitem = ({ icon, text, to, ...props }) => {
  return (
    <MenuitemStyle
      to={to}
      activeClassName="active"
      {...props}
      loaclEnv={check_if_local_env()}
    >
      <div style={{ fontSize: 20 }}>{icon}</div>
      <div style={{ height: 2 }} />
      <div style={{ fontSize: 14 }}>{text}</div>
    </MenuitemStyle>
  );
};

const Token = localStorage.getItem(TOKEN);

const ADMIN_LOGOUT = gql`
  mutation($token: String!) {
    invalidateSession(token: $token) {
      success
    }
  }
`;

class Portal extends Component {
  reloadPage() {
    window.location.reload(true);
  }

  render() {
    return (
      <PortalStyle>
        <Flex column style={{ width: 90, backgroundColor: "white" }}>
          <Menuitem
            style={{
              color: check_if_local_env() ? "#dd8d3e" : "#42c175",
              backgroundColor: "transparent",
              paddingTop: 20,
            }}
            to="/"
            icon={
              <div style={{ width: 30 }}>
                {require("./ubutler-logo.jsx").default}
              </div>
            }
            text={
              <b>
                Admin
                <br />
                portal
              </b>
            }
          />

          <div style={{ height: 12 }} />

          <VerticalList style={{ flex: 1, overflowY: "auto" }}>
            <Menuitem
              to="/clients"
              icon={<MaterialIcon name="people" />}
              text="Clients"
            />
            <Menuitem
              to="/companies"
              icon={<MaterialIcon name="domain" />}
              text="Companies"
            />
            <Menuitem
              to="/todos"
              icon={<PhosphorIcon name="list-checks" />}
              text="To do's"
            />
            <Menuitem
              to="/topics"
              icon={<PhosphorIcon name="info" />}
              text="Topics"
            />
            <Menuitem
              to="/questionsAndAnswers"
              icon={<PhosphorIcon name="question" />}
              text="Q&As"
            />
            <Menuitem
              to="/stories"
              icon={<PhosphorIcon name="play-circle" />}
              text="Stories"
            />
          </VerticalList>

          <Mutation mutation={ADMIN_LOGOUT}>
            {(invalidateSession, { loading }) => (
              <div style={{ marginTop: 24 }}>
                {loading ? (
                  <CircularProgress className="loader" />
                ) : (
                  <Button
                    style={{ width: "100%", textTransform: "initial" }}
                    className="button"
                    onClick={() => {
                      invalidateSession({ variables: { token: Token } });
                      localStorage.removeItem(TOKEN);
                      this.reloadPage();
                    }}
                  >
                    Sign out
                  </Button>
                )}
              </div>
            )}
          </Mutation>
        </Flex>

        <main
          style={{
            flex: 1,
            overflowX: "hidden",
            overflowY: "auto",
            height: "100vh",
          }}
        >
          <React.Suspense
            fallback={<EmptyState icon="accessibility">Loading</EmptyState>}
          >
            <Switch>
              <Route exact path="/" component={Welcome} />
              <Route path="/clients" component={ClientsListPage} />
              <Route path="/companies" component={CompaniesPage} />
              <Route path="/suppliers" component={SuppliersPage} />
              <Route exact path="/todos" component={HandlingPlans} />
              <Route exact path="/tags" component={Tags} />
              <Route path="/todos/create" component={CreateHandlingPlan} />
              <Route path="/todos/:id" component={EditHandlingPlan} />
              <Route exact path="/topics" component={Topics} />
              <Route exact path="/topics/create" component={CreateTopic} />
              <Route exact path="/topics/:id/edit" component={EditTopic} />
              <Route
                exact
                path="/questionsAndAnswers"
                component={QuestionsAndAnswers}
              />
              <Route
                exact
                path="/questionsAndAnswers/create"
                component={CreateQuestionAndAnswer}
              />
              <Route
                exact
                path="/questionsAndAnswers/:id/edit"
                component={EditQuestionAndAnswer}
              />
              <Route exact path="/stories" component={Stories} />
              <Route exact path="/stories/create" component={AddStory} />
              <Route exact path="/stories/:id/edit" component={EditStory} />
            </Switch>
          </React.Suspense>
        </main>
      </PortalStyle>
    );
  }
}

export default Portal;
