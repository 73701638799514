import React, { useState } from "react";
import gql from "graphql-tag";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "../../Graphql";
import { getToken } from "../../constants";
import { StepsList } from "./StepsList";
import omitDeep from "omit-deep-lodash";
import { pickBy } from "lodash";
import { TodoStepsFragment } from "../../Graphql/fragments";
import { UpdateConfirmation } from "../../Elements/HelpInfo";
import CircularProgress from "@material-ui/core/CircularProgress";

const GET_STEPS = gql`
  query($token: String!, $_id: String!) {
    adminTodo(token: $token, _id: $_id) {
      _id
      ...TodoStepsFragment
    }
  }
  ${TodoStepsFragment}
`;

const UPDATE_STEPS = gql`
  mutation updateSteps(
    $token: String!
    $_id: String!
    $payload: [InputStep!]!
  ) {
    updateSteps(token: $token, _id: $_id, payload: $payload) {
      ...TodoStepsFragment
    }
  }
  ${TodoStepsFragment}
`;

export const Steps = ({ match }) => {
  const { id: _id } = match.params;

  const { data: stepsData, loading } = useQuery(GET_STEPS, {
    variables: {
      token: getToken(),
      _id,
    },
    suspend: true,
  });

  const [updateSteps] = useMutation(UPDATE_STEPS);
  const [updateSuccess, set_updateSuccess] = useState(false);

  if (loading) return <CircularProgress className="loader" />;

  let todo = stepsData?.adminTodo?.handling;
  if (!todo) return <p>Todo not found</p>;

  const { steps = [] } = todo;

  return (
    <>
      <StepsList
        steps={steps}
        onStepsChange={async steps => {
          let canUpdateSteps = true;
          const nextSteps = steps.map((step, index) => {
            if (step.title.trim() === "") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text:
                  "One of the steps does not have a title, your changes have not been updated.",
              });
              canUpdateSteps = false;
            }
            // Clear the timeFrame if the step doesn't need an ETA
            const adjustedStep = step.needETA
              ? { ...step }
              : {
                  ...step,
                  timeFrame: {
                    minutes: 0,
                    hours: 0,
                    days: 0,
                  },
                };

            const sum_timeFrame =
              step.timeFrame &&
              Number(step.timeFrame.days) +
                Number(step.timeFrame.hours) +
                Number(step.timeFrame.minutes);

            if (step.needETA && (!step.timeFrame || sum_timeFrame <= 0)) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text:
                  "One of the steps is marked as 'need an ETA' but the time frame is empty, your changes have not been updated.",
              });
              canUpdateSteps = false;
            }
            const previousStepId = index !== 0 ? steps[index - 1]._id : null;
            // return values that are not equal to null, are not empty strings/arrays, Booleans and the duration object.
            return pickBy({ ...adjustedStep, previousStepId }, (value, key) => {
              if (value === null) return false;
              if (value === "<p><br></p>") return false;
              if (key === "maxDuration") return true;
              if (key === "timeFrame") return true;
              if (typeof value === "boolean") return true;
              if (value.length >= 1) return true;
              return false;
            });
          });
          if (canUpdateSteps) {
            await updateSteps({
              variables: {
                token: getToken(),
                _id,
                // will omit typenames in deep nested pairs
                payload: omitDeep(nextSteps, "__typename"),
              },
            });
            set_updateSuccess(true);
            setTimeout(() => {
              set_updateSuccess(false);
            }, 1000);
          }
        }}
      />
      {updateSuccess && (
        <UpdateConfirmation
          successStatus={updateSuccess}
          content={"Steps updated successfully!"}
        />
      )}
    </>
  );
};
