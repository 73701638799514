import React from "react";
import FormInfo from "../../Styled/FormInfo";
import FormRow from "../../Styled/FormRow";
import { ContentForm } from "../../ContentForm";
import { SubmitForm } from "../HandlingPlanForm";

export const Content = props => {
  return (
    <form>
      <FormRow>
        <FormInfo>
          <h3>Content</h3>
          <p>
            This is the content for this to do. It is shown in the knowledge
            base.
          </p>
        </FormInfo>

        <ContentForm content={props.content} onChange={props.onContentChange} />
      </FormRow>
      <SubmitForm handleSubmit={props.handleSubmit} />
    </form>
  );
};
