import React from "react";
import MuiTableRow from "@material-ui/core/TableRow";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import SortableTableRowProvider from "./SortableTableRowProvider";

export const SortableTableRow = ({
  children,
  draggableId,
  index,
  highlightColor,
}) => {
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <SortableTableRowProvider value={{ provided }}>
          <TableRow
            innerRef={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
            highlightColor={highlightColor}
          >
            {children}
          </TableRow>
        </SortableTableRowProvider>
      )}
    </Draggable>
  );
};

const TableRow = styled(({ isDragging, highlightColor, ...props }) => (
  <MuiTableRow {...props} />
))`
  transition: box-shadow 0.3s ease;

  td {
    transition: background-color 0.3s ease;
  }

  ${({ isDragging, highlightColor }) =>
    isDragging &&
    `
      box-shadow: 0 2px 5px rgb(0 0 0 / 12%);
      td {
        background-color: ${highlightColor};
      }
    `};
`;
