import React, { useState } from "react";
import gql from "graphql-tag";
import immer from "immer";
import { omit } from "lodash";
import { useMutation, useQuery, updateQuery } from "../../Graphql.js";
import { ClientLayout } from "./ClientLayout.js";
import { ClientFragment, CompanyFragment } from "../../Graphql/fragments";
import { GraphqlErrorWell } from "../Elements.js";
import { getToken, flatten_ids } from "../../Graphql.js";
import { get_company_todos } from "../Company/CompanyRoadmap";
import { UpdateConfirmation } from "../../Elements/HelpInfo";
import Swal from "sweetalert2";

export const ADMIN_CLIENT_QUERY = gql`
  query($clientId: ID!, $token: String!) {
    adminClient(token: $token, clientId: $clientId) {
      ...ClientFragment
    }
  }
  ${ClientFragment}
`;

export const COMPANIES_QUERY = gql`
  query($token: String!) {
    companies(token: $token) {
      _id
      clients {
        _id
      }
    }
  }
  ${CompanyFragment}
`;

export const TODOS_QUERY = gql`
  query($token: String!) {
    handlingPlans(token: $token) {
      _id
      title
      region
    }
  }
`;

export const UPDATE_CLIENT_MUTATION = gql`
  mutation($token: String!, $id: ID!, $item: InputClient!) {
    updateClient(id: $id, token: $token, item: $item) {
      ...ClientFragment
    }
  }
  ${ClientFragment}
`;

export const ClientEditPage = ({ client_id, ...props }) => {
  const [updateSuccess, set_updateSuccess] = useState(false);

  const flatten_input = batches => {
    return flatten_ids(batches, ({ __typename, _id }, key) => {
      const typenameSet = new Set([
        "TimelineItem",
        "HandlingPlan",
        "Todo",
        "FeatureFlags",
      ]);

      if (typenameSet.has(__typename)) {
        return { [`${key}_id`]: _id };
      }
    });
  };

  const {
    data: { adminClient },
  } = useQuery(ADMIN_CLIENT_QUERY, {
    variables: {
      token: getToken(),
      clientId: client_id,
    },
    suspend: true,
  });

  const { data: todosData } = useQuery(TODOS_QUERY, {
    variables: {
      token: getToken(),
    },
    suspend: true,
  });

  const [updateClient, { error: updateClientError }] = useMutation(
    UPDATE_CLIENT_MUTATION
  );

  const companyTodos =
    adminClient?.company && get_company_todos(adminClient.company.topics);
  const onClientChange = async client => {
    if (!Boolean(client.firstName)) {
      return Swal.fire({
        icon: "error",
        text: "You must fill in the first name of the GE",
        backdrop: false,
      });
    }

    if (!Boolean(client.lastName)) {
      return Swal.fire({
        icon: "error",
        text: "You must fill in the last name of the GE",
        backdrop: false,
      });
    }

    if (!Boolean(client.email)) {
      return Swal.fire({
        icon: "error",
        text: "You must fill in the email of the GE",
        backdrop: false,
      });
    }

    if (!Boolean(client.company)) {
      return Swal.fire({
        icon: "error",
        text: "You must fill in the company of the GE",
        backdrop: false,
      });
    }

    if (!Boolean(client.relocatingTo)) {
      return Swal.fire({
        icon: "error",
        text: "You must select the GE relocation destination",
        backdrop: false,
      });
    }

    if (!Boolean(client.currency)) {
      return Swal.fire({
        icon: "error",
        text: "You must select the preferred GE currency",
        backdrop: false,
      });
    }

    if (!Boolean(client.cluster)) {
      return Swal.fire({
        icon: "error",
        text: "You must fill in the cluster/RC name of the GE",
        backdrop: false,
      });
    }
    
    const updatedClient = immer(client, draft => {
      draft.batches = flatten_input(client.batches);

      const clientFeatures = omit(client.features, "chat");
      draft.features = flatten_input(clientFeatures);

      draft.companyId = draft.company?._id;
      delete draft.allowances;
      delete draft.company;
      delete draft.credit;
      delete draft.__typename;
    });

    for (const task of client.tasks) {
      delete task.__typename;
    }

    await updateClient({
      variables: {
        item: updatedClient,
        id: client._id,
        token: getToken(),
      },
      refetchQueries: [
        {
          query: ADMIN_CLIENT_QUERY,
          variables: {
            token: getToken(),
            clientId: client_id,
          },
        },
      ],
      awaitRefetchQueries: true,
      update: (cache, { data: { updateClient } }) => {
        if (updateClient.company?._id !== client.company?._id) {
          updateQuery(
            cache,
            {
              query: COMPANIES_QUERY,
              variables: { token: getToken() },
            },
            immer(data => {
              for (let company of data.companies) {
                // Remove client from company list if client was assigned to another company
                company.clients = company.clients.filter(
                  client => client._id !== updateClient._id
                );

                // Push client to new company
                if (company._id === updateClient.company?._id) {
                  company.clients = [...company.clients, updateClient];
                }
              }
            })
          );
        }
      },
    });
    set_updateSuccess(true);
    setTimeout(() => {
      set_updateSuccess(false);
    }, 1000);
  };

  return (
    <>
      {updateClientError && <GraphqlErrorWell error={updateClientError} />}
      {updateSuccess && (
        <UpdateConfirmation
          successStatus={updateSuccess}
          content={"Client updated successfully!"}
        />
      )}

      <ClientLayout
        {...props}
        client={adminClient}
        todoSuggestions={todosData.handlingPlans}
        companyTodos={companyTodos}
        onBatchesChange={async batches =>
          onClientChange({ ...adminClient, batches })
        }
        onClientChange={onClientChange}
      />
    </>
  );
};
