import React from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { getToken } from "../../constants";
import { BatchFragment } from "../../Graphql/fragments";
import { flatten_ids } from "../../Graphql.js";
import { TodoView } from "../TodoView";

export const ROADMAP_TYPE = {
  COMPANY: "company",
  PERSONAL: "personal",
};

export const TODOS_QUERY = gql`
  query($token: String!) {
    handlingPlans(token: $token) {
      _id
      title
      region
    }
  }
`;

export const SET_COMPANY_ROADMAP = gql`
  mutation setCompanyRoadmap(
    $token: String!
    $companyId: ID!
    $batches: [InputBatches!]!
  ) {
    admin_set_company_roadmap(
      token: $token
      companyId: $companyId
      batches: $batches
    ) {
      _id
      batches {
        ...BatchFragment
      }
    }
  }
  ${BatchFragment}
`;

export const get_company_todos = topics => {
  let companyTodos = [];
  for (const topic of topics) {
    for (const todo of topic.todos) {
      companyTodos.push(todo.todo);
    }
  }
  return companyTodos;
};

export const CompanyRoadmap = ({ company }) => {
  let { _id: company_id } = company;

  const [setCompanyRoadmap] = useMutation(SET_COMPANY_ROADMAP, {
    variables: {
      token: getToken(),
    },
  });

  const { data: suggestions } = useQuery(TODOS_QUERY, {
    variables: {
      token: getToken(),
    },
  });

  const flatten_batches = batches => {
    return flatten_ids(batches, ({ __typename, _id }, key) => {
      if (__typename === "TimelineItem") {
        return { [`${key}_id`]: _id };
      }
      if (__typename === "HandlingPlan" || __typename === "Todo") {
        return { [`${key}_id`]: _id };
      }
    });
  };

  return suggestions?.handlingPlans ? (
    <TodoView
      key={company_id}
      roadmapType={ROADMAP_TYPE.COMPANY}
      batches={company.batches}
      onBatchesChange={batches => {
        setCompanyRoadmap({
          variables: {
            token: getToken(),
            companyId: company_id,
            batches: flatten_batches(batches),
          },
          optimisticResponse: {
            admin_set_company_roadmap: {
              __typename: "Company",
              _id: company_id,
              batches: batches,
            },
          },
        });
      }}
      todoSuggestions={suggestions.handlingPlans}
      companyTodos={get_company_todos(company.topics)}
    />
  ) : (
    <div />
  );
};
