import React from "react";
import MuiTableHead from "@material-ui/core/TableHead";
import styled from "styled-components";

export const TableHead = ({ children }) => {
  return <StyledTableHead>{children}</StyledTableHead>;
};

const StyledTableHead = styled(MuiTableHead)`
  .MuiTableCell-head {
    font-weight: 600;
  }
`;
