import React, { useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { applySearch } from "../../helpers.js";
import { flatten_ids, getToken } from "../../Graphql.js";
import { ClientEditPage } from "./ClientEditPage.js";
import { ClientCreatePage } from "./ClientCreatePage";
import { SimpleClientFragment } from "../../Graphql/fragments";
import { gql, useMutation, useQuery } from "../../Graphql.js";
import { Sidebar, SidebarLink } from "../PageElements.js";
import { check_if_local_env } from "../../helpers";

import {
  Whitespace,
  VerticalList,
  TextInput,
  StyledNavLink,
  Card,
  Flex,
  EmptyState,
} from "../Elements.js";
import PhosphorIcon from "../phosphor-icons/phosphor-icon.js";

const ADD_CLIENT = gql`
  mutation($token: String!, $item: InputClient!) {
    createClient(token: $token, item: $item) {
      _id
    }
  }
`;

export const ADMIN_CLIENTS_QUERY = gql`
  query($token: String!) {
    adminClients(token: $token) {
      ...SimpleClientFragment
    }
  }
  ${SimpleClientFragment}
`;

export let ClientsListPage = () => {
  let match = useRouteMatch();
  const [searchQuery, set_searchQuery] = useState("");

  const [addClient] = useMutation(ADD_CLIENT, {
    variables: {
      token: getToken(),
    },
    refetchQueries: [
      { query: ADMIN_CLIENTS_QUERY, variables: { token: getToken() } },
    ],
    awaitRefetchQueries: true,
  });

  let { data } = useQuery(ADMIN_CLIENTS_QUERY, {
    variables: {
      token: getToken(),
    },
    suspend: true,
  });

  return (
    <Flex row style={{ height: "100%" }}>
      <Sidebar title="Clients">
        <Card.Content>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <TextInput
              placeholder="Search clients"
              onChange={e => set_searchQuery(e.target.value)}
              value={searchQuery}
            />
            <Whitespace width={8} />
            <StyledNavLink style={{ padding: 8 }} to={`${match.url}/create`}>
              <PhosphorIcon name="plus" className="icon" />
            </StyledNavLink>
          </div>
        </Card.Content>
        <Whitespace height={8} />
        <VerticalList>
          {data.adminClients
            .filter(client =>
              applySearch({
                search: searchQuery,
                values: [
                  client.firstName,
                  client.lastName,
                  client.nickname,
                  client.phone,
                  client.email,
                  client.company?.name,
                ],
              })
            )
            .map(item => (
              <SidebarLink
                to={`${match.url}/${item._id}`}
                key={item._id}
                loaclEnv={check_if_local_env()}
              >
                <SidebarLink.Title>
                  {item.firstName} {item.lastName}
                </SidebarLink.Title>
                <SidebarLink.Text>
                  {item.company ? (
                    <span>{item.company.name}</span>
                  ) : (
                    <span style={{ color: "gray" }}>No company</span>
                  )}
                </SidebarLink.Text>
              </SidebarLink>
            ))}
        </VerticalList>
      </Sidebar>

      <React.Suspense
        fallback={<EmptyState icon="cached">Loading client</EmptyState>}
      >
        <Flex column style={{ flex: 1, overflow: "auto" }}>
          <Switch>
            <Route
              exact
              path={`${match.path}`}
              render={() => (
                <EmptyState icon="people">Select a client</EmptyState>
              )}
            />
            <Route
              path={`${match.path}/create`}
              render={({ history }) => (
                <ClientCreatePage
                  addClient={async client => {
                    const client_input = flatten_ids(
                      client,
                      ({ __typename, _id }, key) => {
                        if (__typename === "Company") {
                          return { [`${key}Id`]: _id };
                        }
                      },
                    );

                    const result = await addClient({
                      variables: { token: getToken(), item: client_input },
                    });
                    const new_client_id = result.data.createClient._id;
                    history.push(`${match.url}/${new_client_id}`);
                  }}
                />
              )}
            />

            <Route
              path={`${match.url}/:client_id`}
              render={({
                match: {
                  params: { client_id },
                },
              }) => <ClientEditPage key={client_id} client_id={client_id} />}
            />
          </Switch>
        </Flex>
      </React.Suspense>
    </Flex>
  );
};
