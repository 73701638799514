import React from "react";
import MuiTableCell from "@material-ui/core/TableCell";
import styled from "styled-components";
import useSortableRow from "./useSortableRow";
import PhosphorIcon from "../phosphor-icons/phosphor-icon";

export const SortHandleTableCell = ({ width, backgroundColor = "white" }) => {
  const { provided } = useSortableRow();

  return (
    <StyledTableCell
      {...provided.dragHandleProps}
      backgroundColor={backgroundColor}
      width={width}
    >
      <PhosphorIcon name="list" />
    </StyledTableCell>
  );
};
const StyledTableCell = styled(({ backgroundColor, ...props }) => (
  <MuiTableCell {...props} />
))`
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ width }) => width && `width: ${width};`};
  width: 30px;
`;
