import React, { useEffect, useMemo, useState } from "react";
import { FormGroup, MarkupInput } from "../Steps/StepForm";
import { gql, useMutation } from "../../Graphql";
import { useParams } from "react-router-dom";
import { getToken } from "../../constants";
import { Button, Switch } from "@material-ui/core";
import uuid from "uuid";
import { Select } from "../Elements";
import { ChecklistTasks } from "./ChecklistTasks";
import emoji from "node-emoji";
import styled from "styled-components";
import { Table } from "../ComposableTable/Table";
import { ClampedText } from "../ClampedText";
import {
  EditingBox,
  EditingBoxFormGroupRow,
  EditingBoxTitle,
  EditingError,
  EditingFieldTitle,
  EditingInput,
  EditingRequired,
} from "./EditingBox";
import { EditingActions } from "./EditingActions";
import { EditingFormGroupSubtitle } from "./EditingFormGroupSubtitle";

const UPDATE_CHECKLIST_ITEMS = gql`
  mutation updateTodoChecklistItems(
    $token: String!
    $todoId: String!
    $checklistItems: [InputChecklistItem!]!
  ) {
    updateTodoChecklistItems(
      token: $token
      todoId: $todoId
      checklistItems: $checklistItems
    ) {
      _id
      handling {
        checklistItems {
          _id
          description
          phaseId
          route
          title
          isInactive
          geTitle
          geDescription
          tasks {
            _id
            title
            description
            isInactive
          }
        }
      }
      title
    }
  }
`;

export const Checklist = ({
  checklistItems,
  isChecklistPhase,
  phaseId,
  routes,
  onEditingUpdate,
}) => {
  const [updateTodoChecklistItems] = useMutation(UPDATE_CHECKLIST_ITEMS);
  const { id: todoId } = useParams();
  const [error, setError] = useState();
  const [newChecklistItem, setNewChecklistItem] = useState();
  const [isEditingTask, setIsEditingTask] = useState(false);
  const [hasUnsavedTasks, setHasUnsavedTasks] = useState(false);
  const [localChecklistItems, setLocalChecklistItems] = useState(
    checklistItems
  );

  useEffect(() => {
    return () => {
      onEditingUpdate(false);
    };
  }, []);

  useEffect(() => {
    const checkUnsavedTasks = e => {
      // Show generic confirmation window whenever the user has updated, but not saved their tasks
      if (hasUnsavedTasks) {
        e.preventDefault();
        e.returnValue = "";
      }
      return;
    };

    window.addEventListener("beforeunload", checkUnsavedTasks);
    return () => {
      window.removeEventListener("beforeunload", checkUnsavedTasks);
    };
  }, [hasUnsavedTasks]);

  const createNewChecklistItem = () => {
    onEditingUpdate(true);
    setNewChecklistItem({
      _id: uuid.v4(),
      description: "",
      title: "",
      tasks: [],
      isInactive: true,
      geTitle: "",
      geDescription: "",
    });
  };

  const handleEditChecklistItem = async checklistItem => {
    onEditingUpdate(true);
    setNewChecklistItem(checklistItem);
  };

  const handleDeleteChecklistItem = async id => {
    const result = window.confirm("Are you sure?");

    if (!result) {
      return;
    }

    const updatedChecklistItems = localChecklistItems.filter(
      ({ _id }) => _id !== id
    );

    await updateChecklistItems(updatedChecklistItems);
  };

  const handleSaveNewChecklistItem = async () => {
    let errors = {};

    if (
      typeof newChecklistItem.title !== "string" ||
      newChecklistItem.title.replace(/\s*/, "") === ""
    ) {
      errors["title"] = "Title cannot be empty";
    }

    if (isChecklistPhase) {
      if (
        typeof newChecklistItem.route !== "string" ||
        newChecklistItem.route.replace(/\s*/, "") === ""
      ) {
        errors["route"] = "Route cannot be empty";
      }
    } else {
      newChecklistItem.phaseId = phaseId;
    }

    if (Object.keys(errors).length > 0) {
      return setError(errors);
    }

    const updatedChecklistItems = Array.isArray(checklistItems)
      ? [...checklistItems]
      : [];
    const idx = updatedChecklistItems.findIndex(
      checklistItem => checklistItem._id === newChecklistItem._id
    );
    if (idx > -1) {
      updatedChecklistItems[idx] = newChecklistItem;
    } else {
      updatedChecklistItems.push(newChecklistItem);
    }

    await updateChecklistItems(updatedChecklistItems);

    onEditingUpdate(false);
    setError(null);
    setNewChecklistItem(null);
    setHasUnsavedTasks(false);
  };

  const handleSortItems = async result => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const currentChecklistItems = Array.isArray(checklistItemsForPhase)
      ? [...checklistItemsForPhase]
      : [];
    const [removed] = currentChecklistItems.splice(result.source.index, 1);
    currentChecklistItems.splice(result.destination.index, 0, removed);

    const currentChecklistItemIds = currentChecklistItems.map(item => item._id);
    const allChecklistItemsExceptCurrent = checklistItems.filter(
      item => !currentChecklistItemIds.includes(item._id)
    );
    const updatedChecklistItems = [
      ...allChecklistItemsExceptCurrent,
      ...currentChecklistItems,
    ];

    await updateChecklistItems(updatedChecklistItems);
  };

  const updateChecklistItems = async updatedChecklistItems => {
    setLocalChecklistItems(updatedChecklistItems);

    // strip any fields that shouldn't be a part of the mutation, like __fieldName
    const checklistItems = updatedChecklistItems.map(
      ({
        _id,
        description,
        phaseId,
        route,
        title,
        isInactive,
        geTitle,
        geDescription,
        tasks,
      }) => ({
        _id,
        description,
        phaseId,
        route,
        title,
        isInactive,
        geTitle: geTitle !== "" ? geTitle : null,
        geDescription: geDescription !== "" ? geDescription : null,
        tasks: tasks.map(({ _id, title, description, isInactive }) => ({
          _id,
          description,
          title,
          isInactive,
        })),
      })
    );
    await updateTodoChecklistItems({
      variables: {
        checklistItems,
        todoId,
        token: getToken(),
      },
    });
  };

  const checklistItemsForPhase = useMemo(() => {
    if (isChecklistPhase) {
      return localChecklistItems.filter(item => Boolean(item.route));
    }

    return localChecklistItems.filter(item => item.phaseId === phaseId);
  }, [localChecklistItems, isChecklistPhase, phaseId]);

  return (
    <div>
      {checklistItemsForPhase.length > 0 && !newChecklistItem ? (
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell />
              <Table.Cell>Title</Table.Cell>
              {isChecklistPhase && <Table.Cell>Route</Table.Cell>}
              <Table.Cell>Connected tasks</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          </Table.Head>

          <Table.SortableBody onSort={handleSortItems}>
            {checklistItemsForPhase.map((checklistItem, index) => (
              <Table.SortableRow
                key={checklistItem._id}
                draggableId={checklistItem._id}
                index={index}
                highlightColor="#fafafa"
              >
                <Table.SortHandleCell width="30px" id={checklistItem._id} />
                <Table.Cell width="3000px">{checklistItem.title}</Table.Cell>
                {isChecklistPhase && (
                  <Table.Cell width="3000px">
                    <ClampedText linesToClamp={1}>
                      {
                        routes.find(route => route._id === checklistItem.route)
                          ?.title
                      }
                    </ClampedText>
                  </Table.Cell>
                )}
                <Table.Cell width="3000px">
                  {`${checklistItem.tasks?.length ?? 0} connected task${
                    checklistItem.tasks?.length === 1 ? "" : "s"
                  }`}
                </Table.Cell>
                <Table.Cell>
                  <Switch
                    onChange={e => {
                      const index = localChecklistItems.findIndex(
                        item => item._id === checklistItem._id
                      );
                      const updatedItems = [...localChecklistItems];
                      updatedItems[index] = {
                        ...updatedItems[index],
                        isInactive: !e.target.checked,
                      };
                      updateChecklistItems(updatedItems);
                    }}
                    value={!checklistItem.isInactive}
                    checked={!checklistItem.isInactive}
                    color="primary"
                  />
                </Table.Cell>
                <Table.ActionCell
                  onClick={() => {
                    handleEditChecklistItem(checklistItem);
                  }}
                  iconName="pencil"
                />
                <Table.ActionCell
                  onClick={() => {
                    handleDeleteChecklistItem(checklistItem._id);
                  }}
                  iconName="trash"
                />
              </Table.SortableRow>
            ))}
          </Table.SortableBody>
        </Table>
      ) : null}

      <div>
        {newChecklistItem ? (
          <EditingBox>
            <EditingBoxTitle>Editing checklist item</EditingBoxTitle>

            <EditingBoxFormGroupRow>
              <FormGroup noPadding>
                <EditingFieldTitle>
                  Title - Visible in portal{" "}
                  <EditingRequired>* required</EditingRequired>
                </EditingFieldTitle>

                <EditingInput
                  onChange={e =>
                    setNewChecklistItem({
                      ...newChecklistItem,
                      title: e.target.value,
                    })
                  }
                  placeholder="Title"
                  type="text"
                  value={newChecklistItem.title}
                />

                {isChecklistPhase && <EditingFormGroupSubtitle />}

                {error?.title ? (
                  <EditingError>{error.title}</EditingError>
                ) : null}
              </FormGroup>
              {isChecklistPhase && (
                <FormGroup noPadding>
                  <EditingFieldTitle>Title - GEs</EditingFieldTitle>

                  <EditingInput
                    onChange={e =>
                      setNewChecklistItem({
                        ...newChecklistItem,
                        geTitle: e.target.value,
                      })
                    }
                    placeholder="Title"
                    type="text"
                    value={newChecklistItem.geTitle ?? ""}
                  />
                  <EditingFormGroupSubtitle isGEField />
                </FormGroup>
              )}
            </EditingBoxFormGroupRow>

            <EditingBoxFormGroupRow>
              <FormGroup noPadding>
                <EditingFieldTitle>
                  Description - Visible in portal
                </EditingFieldTitle>

                <MarkupInput
                  value={newChecklistItem.description}
                  onChange={newValue =>
                    setNewChecklistItem({
                      ...newChecklistItem,
                      description: emoji.emojify(newValue),
                    })
                  }
                  placeholder="Description"
                />

                {isChecklistPhase && <EditingFormGroupSubtitle />}
              </FormGroup>
              {isChecklistPhase && (
                <FormGroup noPadding>
                  <EditingFieldTitle>Description - GEs</EditingFieldTitle>

                  <MarkupInput
                    value={newChecklistItem.geDescription}
                    onChange={newValue =>
                      setNewChecklistItem({
                        ...newChecklistItem,
                        geDescription: emoji.emojify(newValue),
                      })
                    }
                    placeholder="Description"
                  />
                  <EditingFormGroupSubtitle isGEField />
                </FormGroup>
              )}
            </EditingBoxFormGroupRow>

            {isChecklistPhase ? (
              <FormGroup noPadding>
                <EditingFieldTitle>
                  Route <EditingRequired>* required</EditingRequired>
                </EditingFieldTitle>

                <StyledSelect
                  items={routes.map(route => ({
                    title: route.title,
                    value: route._id,
                  }))}
                  onChange={e =>
                    setNewChecklistItem({
                      ...newChecklistItem,
                      route: e.target.value,
                    })
                  }
                  value={newChecklistItem.route}
                  variant="outlined"
                />

                {error?.route ? (
                  <EditingError>{error.route}</EditingError>
                ) : null}
              </FormGroup>
            ) : null}

            <FormGroup noPadding>
              <ChecklistTasks
                checklistTasks={newChecklistItem.tasks ?? []}
                onTasksUpdate={tasks => {
                  setNewChecklistItem({
                    ...newChecklistItem,
                    tasks,
                  });
                  setHasUnsavedTasks(true);
                }}
                onEditingUpdate={setIsEditingTask}
              />
            </FormGroup>

            <EditingActions
              onCancel={() => {
                onEditingUpdate(false);
                setNewChecklistItem(null);
              }}
              onConfirm={handleSaveNewChecklistItem}
              confirmLabel="Save checklist item"
              isDisabled={isEditingTask}
            />
          </EditingBox>
        ) : (
          <Button
            size="small"
            variant="outlined"
            onClick={createNewChecklistItem}
            style={{ margin: "10px 10px 0 0" }}
          >
            Add item
          </Button>
        )}
      </div>
    </div>
  );
};

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    padding: 12px;
    background-color: white;
  }

  fieldset {
    border: 1px solid #d9e4eb;
  }
`;
