import React, { Component } from "react";

import "./welcome.css";
import PhosphorIcon from "../phosphor-icons/phosphor-icon";

class Welcome extends Component {
  render() {
    return (
      <div className="zero-state">
        <p className="icon-container">
          <PhosphorIcon name="rocket-launch" className="icon" />
        </p>
        <h2>Welcome!</h2>
        <p>To the new admin portal</p>
      </div>
    );
  }
}
export default Welcome;
