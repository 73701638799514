import React from "react";
import dompurify from "dompurify";
import styled from "styled-components";
import FormInfo from "../../Styled/FormInfo";
import FormRow from "../../Styled/FormRow";
import { OldStepsForm } from "../OldStepsForm";

export const OldHandling = ({ handlingPlan }) => {
  const { steps, proposalExample, considerations } = handlingPlan;

  return (
    <form>
      <FormRow>
        <FormInfo>
          <h3>Handling plan steps</h3>
          <p>The virtual butler follows these steps to complete the request.</p>
        </FormInfo>
        {steps.length > 0 ? (
          <div className="inputs" style={{ pointerEvents: "none" }}>
            <OldStepsForm steps={steps} />
          </div>
        ) : (
          <div>This handling plan has no steps</div>
        )}
      </FormRow>

      {proposalExample && (
        <FormRow>
          <FormInfo>
            <h3>Preview proposal</h3>
            <p>
              This can be used to provide a template and example for proposals.
            </p>
          </FormInfo>
          <TextInfo
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(proposalExample),
            }}
          />
        </FormRow>
      )}

      {considerations && (
        <FormRow>
          <FormInfo>
            <h3>Take into account</h3>
            <p>
              Things the virtual butler should keep in the back of their mind
              while handling the request.
            </p>
          </FormInfo>
          <TextInfo
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(considerations),
            }}
          />
        </FormRow>
      )}
    </form>
  );
};

export const TextInfo = styled.div`
  width: 100%;
  border-radius: 4px 4px 0 0;
  background: rgba(0, 0, 0, 0.09);
  padding: 10px;

  li {
    margin-left: 1em;
  }
`;
