import React from "react";
import { uniq } from "lodash";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { TOPICS_LIST_QUERY } from "../../Pages/Topics";
import { Autocomplete } from "../Autocomplete";
import { IconSelector } from "../Icon/IconSelector";
import FormInfo from "../Styled/FormInfo";
import FormRow from "../Styled/FormRow";
import { ContentForm } from "../ContentForm";
import { QuestionAndAnswerForm } from "../QuestionAndAnswerForm.js";
import { Whitespace } from "../Elements.js";
import { useQuery, flatten_ids, getToken } from "../../Graphql.js";

export let TopicForm = ({
  topic: initialTopic,
  onSubmit,
  buttonText = "Save",
}) => {
  let [topic, set_topic] = React.useState({
    title: "",
    icon: "",
    category: "",
    weight: 0,
    content: [],
    questionsAndAnswers: [],
    ...initialTopic,
  });

  let updateTopic = (key, value) => {
    set_topic({
      ...topic,
      [key]: value,
    });
  };

  let handleSubmit = event => {
    event.preventDefault();
    onSubmit(
      flatten_ids(topic, (value, key) => {
        if (key === "questionsAndAnswers") {
          return { questionAndAnswerIds: value.map(x => x._id) };
        }
      })
    );
  };

  let {
    data: { topics: topics_for_categories },
  } = useQuery(TOPICS_LIST_QUERY, {
    variables: { token: getToken() },
    suspend: true,
  });

  let categories = uniq(topics_for_categories.map(x => x.category));

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <FormInfo>
          <h3>General</h3>
          <p>
            This information is used in the overview and at the top of the
            individual topic page.
          </p>
        </FormInfo>

        <div className="inputs">
          <TextField
            className="input input__text"
            label="Title"
            required
            fullWidth={true}
            autoFocus={true}
            variant="filled"
            value={topic.title}
            onChange={e => updateTopic("title", e.target.value)}
          />

          <IconSelector
            value={topic.icon}
            onChange={e => updateTopic("icon", e.target.value)}
            required={true}
            style={{ marginBottom: 30 }}
          />

          <Autocomplete
            required
            allowCustomValue
            label="Category"
            options={categories}
            value={topic.category}
            onChange={e => updateTopic("category", e)}
            style={{ marginBottom: 30 }}
          />

          <TextField
            className="input input__text"
            label="Position"
            required
            fullWidth={true}
            variant="filled"
            value={Number(topic.weight) + 1}
            onChange={e => updateTopic("weight", e.target.value - 1)}
            type="number"
          />
        </div>
      </FormRow>

      <FormRow>
        <FormInfo>
          <h3>Content</h3>
          <p>
            This is the content for this topic. It is shown in the knowledge
            base.
          </p>
        </FormInfo>

        <ContentForm
          content={topic.content}
          onChange={value => updateTopic("content", value)}
        />
      </FormRow>

      <FormRow style={{ display: "flex", flexDirection: "column" }}>
        <h3>Q&A's</h3>
        <Whitespace height={8} />
        <QuestionAndAnswerForm
          value={topic.questionsAndAnswers}
          onChange={questionsAndAnswers => {
            updateTopic("questionsAndAnswers", questionsAndAnswers);
          }}
        />
      </FormRow>

      <FormRow
        style={{
          position: `sticky`,
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Button type="submit" variant="contained" color="primary">
          {buttonText}
        </Button>
      </FormRow>
    </form>
  );
};
