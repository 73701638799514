import styled from "styled-components/macro";

const FormRow = styled.div`
  display: flex;
  margin-top: 40px;
  padding: 25px;
  background: white;

  &:first-child {
    margin-top: 0;
  }

  .inputs {
    flex: 1;

    .input {
      margin-bottom: 30px;

      &.input__switch {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h4 {
    color: hsl(164, 6%, 40%);
    font-size: 18px;
  }
`;

export default FormRow;
