import React, { useState } from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";

export const FileUpload = ({
  name,
  createLabel = "Upload new file",
  updateLabel = "Update current file",
  onChange = () => {},
  previousFileKey,
}) => {
  const [containsFile, setContainsFile] = useState(Boolean(previousFileKey));

  const handleFileChange = event => {
    if (!event.target.files[0]) return;

    setContainsFile(true);
    onChange(event.target.files[0]);
  };

  return (
    <>
      <Button variant="outlined" component="label" htmlFor={name}>
        <HiddenInput
          accept="image/*"
          type="file"
          onChange={handleFileChange}
          id={name}
        />
        {containsFile ? updateLabel : createLabel}
      </Button>
    </>
  );
};

const HiddenInput = styled("input")`
  display: none;
`;
