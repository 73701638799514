import React, { useState } from "react";
import styled from "styled-components";
import { DatePicker, Button } from "../Elements";
import moment from "moment";

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
`;

const ActionContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin-left: 10px;
  position: relative;
`;

const PickerContainer = styled.div`
  flex: 1;
  margin-left: 10px;
`;

export const ClientImportRoadmap = ({
  company,
  onBatchesChange,
  clientBatches,
}) => {
  const [isDatePicker, setIsDatePicker] = useState(false);
  return (
    <ButtonContainer>
      {company?.name && (
        <Button
          disabled={!company.batches || company.batches.length === 0}
          title={
            !company.batches || company.batches.length === 0
              ? "No company roadmap present"
              : "Click to import"
          }
          onClick={() => setIsDatePicker(true)}
        >
          {`Import ${company.name} Roadmap`}
        </Button>
      )}
      {isDatePicker && (
        <ActionContainer>
          <p>Select start date:</p>
          <PickerContainer>
            <DatePicker
              label="Active from"
              onChange={d => {
                const date = d.valueOf();
                setIsDatePicker(false);

                if (clientBatches.length >= 1) {
                  const confirmationStr =
                    "This will overwrite the clients current roadmap and cannot be undone. \n\nAre you sure you want to continue?";
                  if (!window.confirm(confirmationStr)) return;
                }

                onBatchesChange(
                  company.batches.map(batch => ({
                    ...batch,
                    from: moment(batch.from).startOf("day") + date,
                    till: moment(batch.till).startOf("day") + date,
                    items: batch.items.map(item => ({
                      ...item,
                      from: moment(item.from).valueOf() + date,
                      till: moment(item.till).valueOf() + date,
                    })),
                  }))
                );
              }}
              style={{
                flexShrink: 0,
              }}
              value={Date.now()}
            />
          </PickerContainer>
        </ActionContainer>
      )}
    </ButtonContainer>
  );
};
