import React from "react";
import gql from "graphql-tag";

import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { TopicForm } from "../Components/Topics/TopicForm";

import { getToken, useQuery, useMutation } from "../Graphql.js";

export let EditTopic = ({
  history,
  match: {
    params: { id },
  },
}) => {
  let {
    data: { topic },
  } = useQuery(TOPIC_QUERY, {
    variables: { token: getToken(), id },
    suspend: true,
  });
  let [updateTopicMutation] = useMutation(UPDATE_TOPIC_MUTATION, {
    variables: { token: getToken() },
  });

  let updateTopic = async topic => {
    await updateTopicMutation({
      variables: {
        token: getToken(),
        id: id,
        data: topic,
      },
    });

    history.push("/topics");
  };

  return (
    <Page>
      <Container>
        <PageHeader>
          <PageTitle>
            <h2>Update Topic</h2>
          </PageTitle>
        </PageHeader>

        <TopicForm topic={topic} onSubmit={updateTopic} buttonText="Update" />
      </Container>
    </Page>
  );
};

const TOPIC_FRAGMENT = gql`
  fragment TopicFragment on Topic {
    _id
    title
    icon
    weight
    category
    content {
      _id
      type
      weight
      body
      url
      videoId
    }
    questionsAndAnswers {
      _id
      question
      answer
    }
  }
`;

const TOPIC_QUERY = gql`
  query($token: String!, $id: String!) {
    topic(token: $token, _id: $id) {
      ...TopicFragment
    }
  }
  ${TOPIC_FRAGMENT}
`;

const UPDATE_TOPIC_MUTATION = gql`
  mutation($token: String!, $id: String!, $data: TopicInput!) {
    updateTopic(token: $token, _id: $id, data: $data) {
      ...TopicFragment
    }
  }
  ${TOPIC_FRAGMENT}
`;
