import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { TableBody } from "@material-ui/core";

export const SortableTableBody = ({ onSort, children }) => {
  return (
    <DragDropContext onDragEnd={onSort}>
      <Droppable droppableId="droppable">
        {provided => (
          <TableBody {...provided.droppableProps} ref={provided.innerRef}>
            {children}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};
