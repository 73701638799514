import React from "react";
import styled from "styled-components";
import { StepType } from "./Step";
import { ProposalOptionsForm } from "./ProposalOptionsForm";
import { Label } from "./StepForm";

export const SendProposalStep = ({ step, onChange, allSteps }) => {
  const createProposalSteps = allSteps.filter(
    step => step.type === StepType.CREATE_PROPOSAL
  );

  const NOT_LINKED = "not-linked";
  const linkStep = newValue => {
    const stepId = newValue === NOT_LINKED ? null : newValue;

    onChange("createProposalStepId", stepId);
  };

  return (
    <section>
      <label style={{ display: "flex", flexDirection: "column" }}>
        <Label>Link create proposal step (optional)</Label>
        <SelectInput
          defaultValue={step.createProposalStepId}
          onChange={e => linkStep(e.target.value)}
        >
          <option value={NOT_LINKED}>Not linked</option>
          {createProposalSteps.map(stepOption => (
            <option key={stepOption._id} value={stepOption._id}>
              {stepOption.title}
            </option>
          ))}
        </SelectInput>
      </label>

      {step.createProposalStepId == null && (
        <ProposalOptionsForm options={step.options} onChange={onChange} />
      )}
    </section>
  );
};

const SelectInput = styled.select`
  border-radius: 5px;
  border: solid 1px #d9e4eb;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #2a2135;
`;
