import { DateTime } from "luxon";

let memoize_one = fn => {
  let last_input = null;
  let last_output = null;

  return input => {
    if (input === last_input) {
      return last_output;
    } else {
      let output = fn(input);
      last_output = output;
      return output;
    }
  };
};

let split_query = memoize_one(query =>
  query
    .split(" ")
    .map(x => x.trim().toLowerCase())
    .filter(x => x !== "")
);
export let applySearch = ({ search: query, values }) => {
  let query_parts = split_query(query);

  if (query_parts.length === 0) {
    return true;
  }

  return query_parts.every(query_part => {
    return values.some(value => {
      let lowercase_value = (value || "").toLowerCase();
      return lowercase_value.includes(query_part);
    });
  });
};

export let moment_ceil_today = float =>
  DateTime.fromMillis(float)
    .endOf("day")
    .valueOf();

export let moment_floor_today = float =>
  DateTime.fromMillis(float)
    .startOf("day")
    .valueOf();

export let convert_to_cents = euros => {
  return euros * 100;
};

export let convert_to_euros = cents => {
  return cents / 100;
};

export const check_if_local_env = () => {
  return Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
};
