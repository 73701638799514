import React from "react";
import styled from "styled-components";

export const ChooseOption = ({ onSelect, options, value }) => (
  <Wrapper>
    {options.map(option => (
      <Option
        chosen={value === option.key}
        key={option.key}
        onClick={() => onSelect(option)}
      >
        {option.label}
      </Option>
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  padding: 0 12px;
  border-radius: 13px;
  margin-right: 6px;
  font-size: 12px;
  font-weight: bold;
  color: ${({ chosen }) => (chosen ? "white" : "#657786")};
  background: ${({ chosen }) => (chosen ? "#42c175" : "#eef2f5")};
  cursor: pointer;
`;
