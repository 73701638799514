import React from "react";
import { TableCell } from "./TableCell";
import { IconButton } from "@material-ui/core";
import NavLinkAdapter from "../NavLinkAdapter";
import PhosphorIcon from "../phosphor-icons/phosphor-icon";


export const ActionTableCell = ({ onClick, to, iconName, backgroundColor }) => {
  return (
    <TableCell width="50px" isCentered backgroundColor={backgroundColor}>
      <IconButton
        onClick={onClick}
        to={to}
        component={to ? NavLinkAdapter : undefined}
      >
        <PhosphorIcon name={iconName} />
      </IconButton>
    </TableCell>
  );
};
