import React, { useMemo, useState } from "react";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import uuid from "uuid";
import omitDeep from "omit-deep-lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery, useMutation } from "../../../Graphql";
import { getToken } from "../../../constants";
import { PhasesList } from "../../Phases/PhasesList";
import { UpdateConfirmation } from "../../../Elements/HelpInfo";

export const INFORMATION_PHASE = "__INFORMATION__";
export const ROUTE_PHASE = "__ROUTE__";
export const CHECKLIST_PHASE = "__CHECKLIST__";
export const AFTERSALES_PHASE = "__AFTERSALES__";

const DEFAULT_PHASES = [
  {
    _id: uuid.v4(),
    description: "Phase 1: Information",
    title: INFORMATION_PHASE,
  },
  {
    _id: uuid.v4(),
    description: "Phase 2: Route",
    title: ROUTE_PHASE,
  },
  {
    _id: uuid.v4(),
    description: "Phase 3: Checklist",
    title: CHECKLIST_PHASE,
  },
  {
    _id: uuid.v4(),
    description: "Phase 4: Aftersales",
    title: AFTERSALES_PHASE,
  },
];

export const Phases = () => {
  const { id: todoId } = useParams();

  const { data, loading } = useQuery(PHASES_QUERY, {
    variables: {
      token: getToken(),
      _id: todoId,
    },
  });

  const [updateTodoPhases] = useMutation(UPDATE_PHASES);
  const [updateSuccess, set_updateSuccess] = useState(false);

  const queryPhases = useMemo(() => {
    if (
      Array.isArray(data?.adminTodo?.handling?.phases) === false ||
      data?.adminTodo?.handling?.phases.length === 0
    ) {
      return DEFAULT_PHASES;
    }

    return DEFAULT_PHASES.map(defaultPhase => {
      const idx = data.adminTodo.handling.phases.findIndex(
        phase => phase.title === defaultPhase.title
      );

      if (idx > -1) {
        return data.adminTodo.handling.phases[idx];
      }

      return defaultPhase;
    });
  }, [data?.adminTodo?.handling?.phases]);

  if (loading) return <CircularProgress className="loader" />;

  const onUpdatePhases = async newPhases => {
    if (newPhases.length !== 0) {
      newPhases.forEach(phase => {
        if (phase.title.trim() === "") {
          alert("All phases must have a title");
          return;
        } else if (phase.description.trim() === "") {
          alert("All phases must have a description");
          return;
        }
      });
    }

    await updateTodoPhases({
      variables: {
        token: getToken(),
        todoId,
        phases: omitDeep(newPhases, "__typename"),
      },
    });
    set_updateSuccess(true);
    setTimeout(() => {
      set_updateSuccess(false);
    }, 1000);
  };

  return (
    <div>
      {queryPhases.length === 0 && <p>No phases found</p>}
      {queryPhases.length > 0 && (
        <PhasesList
          checklistItems={data?.adminTodo?.handling?.checklistItems ?? []}
          onUpdatePhases={onUpdatePhases}
          phases={queryPhases}
          routes={data?.adminTodo?.handling?.routes ?? []}
        />
      )}

      {updateSuccess && (
        <UpdateConfirmation
          successStatus={updateSuccess}
          content={"Phases updated successfully!"}
        />
      )}
    </div>
  );
};

const PHASES_QUERY = gql`
  query($token: String!, $_id: String!) {
    adminTodo(token: $token, _id: $_id) {
      _id
      title
      handling {
        checklistItems {
          _id
          description
          phaseId
          route
          title
          isInactive
          geTitle
          geDescription
          tasks {
            _id
            title
            description
            isInactive
          }
        }
        phases {
          _id
          title
          description
        }
        routes {
          _id
          description
          title
          isInactive
          geTitle
          geDescription
        }
      }
    }
  }
`;

const UPDATE_PHASES = gql`
  mutation updateTodoPhases(
    $token: String!
    $todoId: String!
    $phases: [InputPhase!]!
  ) {
    updateTodoPhases(token: $token, todoId: $todoId, phases: $phases) {
      _id
      title
      handling {
        phases {
          _id
          title
          description
        }
      }
    }
  }
`;
