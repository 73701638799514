import React, { useState } from "react";
import styled from "styled-components";
import immer from "immer";
import {
  BatchSettings,
  Creator,
  Navigation,
  TodoSelector,
  SideBar,
  Calendar,
} from "./index";
import { useDebouncedUpdate } from "../../useGeneric.js";
import moment from "moment";

const TodoViewContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 10px;
`;

const TodoContainer = styled.div`
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
`;

export const TodoView = ({
  allowances,
  batches: parent_batches,
  onBatchesChange: parent_onBatchesChange,
  todoSuggestions,
  companyTodos,
  roadmapType,
}) => {
  let [batches, _, onBatchesChange] = useDebouncedUpdate({
    value: parent_batches,
    delay: 500,
    onUpdate: value => {
      parent_onBatchesChange(value);
    },
  });

  const [activeBatch, setActiveBatch] = useState(0);
  const [visibleTodo, setVisibleTodo] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [isSelector, setIsSelector] = useState(false);
  const [isSettings, setIsSettings] = useState(false);

  //Create a new period
  if (isCreator) {
    return (
      <Creator
        isInitialBatch={batches.length === 0}
        onBatchAdd={(from, till) => {
          onBatchesChange([
            ...batches,
            {
              __typename: "Batch",
              from: from,
              till: till,
              items: [],
            },
          ]);
        }}
        companyNextBatchStart={
          batches.length >= 1 ? batches[batches.length - 1].till : 42800000 // mid day 1/1/1970
        }
        roadmapType={roadmapType}
        setIsCreator={setIsCreator}
      />
    );
  }

  //Show a list of todos to add to the period
  if (isSelector) {
    return (
      <TodoSelector
        activeBatch={activeBatch}
        batch={batches[activeBatch]}
        onAddTodo={todo => {
          const nextBatches = immer(batches, batches => {
            batches[activeBatch].items.push(todo);
          });
          onBatchesChange(nextBatches);
        }}
        onRemoveTodo={index => {
          const nextBatches = immer(batches, batches => {
            batches[activeBatch].items.splice(index, 1);
          });
          onBatchesChange(nextBatches);
        }}
        onTodoMove={(target, index) => {
          let nextBatches = immer(batches, batches => {
            const targetIndex = batches[activeBatch].items[target];
            batches[activeBatch].items[target] =
              batches[activeBatch].items[index];
            batches[activeBatch].items[index] = targetIndex;
          });
          onBatchesChange(nextBatches);
        }}
        setIsSelector={setIsSelector}
        todoSuggestions={todoSuggestions}
        companyTodos={companyTodos}
      />
    );
  }

  //Period setting: set the dates or delete the period
  if (isSettings) {
    return (
      <BatchSettings
        activeBatch={activeBatch}
        batch={batches[activeBatch]}
        onDeleteBatch={() => {
          const newBatches = [
            ...batches.slice(0, activeBatch),
            ...batches.slice(activeBatch + 1),
          ];
          if (newBatches.length < 1) {
            setActiveBatch(0);
          } else {
            const newIndex = activeBatch - 1;
            setActiveBatch(newIndex < 0 ? 0 : newIndex);
          }
          setIsSettings(false);
          onBatchesChange(newBatches);
        }}
        onSettingsChange={batch => {
          const nextBatches = immer(batches, draft => {
            const { from, till } = batch;
            const items = batch.items.map(item => ({
              ...item,
              from: moment(from).startOf("day"),
              till: moment(till).endOf("day"),
            }));
            draft[activeBatch] = { ...batch, items };
          });
          onBatchesChange(nextBatches);
        }}
        roadmapType={roadmapType}
        setIsSettings={setIsSettings}
      />
    );
  }

  //Default. On a period, see a list of todos and their dates
  return (
    <TodoViewContainer>
      <Navigation
        activeBatch={activeBatch}
        setActiveBatch={n => {
          if (n < batches.length) {
            setActiveBatch(n);
          }
        }}
        totalBatches={batches.length}
        setIsCreator={setIsCreator}
      />
      {batches.length >= 1 && (
        <TodoContainer>
          <SideBar
            activeBatch={activeBatch}
            allowances={allowances}
            batch={batches[activeBatch]}
            onTodoChange={(todo_index, new_todo) => {
              let new_batches = immer(batches, batches => {
                batches[activeBatch].items[todo_index] = {
                  ...batches[activeBatch].items[todo_index],
                  ...new_todo,
                };
              });
              onBatchesChange(new_batches);
            }}
            roadmapType={roadmapType}
            setIsSettings={setIsSettings}
            setIsSelector={setIsSelector}
            setVisibleTodo={setVisibleTodo}
            visibleTodo={visibleTodo}
          />
          <Calendar
            batch={batches[activeBatch]}
            setVisibleTodo={setVisibleTodo}
            visibleTodo={visibleTodo}
            onTodoChange={(todo_index, new_todo) => {
              let new_batches = immer(batches, batches => {
                batches[activeBatch].items[todo_index] = {
                  ...batches[activeBatch].items[todo_index],
                  ...new_todo,
                };
              });
              onBatchesChange(new_batches);
            }}
          />
        </TodoContainer>
      )}
    </TodoViewContainer>
  );
};
