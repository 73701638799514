import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { InputList } from "../../InputList";
import { MarkupTextArea } from "../../MarkupTextArea";
import FormInfo from "../../Styled/FormInfo";
import FormRow from "../../Styled/FormRow";
import { SubmitForm } from "../HandlingPlanForm";
import { Select } from "../../Elements";
import gql from "graphql-tag";
import { getToken, useQuery } from "../../../Graphql";

const ESSENTIAL_SERVICES_QUERY = gql`
  query($token: String!) {
    essentialServices(token: $token) {
      _id
      name
    }
  }
`;

export const Info = props => {
  const {
    data: { essentialServices },
  } = useQuery(ESSENTIAL_SERVICES_QUERY, {
    variables: { token: getToken() },
    suspend: true,
  });

  const matchedService = essentialServices.find(
    entry => entry._id === props.serviceId
  );
  const selectedService = matchedService?._id;

  const serviceOptions = essentialServices.map(entry => {
    return {
      title: entry.name,
      value: entry._id,
    };
  });
  serviceOptions.unshift({ title: "None", value: null });

  return (
    <form>
      <FormRow>
        <FormInfo>
          <h3>General</h3>
          <p>
            This information is used to search handling plans in the portal and
            to show todo's to the client.
          </p>
        </FormInfo>
        <div className="inputs">
          <FormControlLabel
            control={
              <Switch
                checked={props.enabled}
                onChange={props.handleChange("enabled", "checked")}
                value="enabled"
                color="primary"
              />
            }
            label="Enabled"
            className="input input__switch"
          />
          <TextField
            className="input input__text"
            label="Title"
            required
            fullWidth={true}
            autoFocus={true}
            variant="filled"
            value={props.title}
            onChange={props.handleChange("title")}
          />
          <MarkupTextArea
            label="Intro text"
            value={props.description || ""}
            onChange={props.handleChange("description")}
          />
          <div style={{ height: 32 }} />
          <TextField
            className="input input__text"
            label="Region"
            fullWidth={true}
            variant="filled"
            value={props.region}
            onChange={props.handleChange("region")}
          />
        </div>
      </FormRow>

      <FormRow>
        <FormInfo>
          <h3>Sensitive info consent</h3>
          <p>
            The client is asked if we can access/use this information when
            handling this todo for them. This is asked automatically when
            activating the todo.
          </p>
        </FormInfo>

        <InputList
          values={props.sensitiveInfo}
          onChange={props.onSensitiveInfoChange}
          addButtonText="Add sensitive info item"
        />
      </FormRow>

      <FormRow>
        <FormInfo>
          <h3>Requirements</h3>
          <p>
            These requirements are separate from the handling and will show up
            in the knowledge base.
          </p>
        </FormInfo>

        <InputList
          values={props.requirements}
          onChange={props.onRequirementsChange}
          addButtonText="Add requirement"
        />
      </FormRow>

      <FormRow>
        <FormInfo>
          <h3>Other</h3>
          <p>These parts of the todo are changed in other places.</p>
        </FormInfo>
      </FormRow>

      <FormRow>
        <FormInfo>
          <h3>Attached service</h3>
          <p>
            Attaching a service allows the RMP to determine the status of a
            service for a GE that has this todo attached to their roadmap.
          </p>
        </FormInfo>

        <div style={{ display: "flex", width: "100%" }}>
          <Select
            label="Service"
            items={serviceOptions}
            onChange={e => {
              const chosenOption = essentialServices.find(service => {
                return service._id === e.target.value;
              });
              props.onServiceIdChange(chosenOption?._id ?? null);
            }}
            value={selectedService}
          />
        </div>
      </FormRow>

      <SubmitForm handleSubmit={props.handleSubmit} />
    </form>
  );
};
