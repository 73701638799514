import React from "react";
import styled from "styled-components";

const DEFAULT_LINES_TO_CLAMP = 3;

export const ClampedText = ({
  linesToClamp = DEFAULT_LINES_TO_CLAMP,
  children,
}) => {
  return (
    <ClampedTextContainer linesToClamp={linesToClamp}>
      {children}
    </ClampedTextContainer>
  );
};

const ClampedTextContainer = styled("span")`
  display: -webkit-box;
  -webkit-line-clamp: ${({ linesToClamp }) => "" + linesToClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
