import React, { useState } from "react";
import styled from "styled-components";
import { DatePicker, Whitespace, Button } from "../Elements";
import moment from "moment";
import { ROADMAP_TYPE } from "../Company/CompanyRoadmap";

const CreatorContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 10px;
`;

const CreatorNavigation = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-bottom: 10px;
`;

const PickerContainer = styled.div`
  display: flex;
`;

const CalendarContainer = styled.div`
  flex-shrink: 0;
`;

const Hint = styled.p`
  flex: 1;
  line-height: 50px;
  margin-left: 10px;
  vertical-align: middle;
`;

const PickerClient = ({
  from,
  till,
  onBatchAdd,
  setIsCreator,
  setFrom,
  setTill,
}) => {
  return (
    <PickerContainer>
      <CalendarContainer>
        <DatePicker
          label="Active from"
          onChange={d => {
            const date = d.valueOf();
            if (till && moment(date).isBefore(till)) {
              onBatchAdd(date, till);
              return setIsCreator(false);
            }
            setFrom(date);
          }}
          style={{
            flexShrink: 0,
          }}
          value={from}
        />
      </CalendarContainer>
      <Whitespace width="8px" />
      <CalendarContainer>
        <DatePicker
          label="Active till"
          minDate={from}
          onChange={d => {
            const date = d.valueOf();
            if (from && moment(date).isAfter(from)) {
              onBatchAdd(from, date);
              return setIsCreator(false);
            }
            setTill(date);
          }}
          style={{
            flexShrink: 0,
          }}
          value={till}
        />
      </CalendarContainer>
    </PickerContainer>
  );
};

const PickerCompany = ({
  isInitialBatch,
  from,
  till,
  onBatchAdd,
  setIsCreator,
  setFrom,
  setTill,
}) => {
  return (
    <PickerContainer>
      <CalendarContainer>
        <DatePicker
          disabled={true}
          label="Active from"
          format={"dd MMMM"}
          maxDate={31449600000}
          minDate={0}
          onChange={d => {
            const date = d.valueOf();
            if (till && moment(date).isBefore(till)) {
              onBatchAdd(date, till);
              return setIsCreator(false);
            }
            setFrom(date);
          }}
          style={{
            flexShrink: 0,
          }}
          value={from}
        />
      </CalendarContainer>
      <Whitespace width="8px" />
      <CalendarContainer>
        <DatePicker
          label="Active till"
          format={"dd MMMM"}
          maxDate={31449600000}
          minDate={from}
          onChange={d => {
            const date = d.valueOf();
            /* Because we're working soley with past dates the DatePicker automatically jumps to maxDate and 
              fires onChange handler - this timestamp prevents onBatchAdd being called prematurely. */
            if (from && moment(date).isAfter(from) && date < 31363200000) {
              onBatchAdd(from, date);
              return setIsCreator(false);
            }
            setTill(date);
          }}
          style={{
            flexShrink: 0,
          }}
          value={till}
        />
      </CalendarContainer>
      {isInitialBatch && (
        <Hint>All company roadmaps must initially start from 1st January.</Hint>
      )}
    </PickerContainer>
  );
};

export const Creator = ({
  isInitialBatch,
  onBatchAdd,
  companyNextBatchStart,
  setIsCreator,
  roadmapType,
}) => {
  const [from, setFrom] = useState(null);
  const [till, setTill] = useState(null);

  return (
    <CreatorContainer>
      <CreatorNavigation>
        <Button
          onClick={() => setIsCreator(false)}
          style={{
            height: "40px",
            display: "block",
            width: "200px",
          }}
        >
          Cancel
        </Button>
      </CreatorNavigation>
      <h3>Choose date range for the new period</h3>
      <Whitespace height="8px" />
      {roadmapType === ROADMAP_TYPE.PERSONAL ? (
        <PickerClient
          from={from}
          till={till}
          onBatchAdd={onBatchAdd}
          setIsCreator={setIsCreator}
          setFrom={setFrom}
          setTill={setTill}
        />
      ) : (
        <PickerCompany
          isInitialBatch={isInitialBatch}
          from={companyNextBatchStart}
          till={companyNextBatchStart}
          onBatchAdd={onBatchAdd}
          setIsCreator={setIsCreator}
          setFrom={setFrom}
          setTill={setTill}
        />
      )}
    </CreatorContainer>
  );
};
