import React, { useState } from "react";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import { Button, TextInput } from "../Elements";
import Tooltip from "@material-ui/core/Tooltip";
import { WarningSign } from "./SideBar";
import PhosphorIcon from "../phosphor-icons/phosphor-icon";

const SelectorView = styled.div`
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 10px;
`;

const Navigation = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-bottom: 10px;
`;

const HeaderContainer = styled.div`
  height: 60px;
`;

const Header = styled.div`
  display: flex;
`;

const BatchTitle = styled.p`
  line-height: 60px;
  padding-left: 10px;
  vertical-align: middle;
  width: 350px;
`;

const SearchBar = styled.div`
  flex: 1;
`;

const SelectorContainer = styled.div`
  height: 500px;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const SideBar = styled.div`
  flex-shrink: 0;
  width: 350px;
`;

const TodoContainer = styled.div`
  padding: 0 5px;
  width: 100%;

  &:nth-child(even) {
    background-color: #f5f5f5;
  }
`;

const TodoIcon = styled.div`
  cursor: pointer;
  line-height: 50px;
  padding-right: 5px;
  width: 15px;
  vertical-align: middle;
`;

const TodoPriority = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  height: 50%;
  width: 35px;
`;

const UpIcon = styled.div`
  cursor: pointer;
`;

const DownIcon = styled.div`
  cursor: pointer;
`;

const TodoTitle = styled.p`
  flex: 1;
  margin: auto;
  margin-left: 5px;
`;

const TodoTitleContainer = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
`;

const SuggestionContainer = styled.div`
  border-left: 1px solid #e5e5e5;
  flex: 1;
  flex-shrink: 0;
`;

export const TodoSelector = ({
  activeBatch,
  batch,
  onAddTodo,
  onRemoveTodo,
  onTodoMove,
  setIsSelector,
  todoSuggestions,
  companyTodos,
}) => {
  const { items } = batch;
  const [filteredTodos, setFilteredTodos] = useState(
    companyTodos && companyTodos.length > 0 ? companyTodos : todoSuggestions
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [todoIds] = useState(new Set());

  return (
    <SelectorView>
      <Navigation>
        <Button
          onClick={() => setIsSelector(false)}
          style={{
            height: "40px",
            display: "block",
            width: "200px",
          }}
        >
          Done
        </Button>
      </Navigation>
      <HeaderContainer>
        <AppBar position="static" color="default">
          <Header>
            <BatchTitle>Period {activeBatch + 1}</BatchTitle>
            <SearchBar>
              <TextInput
                label="Search"
                value={searchQuery}
                style={{ width: 400 }}
                onChange={e => {
                  const query = e.target.value;
                  setSearchQuery(query);
                  if (query === "") return setFilteredTodos(todoSuggestions);
                  const filteredTodos = todoSuggestions.filter(
                    ({ title, region }) => {
                      if (title.toLowerCase().includes(query.toLowerCase()))
                        return true;
                      return region
                        ?.toLowerCase()
                        .includes(query.toLowerCase());
                    }
                  );
                  setFilteredTodos(filteredTodos);
                }}
              />
            </SearchBar>
          </Header>
        </AppBar>
      </HeaderContainer>
      <SelectorContainer>
        <SideBar>
          {items.map((todo, index) => {
            const _id = todo.todo?._id ?? todo.item._id;
            const title = todo.todo?.title ?? todo.item.title;
            todoIds.add(_id);
            return (
              <TodoContainer key={_id}>
                <TodoTitleContainer>
                  <TodoIcon
                    onClick={() => {
                      todoIds.delete(_id);
                      onRemoveTodo(index);
                    }}
                  >
                    <PhosphorIcon name="x" />
                  </TodoIcon>
                  <TodoTitle>
                    {todo.item?._id && (
                      <Tooltip
                        title={
                          "This item is deprecated and will be removed on 28/10/2020 a new todo needs to be created"
                        }
                        placement="top-start"
                      >
                        <WarningSign />
                      </Tooltip>
                    )}
                    {title}
                  </TodoTitle>
                  <TodoPriority>
                    <UpIcon
                      onClick={() => {
                        if (index > 0) {
                          onTodoMove(index - 1, index);
                        }
                      }}
                    >
                      <PhosphorIcon name="caret-up" />
                    </UpIcon>
                    <DownIcon
                      onClick={() => {
                        if (index < items.length - 1) {
                          onTodoMove(index + 1, index);
                        }
                      }}
                    >
                      <PhosphorIcon name="caret-down" />
                    </DownIcon>
                  </TodoPriority>
                </TodoTitleContainer>
              </TodoContainer>
            );
          })}
        </SideBar>
        <SuggestionContainer>
          {filteredTodos.map(todo => {
            const { _id, title } = todo;
            if (!todoIds.has(_id) && title.length > 0) {
              return (
                <TodoContainer key={_id}>
                  <TodoTitleContainer>
                    <TodoIcon
                      onClick={() => {
                        const attrObj = {
                          from: batch.from,
                          till: batch.till,
                        };
                        if (todo.__typename === "TimelineItem") {
                          attrObj.item = todo;
                        }
                        if (todo.__typename === "HandlingPlan") {
                          attrObj.todo = todo;
                        }
                        const assignedItem = {
                          status: "ACTIVE",
                          credit_in_cents: 0,
                          dismiss_reason: null,
                          remindedAt: null,
                          times_usable: 1,
                          times_used: 0,
                          __typename: "AssignedTimelineItem",
                          ...attrObj,
                        };
                        onAddTodo(assignedItem);
                      }}
                    >
                      <PhosphorIcon name="plus" />
                    </TodoIcon>
                    <TodoTitle>
                      {todo.suggestion && (
                        <div
                          title="This item is deprecated and will be removed on 28/10/2020 a new todo needs to be created."
                          style={{
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          <PhosphorIcon
                            name="warning"
                          />
                        </div>
                      )}

                      {`${todo.title} ${
                        todo.region ? `in ${todo.region}` : ""
                      }`}
                    </TodoTitle>
                  </TodoTitleContainer>
                </TodoContainer>
              );
            }
            return null;
          })}
        </SuggestionContainer>
      </SelectorContainer>
    </SelectorView>
  );
};
