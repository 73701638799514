import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import gql from "graphql-tag";

import { useQuery, useMutation } from "../Graphql.js";
import { DeleteButton } from "../Components/DeleteButton";
import NavLinkAdapter from "../Components/NavLinkAdapter";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { getToken } from "../constants";
import PhosphorIcon from "../Components/phosphor-icons/phosphor-icon.js";

const REMOVE_TOPIC_MUTATION = gql`
  mutation($token: String!, $id: String!) {
    removeTopic(token: $token, _id: $id) {
      id
    }
  }
`;

export let Topics = () => {
  let [removeTopicMutation] = useMutation(REMOVE_TOPIC_MUTATION, {
    variables: {
      token: getToken(),
    },
    update: (cache, { data }) => {
      const topicId = data.removeTopic.id;

      const { topics } = cache.readQuery({
        query: TOPICS_LIST_QUERY,
        variables: { token: getToken() },
      });

      cache.writeQuery({
        query: TOPICS_LIST_QUERY,
        variables: { token: getToken() },
        data: {
          topics: topics.filter(topic => topic._id !== topicId),
        },
      });
    },
  });

  let {
    data: { topics },
  } = useQuery(TOPICS_LIST_QUERY, {
    variables: { token: getToken() },
    suspend: true,
  });

  return (
    <Page>
      <Container>
        <PageHeader>
          <PageTitle>
            <h2>Topics</h2>
            <Button
              variant="contained"
              size="small"
              component={NavLinkAdapter}
              to="/topics/create"
            >
              Add
            </Button>
          </PageTitle>
        </PageHeader>

        <Table style={{ background: "white" }}>
          <TableHead>
            <TableRow>
              <TableCell />
              {/* edit buttons */}
              <TableCell>Category</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Title</TableCell>
              <TableCell />
              {/* delete buttons */}
            </TableRow>
          </TableHead>

          <TableBody>
            {topics.map(topic => (
              <TableRow key={topic._id}>
                <TableCell style={{ width: 50 }}>
                  <IconButton
                    aria-label="edit"
                    component={NavLinkAdapter}
                    to={`/topics/${topic._id}/edit`}
                  >
                    <PhosphorIcon name="pencil" />
                  </IconButton>
                </TableCell>

                <TableCell>{topic.category}</TableCell>
                <TableCell>{topic.weight + 1}</TableCell>
                <TableCell>{topic.title}</TableCell>

                <TableCell style={{ width: 50 }}>
                  <DeleteButton
                    onClick={async () => {
                      await removeTopicMutation({
                        variables: {
                          id: topic._id,
                        },
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Page>
  );
};

export const TOPICS_LIST_QUERY = gql`
  query($token: String!) {
    topics(token: $token) {
      _id
      title
      weight
      category
    }
  }
`;
