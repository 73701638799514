import TableHead from "@material-ui/core/TableHead";
import React from "react";
import { Query, Mutation } from "react-apollo";
import { withSnackbar } from "notistack";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageTitle from "../Components/Styled/PageTitle";
import { getToken } from "../constants";
import PageHeader from "../Components/Styled/PageHeader";
import {
  CREATE_TAG_MUTATION,
  REMOVE_TAG_MUTATION,
  UPDATE_TAG_MUTATION,
} from "../Graphql/mutations";
import { TAGS_LIST_QUERY } from "../Graphql/queries";
import PhosphorIcon from "../Components/phosphor-icons/phosphor-icon";

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.captureKeyPressing = this.captureKeyPressing.bind(this);

    this.state = {
      searchQuery: "",
      newTagName: "",
      addNewTagEnabled: false,
      tagToUpdate: {},
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.captureKeyPressing, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.captureKeyPressing, false);
  }

  captureKeyPressing(event) {
    // Escape is pressed
    if (event.keyCode === 27) {
      if (this.state.addNewTagEnabled) {
        this.disableAddingNewTag();
      }
      if (this.state.tagToUpdate._id != null) {
        this.disableTagUpdate();
      }
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleEditChange = event => {
    this.setState({
      tagToUpdate: {
        ...this.state.tagToUpdate,
        name: event.target.value,
      },
    });
  };

  getFilteredAndSortedTags = tags => {
    return tags
      .filter(tag => {
        const parsedName = tag.name.toLowerCase();
        const parsedSearchQuery = this.state.searchQuery.toLowerCase().trim();

        return parsedName.includes(parsedSearchQuery);
      })
      .sort((tagA, tagB) => {
        return tagA.name > tagB.name ? 1 : -1;
      });
  };

  enableAddingNewTag = () => {
    this.setState({
      addNewTagEnabled: true,
    });
  };

  disableAddingNewTag = () => {
    this.setState({
      addNewTagEnabled: false,
      newTagName: "",
    });
  };

  enableTagUpdate = tagToUpdate => {
    this.setState({
      tagToUpdate,
    });
  };

  disableTagUpdate = () => {
    this.setState({
      tagToUpdate: {},
    });
  };

  createTag = async mutation => {
    await mutation({
      variables: {
        token: getToken(),
        name: this.state.newTagName,
      },
    });
  };

  updateTag = async mutation => {
    await mutation({
      variables: {
        token: getToken(),
        _id: this.state.tagToUpdate._id,
        name: this.state.tagToUpdate.name,
      },
    });
  };

  removeTag = (mutation, { _id, name }) => () => {
    if (!window.confirm(`Are you sure you want to delete: ${name}`)) {
      return;
    }

    mutation({
      variables: {
        token: getToken(),
        id: _id,
      },
    });
  };

  /**
   * Manually remove the tag from the query cache.
   */
  handleRemovedTag = (cache, { data }) => {
    if (!data.removeTags.success) {
      this.props.enqueueSnackbar("Failed removing tag.", {
        variant: "error",
      });
      return;
    }

    const tagId = data.removeTags.id;

    const { allTags } = cache.readQuery({
      query: TAGS_LIST_QUERY,
      variables: { token: getToken() },
    });

    cache.writeQuery({
      query: TAGS_LIST_QUERY,
      variables: { token: getToken() },
      data: {
        allTags: allTags.filter(tag => tag._id !== tagId),
      },
    });

    this.props.enqueueSnackbar("Successfully removed tag.", {
      variant: "success",
    });
  };

  /**
   * Manually updated the tag from the query cache.
   */
  handleUpdatedTag = (cache, { data }) => {
    const tagId = data.tagsUpdate._id;

    const { allTags } = cache.readQuery({
      query: TAGS_LIST_QUERY,
      variables: { token: getToken() },
    });

    const newTags = allTags.map(tag => {
      if (tag._id === tagId) {
        tag.name = data.tagsUpdate.name;
        return tag;
      }
      return tag;
    });

    cache.writeQuery({
      query: TAGS_LIST_QUERY,
      variables: { token: getToken() },
      data: {
        allTags: newTags,
      },
    });

    this.disableTagUpdate();
    this.props.enqueueSnackbar("Successfully updated tag.", {
      variant: "success",
    });
  };

  /**
   * Manually add the tag from the query cache.
   */
  handleCreatedTag = (cache, { data: { addTags } }) => {
    const { allTags } = cache.readQuery({
      query: TAGS_LIST_QUERY,
      variables: { token: getToken() },
    });

    cache.writeQuery({
      query: TAGS_LIST_QUERY,
      variables: { token: getToken() },
      data: { allTags: [...allTags, addTags] },
    });

    this.disableAddingNewTag();
    this.props.enqueueSnackbar("Successfully created tag.", {
      variant: "success",
    });
  };

  render() {
    return (
      <Page>
        <Container>
          <PageHeader>
            <PageTitle>
              <h2>Tags</h2>
              <Button
                variant="contained"
                size="small"
                onClick={this.enableAddingNewTag}
              >
                Add
              </Button>
            </PageTitle>
            <TextField
              label="Search"
              variant="outlined"
              type="search"
              autoFocus
              value={this.state.searchQuery}
              onChange={this.handleChange("searchQuery")}
            />
          </PageHeader>

          <Query query={TAGS_LIST_QUERY} variables={{ token: getToken() }}>
            {({ loading, data }) => {
              if (loading) {
                return <p>Loading!</p>;
              }
              return (
                <Table style={{ background: "white" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {/* edit/save buttons */}
                      <TableCell>Name</TableCell>
                      <TableCell />
                      {/* delete/cancel buttons */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* START OF ADDING NEW TAG */}
                    {this.state.addNewTagEnabled && (
                      <TableRow>
                        <Mutation
                          mutation={CREATE_TAG_MUTATION}
                          update={this.handleCreatedTag}
                        >
                          {(mutation, { loading, data, error }) => (
                            <TableCell style={{ width: 50 }}>
                              <IconButton
                                disabled={
                                  !this.state.newTagName ||
                                  this.state.newTagName.trim().length < 1
                                }
                                onClick={() => this.createTag(mutation)}
                                aria-label="save"
                              >
                                <PhosphorIcon name="floppy-disk" />
                              </IconButton>
                            </TableCell>
                          )}
                        </Mutation>
                        <TableCell>
                          <TextField
                            className="input input__text"
                            label="Name"
                            required
                            fullWidth={true}
                            autoFocus={true}
                            variant="filled"
                            value={this.state.newTagName}
                            onChange={this.handleChange("newTagName")}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            onClick={this.disableAddingNewTag}
                          >
                            <PhosphorIcon name="x" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                    {/* END OF ADDING NEW TAG */}
                    {this.getFilteredAndSortedTags(data.allTags).map(tag => {
                      const tagIsEditable =
                        this.state.tagToUpdate._id === tag._id;
                      return (
                        <TableRow key={tag._id}>
                          <TableCell style={{ width: 50 }}>
                            {tagIsEditable ? (
                              <Mutation
                                mutation={UPDATE_TAG_MUTATION}
                                update={this.handleUpdatedTag}
                              >
                                {(updateTagMutation, { loading }) => (
                                  <IconButton
                                    aria-label="save"
                                    onClick={() =>
                                      this.updateTag(updateTagMutation)
                                    }
                                  >
                                    <PhosphorIcon name="floppy-disk" />
                                  </IconButton>
                                )}
                              </Mutation>
                            ) : (
                              <IconButton
                                aria-label="edit"
                                onClick={() => this.enableTagUpdate(tag)}
                              >
                                <PhosphorIcon name="pencil" />
                              </IconButton>
                            )}
                          </TableCell>

                          <TableCell>
                            {tagIsEditable ? (
                              <TextField
                                className="input input__text"
                                label="Name"
                                required
                                fullWidth={true}
                                autoFocus={true}
                                variant="filled"
                                value={this.state.tagToUpdate.name}
                                onChange={this.handleEditChange}
                              />
                            ) : (
                              tag.name
                            )}
                          </TableCell>

                          <TableCell style={{ width: 50 }}>
                            {tagIsEditable ? (
                              <IconButton
                                aria-label="cancel"
                                onClick={this.disableTagUpdate}
                              >
                                <PhosphorIcon name="x" />
                              </IconButton>
                            ) : (
                              <Mutation
                                mutation={REMOVE_TAG_MUTATION}
                                update={this.handleRemovedTag}
                              >
                                {mutation => (
                                  <IconButton
                                    aria-label="delete"
                                    onClick={this.removeTag(mutation, tag)}
                                  >
                                    <PhosphorIcon name="trash" />
                                  </IconButton>
                                )}
                              </Mutation>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              );
            }}
          </Query>
        </Container>
      </Page>
    );
  }
}

export default withSnackbar(Tags);
