import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableHeader from "../TableHeader/tableHeader";
import TableContent from "../TableContent/tableContent";

class TableWrapper extends Component {
  render() {
    const { columns, data, navigate } = this.props;

    return (
      <Table>
        <TableHeader columns={columns} />
        <TableContent columns={columns} data={data} navigate={navigate} />
      </Table>
    );
  }
}

TableWrapper.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
};

export default TableWrapper;
