import React from "react";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const Autocomplete = ({
  label,
  options = [],
  value = "",
  onChange,
  required = false,
  allowCustomValue = false,
  style = {},
}) => (
  <MuiAutocomplete
    freeSolo={allowCustomValue}
    options={options}
    style={style}
    onChange={(event, value) => onChange(value)}
    value={value}
    renderInput={params => (
      <TextField
        label={label}
        required
        variant="filled"
        onChange={e => onChange(e.target.value)}
        fullWidth
        {...params}
      />
    )}
  />
);
