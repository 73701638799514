import React, { Component } from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class TableHeader extends Component {
  render() {
    const { columns } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columns.map(column => {
            return <TableCell key={column.key}>{column.label}</TableCell>;
          })}
        </TableRow>
      </TableHead>
    );
  }
}

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
};

export default TableHeader;
