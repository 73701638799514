import React from "react";
import gql from "graphql-tag";

import { Button, TextField, Icon, IconButton } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";

import { Whitespace } from "./Elements.js";
import { useQuery, getToken } from "../Graphql.js";

export const QAS_LIST_QUERY = gql`
  query($token: String!) {
    questionsAndAnswers(token: $token) {
      _id
      question
      answer
    }
  }
`;

export let QuestionAndAnswerForm = ({ onChange, value = [] }) => {
  let {
    data: { questionsAndAnswers },
  } = useQuery(QAS_LIST_QUERY, {
    variables: { token: getToken() },
    suspend: true,
  });

  let assigned_ids = value.map(x => x._id);

  return (
    <div>
      <MuiAutocomplete
        options={questionsAndAnswers.filter(x => !assigned_ids.includes(x._id))}
        getOptionLabel={option => `${option.question}`}
        // filterOptions={filterOptions}
        // style={{ width: 300 }}
        renderInput={params => (
          <TextField {...params} label="Add Q&A" variant="filled" fullWidth />
        )}
        onChange={(event, new_item) => {
          event.persist();
          onChange([...value, new_item]);
        }}
        value={null}
      />

      <Whitespace height={8} />

      <div style={{ display: "flex", flexDirection: "column" }}>
        {value.map(q_and_a => (
          <div
            key={q_and_a._id}
            style={{
              marginBottom: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: 4,
              paddingLeft: 8,
              backgroundColor: "rgba(0, 0, 0, 0.09)",
            }}
          >
            <IconButton
              color="primary"
              href={`/questionsAndAnswers/${q_and_a._id}/edit`}
            >
              <Icon children="edit" />
            </IconButton>
            <IconButton
              color="secondary"
              onClick={() => {
                onChange(value.filter(x => x._id !== q_and_a._id));
              }}
            >
              <Icon children="delete_outline" />
            </IconButton>

            <Whitespace width={8} />

            <div
              style={{
                flex: 1,
                fontSize: 20,
                overflow: `hidden`,
                textOverflow: `ellipsis`,
                whiteSpace: `nowrap`,
              }}
            >
              {q_and_a.question}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
