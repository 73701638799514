import React from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

export const RowControls = ({ index, total, onUp, onDown, onDelete }) => (
  <Row>
    <IconButton
      size="small"
      tabIndex="-1"
      disabled={index === 0}
      onClick={onUp}
    >
      <Icon icon={["far", "angle-up"]} />
    </IconButton>

    <RowNumber>{index + 1}.</RowNumber>

    <IconButton onClick={onDelete} tabIndex="-1">
      <PhosphorIcon name="trash" />
    </IconButton>

    <IconButton
      size="small"
      tabIndex="-1"
      disabled={index + 1 >= total}
      onClick={onDown}
    >
      <Icon name="caret-down" />
    </IconButton>
  </Row>
);

const Row = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RowNumber = styled("span")`
  font-size: 20px;
`;

const Icon = styled(PhosphorIcon)`
  width: 1em !important;
  height: 1em;
`;
