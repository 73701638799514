import React from "react";
import styled from "styled-components";
import { DatePicker, Whitespace } from "../Elements";
import AppBar from "@material-ui/core/AppBar";
import { Button } from "../Elements";
import moment from "moment";
import { ROADMAP_TYPE } from "../Company/CompanyRoadmap";

const SettingsView = styled.div`
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 10px;
`;

const Navigation = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-bottom: 10px;
`;

const HeaderContainer = styled.div`
  height: 60px;
`;

const Header = styled.div`
  display: flex;
`;

const BatchTitle = styled.p`
  line-height: 60px;
  padding-left: 10px;
  vertical-align: middle;
  width: 350px;
`;

const SettingsContainer = styled.div`
  padding: 10px 0 0 5px;
`;

const PickerClient = ({ from, till, batch, onSettingsChange }) => {
  return (
    <>
      <DatePicker
        label="Active from"
        maxDate={till}
        onChange={d => {
          const { items } = batch;
          const updatedItems = items.map(todo => {
            const { from } = todo;
            if (moment(from).isBefore(d)) {
              return { ...todo, from: d };
            }
            return todo;
          });
          const updatedBatch = {
            from: d.valueOf(),
            items: updatedItems,
            till,
            __typename: "Batch",
          };
          onSettingsChange(updatedBatch);
        }}
        style={{
          flexShrink: 0,
        }}
        value={from}
      />
      <Whitespace height="8px" />
      <DatePicker
        label="Active till"
        minDate={from}
        onChange={d => {
          const { items } = batch;
          const updatedItems = items.map(todo => {
            const { till } = todo;
            if (moment(till).isAfter(d)) {
              return { ...todo, till: d };
            }
            return todo;
          });
          const updatedBatch = {
            from,
            items: updatedItems,
            till: d.valueOf(),
            __typename: "Batch",
          };
          onSettingsChange(updatedBatch);
        }}
        style={{
          flexShrink: 0,
        }}
        value={till}
      />
    </>
  );
};

const PickerCompany = ({ from, till, batch, onSettingsChange }) => {
  return (
    <>
      <DatePicker
        label="Active from"
        format={"dd MMMM"}
        maxDate={till}
        minDate={0}
        onChange={d => {
          const date = d.valueOf();
          const { items } = batch;
          const updatedItems = items.map(todo => {
            const { from } = todo;
            if (moment(from).isBefore(date)) {
              return { ...todo, from: date };
            }
            return todo;
          });
          const updatedBatch = {
            from: date,
            items: updatedItems,
            till,
            __typename: "Batch",
          };
          onSettingsChange(updatedBatch);
        }}
        style={{
          flexShrink: 0,
        }}
        value={from}
      />
      <Whitespace width="8px" />
      <DatePicker
        label="Active till"
        format={"dd MMMM"}
        maxDate={31449600000}
        minDate={from}
        onChange={d => {
          const date = d.valueOf();
          const { items } = batch;
          const updatedItems = items.map(todo => {
            const { till } = todo;
            if (moment(till).isAfter(date)) {
              return { ...todo, till: date };
            }
            return todo;
          });
          const updatedBatch = {
            from,
            items: updatedItems,
            till: date,
          };
          onSettingsChange(updatedBatch);
        }}
        style={{
          flexShrink: 0,
        }}
        value={till}
      />
    </>
  );
};

export const BatchSettings = ({
  activeBatch,
  batch,
  onDeleteBatch,
  onSettingsChange,
  setIsSettings,
  roadmapType,
}) => {
  const { from, till } = batch;

  return (
    <SettingsView>
      <Navigation>
        <Button
          onClick={() => setIsSettings(false)}
          style={{
            height: "40px",
            display: "block",
            width: "200px",
          }}
        >
          Done
        </Button>
      </Navigation>
      <HeaderContainer>
        <AppBar position="static" color="default">
          <Header>
            <BatchTitle>Period {activeBatch + 1} Settings</BatchTitle>
          </Header>
        </AppBar>
      </HeaderContainer>
      <SettingsContainer>
        {roadmapType === ROADMAP_TYPE.PERSONAL ? (
          <PickerClient
            batch={batch}
            from={from}
            till={till}
            onSettingsChange={onSettingsChange}
            setIsSettings={setIsSettings}
          />
        ) : (
          <PickerCompany
            batch={batch}
            from={from}
            till={till}
            onSettingsChange={onSettingsChange}
            setIsSettings={setIsSettings}
          />
        )}
        <Whitespace height="36px" />
        <Button
          onClick={() => {
            const { items } = batch;
            if (items.length !== 0) {
              alert("You need to remove all items from this Period first");
              return;
            }
            onDeleteBatch();
          }}
        >
          Delete Batch
        </Button>
      </SettingsContainer>
    </SettingsView>
  );
};
