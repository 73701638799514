import React from "react";
import gql from "graphql-tag";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TableHead from "@material-ui/core/TableHead";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";

import { useQuery, useMutation } from "../Graphql.js";
import { HandlingPlanFragment } from "../Graphql/fragments.js";
import NavLinkAdapter from "../Components/NavLinkAdapter";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageTitle from "../Components/Styled/PageTitle";
import { getToken } from "../constants";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import PageHeader from "../Components/Styled/PageHeader";
import Button from "@material-ui/core/Button";
import { COMPANIES_QUERY, HANDLING_PLANS_LIST_QUERY } from "../Graphql/queries";
import PhosphorIcon from "../Components/phosphor-icons/phosphor-icon.js";

const styles = {
  tableRow: {
    "& .basicButton": {
      visibility: "hidden",
    },
    "&:hover": {
      "& .basicButton": {
        visibility: "visible",
      },
    },
  },
};

let updateQuery = ({ cache, query, variables, update }) => {
  const data = cache.readQuery({
    query,
    variables,
  });

  update(data);

  cache.writeQuery({
    query,
    variables,
    data,
  });
};

let REMOVE_HANDLING_PLAN_MUTATION = gql`
  mutation($token: String!, $id: ID!) {
    removeHandlingPlan(token: $token, _id: $id) {
      id
    }
  }
`;

let SET_BASIC_HANDLING_PLAN = gql`
  mutation($token: String!, $id: ID!) {
    setBasicHandlingPlan(token: $token, _id: $id) {
      _id
      title
      region
      enabled
      isBasic
      suppliers
    }
  }
`;

let UPDATE_HANDLING_PLAN_MUTATION = gql`
  mutation($token: String!, $id: ID!, $data: HandlingPlanInput!) {
    updateHandlingPlan(token: $token, _id: $id, data: $data) {
      ...HandlingPlanFragment
    }
  }
  ${HandlingPlanFragment}
`;

let HandlingPlans = ({ classes }) => {
  let [searchQuery, set_searchQuery] = React.useState("");

  let getFilteredHandlingPlans = handlingPlans => {
    return handlingPlans
      .filter(plan => {
        const parsedTitle = plan.title.toLowerCase();
        const parsedSearchQuery = searchQuery.toLowerCase();

        return parsedTitle.includes(parsedSearchQuery);
      })
      .sort(handlingPlanA => {
        return handlingPlanA.isBasic === true ? -1 : 0;
      });
  };

  let [removeHandlingPlanMutation] = useMutation(
    REMOVE_HANDLING_PLAN_MUTATION,
    {
      update: (cache, { data }) => {
        const handlingPlanId = data.removeHandlingPlan.id;
        updateQuery({
          cache: cache,
          query: HANDLING_PLANS_LIST_QUERY,
          variables: { token: getToken() },
          update: data => {
            data.handlingPlans = data.handlingPlans.filter(
              plan => plan._id !== handlingPlanId
            );
          },
        });
        updateQuery({
          cache: cache,
          query: COMPANIES_QUERY,
          variables: { token: getToken() },
          update: data => {
            const { companies = [] } = data;
            data.companies = companies.map(company => {
              const batches = company.batches.map(batch => {
                return {
                  ...batch,
                  items: batch.items.filter(
                    ({ todo = {} }) => todo._id !== handlingPlanId
                  ),
                };
              });
              const topics = company.topics.map(topic => {
                return {
                  ...topic,
                  todos: topic.todos.filter(
                    ({ todo = {} }) => todo._id !== handlingPlanId
                  ),
                };
              });
              return {
                ...company,
                batches,
                topics,
              };
            });
          },
        });
      },
    }
  );
  let removeHandlingPlan = ({ _id, title }) => {
    if (!window.confirm(`Are you sure you want to delete: ${title}`)) {
      return;
    }

    removeHandlingPlanMutation({
      optimisticResponse: {
        removeHandlingPlan: {
          __typename: "RemoveResult",
          id: _id,
        },
      },
      variables: {
        token: getToken(),
        id: _id,
      },
    });
  };

  let [setBasicHandlingPlanMutation] = useMutation(SET_BASIC_HANDLING_PLAN);
  let setBasicHandlingPlan = ({ _id, title }) => {
    if (
      // prettier-ignore
      !window.confirm(`Are you sure you want to set to do: ${title} as basic? This will replace the current basic to do.`)
    ) {
      return;
    }

    setBasicHandlingPlanMutation({
      variables: {
        token: getToken(),
        id: _id,
      },
    });
  };

  let [
    updateHandlingPlanMutation,
    { loading: handlingPlanUpdateLoading },
  ] = useMutation(UPDATE_HANDLING_PLAN_MUTATION);
  let toggleEnabled = handlingPlan => {
    updateHandlingPlanMutation({
      variables: {
        token: getToken(),
        id: handlingPlan._id,
        data: { enabled: !handlingPlan.enabled },
      },
    });
  };

  let {
    data: { handlingPlans },
  } = useQuery(HANDLING_PLANS_LIST_QUERY, {
    variables: { token: getToken() },
    suspend: true,
  });

  return (
    <Page>
      <Container>
        <PageHeader>
          <PageTitle>
            <h2>To do's</h2>
            <Button
              variant="contained"
              size="small"
              component={NavLinkAdapter}
              to="/todos/create"
            >
              Add
            </Button>
          </PageTitle>

          <TextField
            label="Search"
            variant="outlined"
            type="search"
            autoFocus
            value={searchQuery}
            onChange={e => set_searchQuery(e.target.value)}
          />
        </PageHeader>

        <Table style={{ background: "white" }}>
          <TableHead>
            <TableRow>
              <TableCell />
              {/* edit buttons */}
              <TableCell>Title</TableCell>
              <TableCell>Region</TableCell>
              <TableCell />
              {/* enabled toggles */}
              <TableCell />
              {/* basic buttons */}
              <TableCell />
              {/* delete buttons */}
            </TableRow>
          </TableHead>

          <TableBody>
            {getFilteredHandlingPlans(handlingPlans).map(handlingPlan => (
              <TableRow className={classes.tableRow} key={handlingPlan._id}>
                <TableCell style={{ width: 50 }}>
                  <IconButton
                    aria-label="edit"
                    component={NavLinkAdapter}
                    to={`/todos/${handlingPlan._id}`}
                  >
                    <PhosphorIcon name="pencil" />
                  </IconButton>
                </TableCell>

                <TableCell>
                  {handlingPlan.title}{" "}
                  {handlingPlan.isBasic && (
                    <Chip label="Basic" color="primary" />
                  )}
                </TableCell>

                <TableCell>{handlingPlan.region}</TableCell>

                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={handlingPlan.enabled}
                        onChange={() => toggleEnabled(handlingPlan)}
                        disabled={handlingPlanUpdateLoading}
                        value="enabled"
                        color="primary"
                      />
                    }
                    label="Enabled"
                    className="input input__switch"
                  />
                </TableCell>
                <TableCell style={{ width: 80 }}>
                  <Button
                    onClick={() => setBasicHandlingPlan(handlingPlan)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    className="basicButton"
                  >
                    Basic
                  </Button>
                </TableCell>
                <TableCell style={{ width: 50 }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeHandlingPlan(handlingPlan)}
                  >
                    <PhosphorIcon name="trash" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Page>
  );
};

export default withStyles(styles)(HandlingPlans);
