import React from "react";

export default (
  <svg style={{ width: "100%" }} viewBox="0 0 125 149">
    <title>ubutler-logo</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <polygon
        id="path-1"
        points="0 0.24 124.704 0.24 124.704 148.94 0 148.94"
      ></polygon>
    </defs>
    <g
      id="ubutler-logo"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="uButler-icon-white">
        <g id="Group-3">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <g id="Clip-2"></g>
          <path
            d="M124.704,0.24 L124.704,87.278 C124.704,123.042 98.458,149 62.295,149 C26.199,149 0,123.042 0,87.278 L0,0.24 L16.527,0.24 L16.527,87.278 C16.527,113.888 35.348,132.473 62.295,132.473 C89.31,132.473 108.177,113.888 108.177,87.278 L108.177,0.24 L124.704,0.24"
            id="Fill-1"
            fill="currentColor"
            className="fill"
            mask="url(#mask-2)"
          ></path>
        </g>
        <path
          d="M30.026,32.355 C29.808,32.355 29.591,32.292 29.402,32.171 C29.074,31.957 28.877,31.596 28.877,31.205 L28.877,7.491 C28.877,7.1 29.074,6.739 29.402,6.525 C29.728,6.314 30.138,6.283 30.495,6.442 L56.958,18.3 C57.371,18.484 57.638,18.895 57.638,19.348 C57.638,19.801 57.371,20.212 56.958,20.396 L30.495,32.254 C30.345,32.321 30.185,32.355 30.026,32.355"
          id="Fill-4"
          className="fill"
          fill="currentColor"
        ></path>
        <path
          d="M94.678,32.355 C94.519,32.355 94.359,32.321 94.209,32.254 L67.675,20.396 C67.261,20.212 66.994,19.801 66.994,19.348 C66.994,18.895 67.261,18.484 67.675,18.3 L94.209,6.442 C94.566,6.283 94.974,6.314 95.304,6.525 C95.63,6.739 95.827,7.102 95.827,7.491 L95.827,31.205 C95.827,31.594 95.63,31.957 95.304,32.171 C95.114,32.292 94.896,32.355 94.678,32.355"
          id="Fill-5"
          className="fill"
          fill="currentColor"
        ></path>
        <path
          d="M62.295,120.108 C42.307,120.108 28.878,106.379 28.878,85.947 L28.878,42.858 L48.673,42.858 L48.673,85.819 C48.673,95.654 53.511,101.071 62.295,101.071 C71.282,101.071 76.032,95.797 76.032,85.819 L76.032,42.858 L95.827,42.858 L95.827,85.947 C95.827,106.379 82.351,120.108 62.295,120.108"
          id="Fill-6"
          className="fill"
          fill="currentColor"
        ></path>
      </g>
    </g>
  </svg>
);
