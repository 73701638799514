import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Icon } from "./Icon";
import icomoonConfig from "./assets/icomoonConfig";

const icons = icomoonConfig.icons.map(icon => icon.properties.name);

export const IconSelector = ({
  value = "",
  onChange,
  required = false,
  style,
}) => (
  <div style={{ display: "flex", alignItems: "center", ...style }}>
    {value !== "" && (
      <Icon name={value} size={32} style={{ marginRight: 16 }} />
    )}

    <FormControl variant="filled" style={{ width: "100%" }} required={required}>
      <InputLabel>Icon</InputLabel>
      <Select value={value} onChange={onChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {icons.map(icon => (
          <MenuItem key={icon} value={icon}>
            {icon}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);
