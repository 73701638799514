import { useContext } from "react";

import { SortableTableRowContext } from "./SortableTableRowProvider";

const useSortableRow = () => {
  const context = useContext(SortableTableRowContext);

  if (context === undefined) {
    throw new Error(
      "useSortableRow must be used within a SortableTableRowContext"
    );
  }

  return context;
};

export default useSortableRow;
