import React from "react";
import immer from "immer";
import styled from "styled-components";

import { FormLabel as StyledFormLabel } from "./Styled/FormLabel";
import { Input as StyledInput } from "./Styled/Input";

export const DurationForm = ({
  duration,
  onChangeDuration,
  showDays = null,
}) => {
  const update = (attribute, value) => {
    onChangeDuration(
      immer(duration, draft => {
        draft[attribute] = value;
      })
    );
  };

  return (
    <div style={{ display: "flex" }}>
      {showDays && (
        <FormLabel light style={{ marginRight: 30 }}>
          <Input
            value={duration.days}
            type="number"
            min="0"
            onChange={e => update("days", e.target.value)}
            placeholder="0"
          />
          Days
        </FormLabel>
      )}

      <FormLabel light style={{ marginRight: 30 }}>
        <Input
          value={duration.hours}
          type="number"
          min="0"
          onChange={e => update("hours", e.target.value)}
          placeholder="0"
        />
        Hours
      </FormLabel>

      <FormLabel light>
        <Input
          value={duration.minutes}
          type="number"
          min="0"
          onChange={e => update("minutes", e.target.value)}
          placeholder="0"
        />
        Min
      </FormLabel>
    </div>
  );
};

const FormLabel = styled(StyledFormLabel)`
  display: flex;
  align-items: center;
  font-size: 13px;
`;

const Input = styled(StyledInput)`
  width: 36px;
  margin-right: 8px;

  // Remove number input buttons
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
`;
