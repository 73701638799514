import IconButton from "@material-ui/core/IconButton";
import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import ReactSelect from "react-select";
import styled from "styled-components/macro";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

const List = styled(MuiList)`
  padding-top: 0 !important;
  margin-bottom: 30px !important;
`;

const ListItem = styled(MuiListItem)`
  border-bottom: 1px solid hsl(164, 9%, 72%);
`;

const Select = styled(ReactSelect)`
  z-index: 10;
`;

export default class MultiSelect extends React.Component {
  get options() {
    const {
      options,
      optionValueAttribute,
      optionLabelAttribute,
      selectedOptions,
    } = this.props;

    return options
      .filter(option => {
        return !selectedOptions.find(
          selected =>
            selected[optionValueAttribute] === option[optionValueAttribute]
        );
      })
      .map(option => ({
        value: option[optionValueAttribute],
        label: option[optionLabelAttribute],
      }));
  }

  onChange = selectedOption => {
    const { options, optionValueAttribute } = this.props;

    const chosenOption = options.find(option => {
      return option[optionValueAttribute] === selectedOption.value;
    });

    this.props.onUpdate(chosenOption);
  };

  render() {
    const {
      selectedOptions,
      optionLabelAttribute,
      optionValueAttribute,
    } = this.props;

    return (
      <React.Fragment>
        {selectedOptions.length > 0 && (
          <List>
            {selectedOptions.map(option => (
              <ListItem key={option[optionValueAttribute]}>
                <ListItemText primary={option[optionLabelAttribute]} />

                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="delete"
                    onClick={() => this.props.onDelete(option)}
                  >
                    <PhosphorIcon name="trash" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}

        <Select options={this.options} onChange={this.onChange} />
      </React.Fragment>
    );
  }
}
