import React from "react";
import dompurify from "dompurify";
import styled from "styled-components/macro";
import { TextField } from "@material-ui/core";

import { HandlingPlanPhases, StepTypes } from "./constants";
import { QuestionsStepForm } from "./StepTypes/QuestionsStepForm";
import { TextInfo } from "./Sections/OldHandling";

export const OldStepsForm = ({ steps: propsSteps }) => {
  const phases = [HandlingPlanPhases.PROPOSAL, HandlingPlanPhases.HANDLING];

  const getStepsByPhase = (phase, steps) => {
    return steps.filter(step => step.phase === phase);
  };

  return (
    <React.Fragment>
      {phases.map(phase => {
        const steps = getStepsByPhase(phase, propsSteps);

        return (
          <Phase key={phase}>
            <h4 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              {phase}
            </h4>

            <div>
              {steps.map((step, index) => (
                <Step key={index}>
                  <div style={{ position: "absolute", left: -45 }}>
                    <span style={{ fontSize: "20px" }}>{index + 1}.</span>
                  </div>

                  <StepTitle>
                    <TextField
                      label="Title"
                      value={step.title}
                      variant="filled"
                      fullWidth={true}
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </StepTitle>

                  {step.type === StepTypes.TEXT && (
                    <TextInfo
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(step.body),
                      }}
                    />
                  )}

                  {step.type === StepTypes.WAYPOINT && (
                    <div>A waypoint step</div>
                  )}
                  {step.type === StepTypes.QUESTIONS && (
                    <QuestionsStepForm step={step} />
                  )}
                </Step>
              ))}
            </div>
          </Phase>
        );
      })}
    </React.Fragment>
  );
};

const Phase = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Step = styled.div`
  padding: 0 20px 20px 20px;
  border: 1px solid hsl(164, 4%, 74%);
  border-radius: 4px;
  margin-bottom: 40px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StepTitle = styled.div`
  width: calc(100% + 40px); // Adds step padding
  padding: 10px 20px;
  border-bottom: 1px solid hsl(164, 4%, 74%);
  margin-bottom: 20px;
  position: relative;
  left: -20px;
`;
