import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DeleteButton } from "./DeleteButton";

export const InputList = ({ values, onChange, addButtonText = "Add" }) => {
  const list = useRef(null);

  const [rowAdded, set_rowAdded] = useState(false);

  // Focus newly added input.
  useEffect(() => {
    if (!rowAdded) return;

    const inputs = list.current.querySelectorAll(".input__text input");
    inputs[inputs.length - 1].focus();

    set_rowAdded(false);
  }, [values]);

  const addRow = useCallback(() => {
    onChange([...values, ""]);
    set_rowAdded(true);
  });

  return (
    <div style={{ width: "100%" }} ref={list}>
      {values.map((row, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: 12 }}
        >
          <DeleteButton
            style={{ marginRight: 8 }}
            key={row}
            onClick={() => {
              let newValues = [...values];
              newValues.splice(index, 1);
              onChange(newValues);
            }}
          />
          <TextField
            className="input input__text"
            fullWidth={true}
            variant="filled"
            size="small"
            value={row}
            onChange={e => {
              let newValues = [...values];
              newValues[index] = e.target.value;
              onChange(newValues);
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                e.preventDefault();
                addRow();
              }
            }}
          />
        </div>
      ))}

      <Button
        variant="outlined"
        size="small"
        style={{ marginLeft: 22 }}
        onClick={addRow}
      >
        {addButtonText}
      </Button>
    </div>
  );
};
