import React from "react";
import { useMutation } from "../Graphql";
import { useHistory } from "react-router-dom";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { getToken } from "../constants";
import { StoryEditOrCreateForm } from "../Components/Stories/StoryEditOrCreateForm";
import { ADD_STORY_MUTATION } from "../Graphql/mutations";
import { STORIES_LIST_QUERY } from "../Graphql/queries";

export const AddStory = () => {
  const history = useHistory();

  const [
    addStoryMutation,
    { loading: loadingAdd, called: calledAdd },
  ] = useMutation(ADD_STORY_MUTATION);

  const handleSubmit = async submitData => {
    await addStoryMutation({
      variables: {
        token: getToken(),
        data: {
          connectedRegions: submitData.story.connectedRegions,
          isActive: submitData.story.isActive,
          title: submitData.story.title,
          thumbnailFileName: submitData.story.thumbnailFileName,
          pages: submitData.story.pages.map(page => {
            return {
              _id: page._id,
              alignment: page.alignment,
              isActive: page.isActive,
              fileName: page.fileName,
            };
          }),
        },
      },
      update: (cache, { data }) => {
        const addStory = data.addStory;

        const { stories } = cache.readQuery({
          query: STORIES_LIST_QUERY,
          variables: { token: getToken() },
        });

        cache.writeQuery({
          query: STORIES_LIST_QUERY,
          variables: { token: getToken() },
          data: {
            stories: [addStory, ...stories],
          },
        });
      },
    });

    history.push("/stories", { createSuccessful: true });
  };

  return (
    <Page>
      <Container>
        <PageHeader>
          <PageTitle>
            <h2>New story</h2>
          </PageTitle>
        </PageHeader>

        <StoryEditOrCreateForm
          onSubmit={handleSubmit}
          isDisabled={loadingAdd || calledAdd}
        />
      </Container>
    </Page>
  );
};
