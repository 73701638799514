import React from "react";
import styled from "styled-components/macro";
import immer from "immer";

import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { DeleteButton } from "../DeleteButton";
import { Whitespace } from "../Elements.js";
import { useDebouncedUpdate } from "../../useGeneric.js";
import { HandlingplanSearch } from "../HandlingplanSearch.js";

let TopicTitle = styled.div`
  font-size: 20px;
`;

let EditTodo = ({ value, onChange, onDelete }) => {
  let [local_todo, _, set_local_todo] = useDebouncedUpdate({
    value: value,
    delay: 1000,
    onUpdate: value => {
      onChange(value);
    },
  });

  let policy = local_todo.policy || { title: "", description: "" };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          flex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DeleteButton
            style={{ margin: "0 16px 0 6px" }}
            onClick={() => onDelete(local_todo)}
          />
          <HandlingplanSearch
            style={{ width: "100%" }}
            label="Linked todo"
            onChange={(event, value) => {
              if (value != null) {
                set_local_todo({
                  ...value,
                  todo: value,
                });
              }
            }}
            value={local_todo.todo}
          />
        </div>

        <Whitespace height={8} />

        <FormControlLabel
          key="chat"
          control={<Switch checked={true} readOnly value="Handle" />}
          label="Chat"
        />

        <Whitespace height={8} />

        <FormControlLabel
          key="handle"
          control={
            <Switch
              checked={local_todo.handle}
              onChange={({ target }) => {
                set_local_todo(
                  immer(todo => {
                    todo.handle = target.checked;
                  })
                );
              }}
              value="Handle"
            />
          }
          label="Handle"
        />
        {local_todo.handle === false && (
          <div key="handle-instructions">
            <TextField
              label="Handle instructions"
              variant="filled"
              type="text"
              multiline
              rows={3}
              style={{ width: "100%" }}
              value={local_todo.handleInstructions}
              onChange={({ target }) => {
                set_local_todo(
                  immer(todo => {
                    todo.handleInstructions = target.value;
                  })
                );
              }}
            />
          </div>
        )}
      </div>

      <Whitespace width={16} />

      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <TextField
          label="Policy title"
          variant="filled"
          type="text"
          autoFocus
          value={policy.title}
          onChange={e => {
            let title = e.target.value;
            set_local_todo(
              immer(todo => {
                todo.policy = { ...policy, title };
              })
            );
          }}
        />

        <Whitespace height={8} />
        <TextField
          label="Policy message"
          variant="filled"
          type="text"
          multiline
          rows={3}
          value={policy.description}
          onChange={e => {
            let description = e.target.value;
            set_local_todo(
              immer(todo => {
                todo.policy = { ...policy, description };
              })
            );
          }}
        />
      </div>
    </div>
  );
};

let CompanyTopic = ({ company_topic, onChange }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 16,
        alignItems: "stretch",
      }}
      key={company_topic._id}
    >
      <TopicTitle>{company_topic.topic.title}</TopicTitle>
      <Whitespace height={8} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        {company_topic.todos.map((todo, todo_index) => (
          <React.Fragment key={todo_index}>
            <EditTodo
              value={todo}
              onDelete={todo => {
                onChange(
                  immer(company_topic, topic => {
                    topic.todos = topic.todos.filter(
                      ({ _id }) => _id !== todo._id
                    );
                  })
                );
              }}
              onChange={new_todo => {
                onChange(
                  immer(company_topic, company_topic => {
                    company_topic.todos[todo_index] = new_todo;
                  })
                );
              }}
            />

            <Whitespace height={16} />
            <div
              style={{
                borderBottom: "solid 1px #919191",
                marginLeft: 30,
                marginRight: 30,
              }}
            />
            <Whitespace height={16} />
          </React.Fragment>
        ))}
        <HandlingplanSearch
          label={`Add todo to ${company_topic.topic.title}`}
          onChange={todo => {
            let new_company_topic = immer(company_topic, company_topic => {
              company_topic.todos.push({
                todo: todo,
                handle: true,
              });
            });
            onChange(new_company_topic);
          }}
          value={null}
        />
      </div>
    </div>
  );
};

export let CompanyTopics = ({ company, onChange }) => {
  return (
    <div>
      <div>
        Topics that don't have any todo's attached to them will not show on the
        knowledgebase
      </div>
      <Whitespace height={16} />

      {company.topics.map((company_topic, topic_index) => (
        <React.Fragment key={topic_index}>
          <CompanyTopic
            company_topic={company_topic}
            onChange={new_topic => {
              onChange(
                immer(company, company_draft => {
                  company_draft.topics[topic_index] = new_topic;
                })
              );
            }}
          />
          <Whitespace height={16} />
          <div style={{ borderBottom: "solid 1px #919191" }} />
          <Whitespace height={16} />
        </React.Fragment>
      ))}
    </div>
  );
};
