import React, { useState } from "react";
import styled from "styled-components";
import { StepNumber } from "../Steps/Step";
import { CardButton } from "../Steps/StepCard";
import { PhaseEditor } from "./PhaseEditor";
import PhosphorIcon from "../phosphor-icons/phosphor-icon";

const phaseTitleToReadableTitle = title =>
  title.replaceAll("_", "").toLowerCase();

export const PhasesList = ({
  checklistItems,
  onUpdatePhases,
  phases,
  routes,
}) => {
  const [editPhaseId, setEditPhaseId] = useState(null);

  const handleSavePhase = async (phase, phases) => {
    let newPhases = phases;
    const phaseIndex = newPhases.findIndex(p => p._id === phase._id);
    if (phaseIndex !== -1) {
      newPhases[phaseIndex] = phase;
    } else {
      newPhases.push(phase);
    }
    onUpdatePhases(newPhases);
    setEditPhaseId(null);
  };

  return (
    <div>
      {phases.map((phase, index) => {
        return (
          <PhaseCard key={phase.title}>
            <StyledStepNumber>{index + 1}</StyledStepNumber>

            <PhaseContent>
              <PhaseTitleContainer>
                <Title>
                  {`Phase ${index + 1}: ${phaseTitleToReadableTitle(
                    phase.title
                  )}`}
                </Title>

                <Buttons>
                  {!editPhaseId && (
                    <CardButton onClick={() => setEditPhaseId(phase._id)}>
                      edit <PhosphorIcon name="pencil" />
                    </CardButton>
                  )}
                </Buttons>
              </PhaseTitleContainer>

              {editPhaseId === phase._id && (
                <PhaseEditor
                  checklistItems={checklistItems}
                  onSave={phase => handleSavePhase(phase, phases)}
                  phase={phase}
                  routes={routes}
                />
              )}
            </PhaseContent>
          </PhaseCard>
        );
      })}
    </div>
  );
};

const PhaseCard = styled.div`
  padding: 25px;
  background: white;
  display: flex;
  align-items: top;
  gap: 20px;
  margin-bottom: 14px;
`;

const PhaseContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;
`;

const PhaseTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
`;

const StyledStepNumber = styled(StepNumber)`
  margin: 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
`;
