import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import emoji from "node-emoji";
import { Whitespace, Select, NumberInput } from "../Elements.js";
import { MarkupTextArea } from "../MarkupTextArea";
import { ROADMAP_TYPE } from "../Company/CompanyRoadmap";

const DescriptionContainer = styled.div`
  max-height: 500px;
  padding-right: 8px;
  margin-bottom: 10px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const Subtitle = styled.div`
  font-size: 13px;
  opacity: 0.8;
  padding-bottom: 8px;
`;

export const UNDISCLOSED_BUCKET = "__UNDISCLOSED_BUCKET__";

export const Description = ({
  allowances = [],
  todo,
  onTodoChange,
  roadmapType,
}) => {
  const {
    allowanceId,
    personalMessage,
    dismiss_reason,
    from,
    remindedAt,
    status,
    till,
    times_usable,
    times_used,
  } = todo;
  const todoObj = {
    city: todo.item?.city ?? null,
    region: todo.todo?.region ?? null,
  };

  const [message, set_message] = useState(personalMessage); // personal message use React Quill. React Quill doesn't play nice with debounce (used in onTodoChange) so it has a local state and only update the onTodoChange onBlur
  const allowancesList = [{ title: "No credit", value: null }];

  for (const allowance of allowances) {
    allowancesList.push({
      title:
        allowance.title === UNDISCLOSED_BUCKET
          ? "Undisclosed bucket"
          : allowance.undisclosed === true
            ? `${allowance.title} (undisclosed)`
            : allowance.title,
      value: allowance._id,
    });
  }

  return (
    <DescriptionContainer>
      <Subtitle>
        {roadmapType === ROADMAP_TYPE.PERSONAL
          ? `${moment(from).format("D MMMM YYYY")} to ${moment(till).format(
              "D MMMM YYYY"
            )}`
          : `${moment(from).format("D MMMM")} to ${moment(till).format(
              "D MMMM"
            )}`}
      </Subtitle>

      <p>
        <strong>City: </strong>
        {todoObj.region || todoObj.city}
      </p>

      {dismiss_reason && (
        <p>
          <strong>Dismiss reason: </strong>
          {dismiss_reason}
        </p>
      )}

      {remindedAt && (
        <p>
          <strong>Reminded at: </strong>
          {moment(remindedAt).format("DD-MM-YYYY HH:mm")}
        </p>
      )}

      <Whitespace height={8} />
      <SelectContainer>
        <div>
          <Select
            label="Status"
            value={status}
            onChange={event => {
              onTodoChange({
                ...todo,
                status: event.target.value,
              });
            }}
            items={[
              { title: "Active", value: "ACTIVE" },
              { title: "Redeemed", value: "REDEEMED" },
              { title: "Dismissed", value: "DISMISSED" },
            ]}
          />
        </div>
        <Whitespace width={8} />
        <div>
          <NumberInput
            integer
            fullWidth={false}
            label="Times usable"
            value={times_usable}
            style={{ flex: 1 }}
            onChange={event => {
              onTodoChange({
                ...todo,
                times_usable: event.target.value,
              });
            }}
          />
        </div>
        <Whitespace width={8} />
        <div>
          <NumberInput
            label="Times used"
            integer
            fullWidth={false}
            value={times_used}
            style={{ flex: 1 }}
            onChange={event => {
              onTodoChange({
                ...todo,
                times_used: event.target.value,
              });
            }}
          />
        </div>
      </SelectContainer>

      {roadmapType === ROADMAP_TYPE.PERSONAL && (
        <>
          <Whitespace height={8} />
          <MarkupTextArea
            label="Personal message "
            value={message}
            onChange={markup => set_message(emoji.emojify(markup))}
            onBlur={() => {
              if (message === "<p><br></p>") {
                set_message(null);
              }
              onTodoChange({
                ...todo,
                personalMessage: message,
              });
            }}
          />
        </>
      )}

      <Whitespace height={8} />

      {roadmapType === ROADMAP_TYPE.PERSONAL && (
        <>
          <Select
            label="Credit bucket"
            value={allowanceId || null}
            onChange={event => {
              onTodoChange({
                ...todo,
                allowanceId: event.target.value,
              });
            }}
            items={allowancesList}
          />

          <Whitespace height={8} />
        </>
      )}
    </DescriptionContainer>
  );
};
