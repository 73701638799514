import config from "../config";

export const uploadFile = async (fileName, file, token) => {
  if (!fileName || !file || !token) {
    console.log("Invalid arguments");
    return false;
  }

  const formData = new FormData();
  formData.append("fileName", fileName);
  formData.append("file", file);

  try {
    const response = await fetch(
      `${config.backendHost}/file-upload`,
      {
        method: "POST",
        body: formData,
        headers: {
          authorization: token,
        },
      }
    );

    if (!response.ok) {
      return false;
    }
  } catch (error) {
    console.error("Error:", error);
    return false;
  }

  return true;
};
