import React from "react";
import { Mutation } from "react-apollo";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { TopicForm } from "../Components/Topics/TopicForm";
import { getToken } from "../constants";
import { CREATE_TOPIC_MUTATION } from "../Graphql/mutations";
import { TOPICS_LIST_QUERY } from "./Topics";

export class CreateTopic extends React.Component {
  createTopic = mutation => async topic => {
    await mutation({
      variables: {
        token: getToken(),
        data: topic,
      },
      refetchQueries: [
        {
          query: TOPICS_LIST_QUERY,
          variables: { token: getToken() },
        },
      ],
    });

    this.props.history.push("/topics");
  };

  render() {
    return (
      <Page>
        <Container>
          <PageHeader>
            <PageTitle>
              <h2>New Topic</h2>
            </PageTitle>
          </PageHeader>

          <Mutation mutation={CREATE_TOPIC_MUTATION}>
            {addTopicMutation => (
              <TopicForm
                onSubmit={this.createTopic(addTopicMutation)}
                buttonText="Create"
              />
            )}
          </Mutation>
        </Container>
      </Page>
    );
  }
}
