import React, { useEffect, useState } from "react";
import { FormGroup, MarkupInput } from "../Steps/StepForm";
import { gql, useMutation } from "../../Graphql";
import { useParams } from "react-router-dom";
import { getToken } from "../../constants";
import { Button, Switch } from "@material-ui/core";
import uuid from "uuid";
import emoji from "node-emoji";
import dompurify from "dompurify";
import Table from "../ComposableTable";
import { ClampedText } from "../ClampedText";
import {
  EditingBox,
  EditingBoxTitle,
  EditingFieldTitle,
  EditingError,
  EditingInput,
  EditingRequired,
  EditingBoxFormGroupRow,
} from "./EditingBox";
import { EditingActions } from "./EditingActions";
import { EditingFormGroupSubtitle } from "./EditingFormGroupSubtitle";

const UPDATE_ROUTES = gql`
  mutation updateTodoRoutes(
    $token: String!
    $todoId: String!
    $routes: [InputRoute!]!
  ) {
    updateTodoRoutes(token: $token, todoId: $todoId, routes: $routes) {
      _id
      handling {
        checklistItems {
          _id
          description
          route
          title
          isInactive
          geTitle
          geDescription
          tasks {
            _id
            title
            description
            isInactive
          }
        }
        routes {
          _id
          description
          title
          isInactive
          geTitle
          geDescription
        }
      }
      title
    }
  }
`;

export const RoutePhase = ({ checklistItems, routes, onEditingUpdate }) => {
  const [updateTodoRoutes] = useMutation(UPDATE_ROUTES);
  const { id: todoId } = useParams();
  const [localRoutes, setLocalRoutes] = useState(routes);
  const [error, setError] = useState();
  const [newRoute, setNewRoute] = useState();

  useEffect(() => {
    return () => {
      onEditingUpdate(false);
    };
  }, []);

  const createNewRoute = () => {
    onEditingUpdate(true);
    setNewRoute({
      _id: uuid.v4(),
      description: "",
      title: "",
      isInactive: true,
      geTitle: "",
      geDescription: "",
    });
  };

  const handleDeleteRoute = async id => {
    const result = window.confirm("Are you sure?");

    if (!result) {
      return;
    }

    const updatedRoutes = localRoutes.filter(({ _id }) => _id !== id);

    await updateRoutes(updatedRoutes);
  };

  const handleSaveNewRoute = async () => {
    if (
      typeof newRoute.title !== "string" ||
      newRoute.title.replace(/\s*/, "") === ""
    ) {
      return setError("Route title cannot be empty");
    }

    let updatedRoutes = [...localRoutes] ?? [];
    const idx = updatedRoutes.findIndex(route => route._id === newRoute._id);
    if (idx > -1) {
      updatedRoutes[idx] = newRoute;
    } else {
      updatedRoutes.push(newRoute);
    }

    await updateRoutes(updatedRoutes);

    onEditingUpdate(false);
    setError(null);
    setNewRoute(null);
  };

  const handleEdit = route => {
    onEditingUpdate(true);
    setNewRoute(route);
  };

  const handleSortItems = async result => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const updatedRoutes = Array.isArray(routes) ? [...routes] : [];
    const [removed] = updatedRoutes.splice(result.source.index, 1);
    updatedRoutes.splice(result.destination.index, 0, removed);

    await updateRoutes(updatedRoutes);
  };

  const updateRoutes = async updatedRoutes => {
    setLocalRoutes(updatedRoutes);

    // strip any fields that shouldn't be a part of the mutation, like __fieldName
    const routes = updatedRoutes.map(
      ({ _id, description, title, isInactive, geTitle, geDescription }) => ({
        _id,
        description,
        title,
        isInactive,
        geTitle: geTitle !== "" ? geTitle : null,
        geDescription: geDescription !== "" ? geDescription : null,
      })
    );

    await updateTodoRoutes({
      variables: { routes, todoId, token: getToken() },
    });
  };

  return (
    <div>
      {localRoutes.length > 0 && !newRoute ? (
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell />
              <Table.Cell>Title</Table.Cell>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>Connected checklist items</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          </Table.Head>

          <Table.SortableBody onSort={handleSortItems}>
            {localRoutes.map((route, index) => {
              const connectedChecklistItems = checklistItems.filter(
                item => item.route === route._id
              );
              return (
                <Table.SortableRow
                  key={route._id}
                  draggableId={route._id}
                  index={index}
                  highlightColor="#fafafa"
                >
                  <Table.SortHandleCell width="30px" />
                  <Table.Cell width="3000px">{route.title}</Table.Cell>
                  <Table.Cell width="4000px">
                    <ClampedText linesToClamp={1}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(route.description),
                        }}
                      ></div>
                    </ClampedText>
                  </Table.Cell>
                  <Table.Cell width="4000px">
                    {`${connectedChecklistItems.length ?? 0} checklist item${
                      connectedChecklistItems.length === 1 ? "" : "s"
                    } connected`}
                  </Table.Cell>
                  <Table.Cell>
                    <Switch
                      onChange={e => {
                        const updatedRoutes = [...localRoutes];
                        updatedRoutes[index] = {
                          ...updatedRoutes[index],
                          isInactive: !e.target.checked,
                        };
                        updateRoutes(updatedRoutes);
                      }}
                      value={!route.isInactive}
                      checked={!route.isInactive}
                      color="primary"
                    />
                  </Table.Cell>
                  <Table.ActionCell
                    onClick={() => handleEdit(route)}
                    iconName="pencil"
                  />
                  <Table.ActionCell
                    onClick={() => handleDeleteRoute(route._id)}
                    iconName="trash"
                  />
                </Table.SortableRow>
              );
            })}
          </Table.SortableBody>
        </Table>
      ) : null}

      <div>
        {newRoute ? (
          <EditingBox>
            <EditingBoxTitle>Editing route</EditingBoxTitle>

            <EditingBoxFormGroupRow>
              <FormGroup noPadding>
                <EditingFieldTitle>
                  Title - Visible in portal{" "}
                  <EditingRequired>* required</EditingRequired>
                </EditingFieldTitle>

                <EditingInput
                  onChange={e =>
                    setNewRoute({ ...newRoute, title: e.target.value })
                  }
                  placeholder="Title"
                  type="text"
                  value={newRoute.title}
                />

                <EditingFormGroupSubtitle />

                {error ? <EditingError>{error}</EditingError> : null}
              </FormGroup>
              <FormGroup noPadding>
                <EditingFieldTitle>Title - GEs</EditingFieldTitle>

                <EditingInput
                  onChange={e =>
                    setNewRoute({ ...newRoute, geTitle: e.target.value })
                  }
                  placeholder="Title"
                  type="text"
                  value={newRoute.geTitle ?? ""}
                />

                <EditingFormGroupSubtitle isGEField />
              </FormGroup>
            </EditingBoxFormGroupRow>

            <EditingBoxFormGroupRow>
              <FormGroup noPadding>
                <EditingFieldTitle>
                  Description - Visible in portal
                </EditingFieldTitle>

                <MarkupInput
                  value={newRoute.description}
                  onChange={newValue =>
                    setNewRoute({
                      ...newRoute,
                      description: emoji.emojify(newValue),
                    })
                  }
                  placeholder="Description"
                />

                <EditingFormGroupSubtitle />
              </FormGroup>

              <FormGroup noPadding>
                <EditingFieldTitle>Description - GEs</EditingFieldTitle>

                <MarkupInput
                  value={newRoute.geDescription ?? ""}
                  onChange={newValue =>
                    setNewRoute({
                      ...newRoute,
                      geDescription: emoji.emojify(newValue),
                    })
                  }
                  placeholder="Description"
                />

                <EditingFormGroupSubtitle isGEField />
              </FormGroup>
            </EditingBoxFormGroupRow>

            <EditingActions
              onCancel={() => {
                onEditingUpdate(false);
                setNewRoute(null);
              }}
              onConfirm={handleSaveNewRoute}
              confirmLabel="Save route"
            />
          </EditingBox>
        ) : (
          <Button
            size="small"
            variant="outlined"
            onClick={() => createNewRoute()}
            style={{ margin: "10px 10px 0 0" }}
          >
            Add route
          </Button>
        )}
      </div>
    </div>
  );
};
