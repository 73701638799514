import React from "react";
import { Mutation, Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import Container from "../Components/Styled/Container";
import Page from "../Components/Styled/Page";
import PageHeader from "../Components/Styled/PageHeader";
import PageTitle from "../Components/Styled/PageTitle";
import { HandlingPlanForm } from "../Components/HandlingPlans/HandlingPlanForm";
import { getToken } from "../constants";
import { CREATE_HANDLING_PLAN_MUTATION } from "../Graphql/mutations";
import {
  HANDLING_PLANS_LIST_QUERY,
  SUPPLIERS_LIST_QUERY,
} from "../Graphql/queries";
import mapSupplier from "../mappers/mapSupplier";

class CreateHandlingPlan extends React.Component {
  createHandlingPlan = mutation => async handlingPlan => {
    const {
      data: { addHandlingPlan },
    } = await mutation({
      variables: {
        token: getToken(),
        data: handlingPlan,
      },
      update(cache, { data: { addHandlingPlan: newHandlingPlan } }) {
        try {
          const { handlingPlans } = cache.readQuery({
            query: HANDLING_PLANS_LIST_QUERY,
            variables: { token: getToken() },
          });
          cache.writeQuery({
            query: HANDLING_PLANS_LIST_QUERY,
            variables: { token: getToken() },
            data: { handlingPlans: handlingPlans.concat([newHandlingPlan]) },
          });
        } catch (e) {
          // There is no cache to update since someone directly went to this page.
        }
      },
    });

    if (addHandlingPlan._id) {
      this.props.history.push(`/todos/${addHandlingPlan._id}`);
    }
  };

  render() {
    return (
      <Page>
        <Container>
          <PageHeader>
            <PageTitle>
              <h2>New to do</h2>
            </PageTitle>
          </PageHeader>

          <Query query={SUPPLIERS_LIST_QUERY} variables={{ token: getToken() }}>
            {({ loading: loadingSuppliers, data: suppliersQuery }) => {
              if (loadingSuppliers === true) return <p>Loading!</p>;
              const suppliers = suppliersQuery.allSuppliers.map(supplier =>
                mapSupplier(supplier)
              );

              return (
                <Mutation mutation={CREATE_HANDLING_PLAN_MUTATION}>
                  {addHandlingPlanMutation => (
                    <HandlingPlanForm
                      tabs={["info"]}
                      suppliers={suppliers}
                      onSubmit={this.createHandlingPlan(
                        addHandlingPlanMutation
                      )}
                      buttonText="Create"
                    />
                  )}
                </Mutation>
              );
            }}
          </Query>
        </Container>
      </Page>
    );
  }
}

export default withRouter(CreateHandlingPlan);
