import gql from "graphql-tag";
import {
  ClientFragment,
  SupplierFullFragment,
  CoreStoryFields,
} from "./fragments";

export const SET_TIMELINE_MUTATION = gql`
  mutation($token: String!, $clientId: ID!, $batches: [InputBatches!]!) {
    admin_set_client_timeline(
      token: $token
      clientId: $clientId
      batches: $batches
    ) {
      ...ClientFragment
    }
  }
  ${ClientFragment}
`;

export const CREATE_HANDLING_PLAN_MUTATION = gql`
  mutation($token: String!, $data: AddHandlingPlanInput!) {
    addHandlingPlan(token: $token, data: $data) {
      _id
      title
      enabled
      suppliers
    }
  }
`;

export const REMOVE_SUPPLIER_MUTATION = gql`
  mutation($token: String!, $id: ID!) {
    removeSupplier(token: $token, _id: $id) {
      id
    }
  }
`;

export const ADD_SUPPLIER_MUTATION = gql`
  mutation($token: String!, $supplier: InputSupplier!) {
    adminAddSupplier(token: $token, supplier: $supplier) {
      ...SupplierFullFragment
    }
  }
  ${SupplierFullFragment}
`;

export const UPDATE_SUPPLIER_MUTATION = gql`
  mutation($token: String!, $_id: String!, $supplier: InputSupplier!) {
    adminUpdateSupplier(token: $token, _id: $_id, supplier: $supplier) {
      ...SupplierFullFragment
    }
  }
  ${SupplierFullFragment}
`;

export const CREATE_TAG_MUTATION = gql`
  mutation($token: String!, $name: String!) {
    addTags(token: $token, name: $name) {
      _id
      name
      createdBy
    }
  }
`;

export const UPDATE_TAG_MUTATION = gql`
  mutation($token: String!, $_id: String!, $name: String!) {
    tagsUpdate(token: $token, _id: $_id, name: $name) {
      _id
      name
      createdBy
    }
  }
`;

export const REMOVE_TAG_MUTATION = gql`
  mutation($token: String!, $id: String!) {
    removeTags(token: $token, id: $id) {
      id
      success
    }
  }
`;

export const CREATE_TOPIC_MUTATION = gql`
  mutation($token: String!, $data: AddTopicInput!) {
    addTopic(token: $token, data: $data) {
      _id
      title
      icon
      category
      weight
    }
  }
`;

export const ADD_STORY_MUTATION = gql`
  mutation($token: String!, $data: InputStory!) {
    addStory(token: $token, data: $data) {
      ...CoreStoryFields
      thumbnailFileName
      pages {
        _id
        alignment
        isActive
        fileName
      }
    }
  }
  ${CoreStoryFields}
`;

export const UPDATE_STORY_MUTATION = gql`
  mutation($token: String!, $_id: ID!, $data: InputStory!) {
    updateStory(token: $token, _id: $_id, data: $data) {
      ...CoreStoryFields
      thumbnailFileName
      pages {
        _id
        alignment
        isActive
        fileName
      }
    }
  }
  ${CoreStoryFields}
`;

export const REMOVE_STORY_MUTATION = gql`
  mutation($token: String!, $_id: ID!) {
    removeStory(token: $token, _id: $_id) {
      id
      success
    }
  }
`;
