import { pickBy, isUndefined } from "lodash";

export default function mapSupplier(data) {
  const supplier = {
    _id: data._id,
    name: data.supplier,
  };

  // Filter out the undefined values.
  return pickBy(supplier, property => !isUndefined(property));
}
