import React from "react";
import omitDeep from "omit-deep-lodash";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import {
  Whitespace,
  VerticalList,
  TextInput,
  StyledNavLink,
  EmptyState,
  Card,
  Flex,
} from "../Elements.js";
import { Sidebar, SidebarLink } from "../PageElements.js";
import { getToken } from "../../constants";
import { SupplierEditPage } from "./SupplierEditPage.js";
import { SupplierCreatePage } from "./SupplierCreatePage.js";
import { SUPPLIER_QUERY, SUPPLIERS_QUERY } from "../../Graphql/queries";
import {
  ADD_SUPPLIER_MUTATION,
  UPDATE_SUPPLIER_MUTATION,
} from "../../Graphql/mutations";
import { applySearch, check_if_local_env } from "../../helpers.js";

import { Mutation, useQuery, useMutation } from "../../Graphql.js";
import PhosphorIcon from "../phosphor-icons/phosphor-icon.js";

let remove_typename = object => {
  return omitDeep(object, "__typename");
};

export let SuppliersPage = () => {
  let match = useRouteMatch();
  let [searchQuery, set_searchQuery] = React.useState("");

  let {
    data: { allSuppliers: suppliers },
  } = useQuery(SUPPLIERS_QUERY, {
    suspend: true,
    variables: { token: getToken() },
  });

  const filteredSuppliers = suppliers.filter(supplier =>
    applySearch({
      search: searchQuery,
      values: [
        supplier.supplier,
        supplier.city,
        ...supplier.tags.map(x => x.name),
      ],
    })
  );

  return (
    <Flex row style={{ height: "100%" }}>
      <Sidebar title="Suppliers">
        <Card.Content>
          <Flex row>
            <TextInput
              placeholder="Search suppliers"
              onChange={e => set_searchQuery(e.target.value)}
              value={searchQuery}
            />
            <Whitespace width={8} />
            <StyledNavLink style={{ padding: 8 }} to={`${match.url}/create`}>
              <PhosphorIcon name="plus" className="icon" />
            </StyledNavLink>
          </Flex>
          <Whitespace height={8} />
        </Card.Content>

        <VerticalList>
          {filteredSuppliers.map(supplier => (
            <SidebarLink
              to={`${match.url}/${supplier._id}`}
              key={supplier._id}
              loaclEnv={check_if_local_env()}
            >
              <SidebarLink.Title>{supplier.supplier}</SidebarLink.Title>
              <SidebarLink.Text>
                {supplier.city ? (
                  <span>city: {supplier.city}</span>
                ) : (
                  <span style={{ color: "gray" }}>No city specified</span>
                )}
              </SidebarLink.Text>
              <SidebarLink.Text>
                {supplier.tags?.length ? (
                  <span>tags: {supplier.tags.map(x => x.name).join(", ")}</span>
                ) : (
                  <span style={{ color: "gray" }}>No tags</span>
                )}
              </SidebarLink.Text>
            </SidebarLink>
          ))}
        </VerticalList>
      </Sidebar>

      <Flex column style={{ flex: 1, overflow: "auto" }}>
        <React.Suspense
          fallback={<EmptyState icon="cached">Loading</EmptyState>}
        >
          <Switch>
            <Route
              exact
              path={match.url}
              render={() => (
                <EmptyState icon="store">Select a supplier</EmptyState>
              )}
            />
            <Route path={`${match.path}/create`} component={SupplierCreate} />
            <Route
              path={`${match.path}/:supplier_id`}
              render={({
                match: {
                  params: { supplier_id },
                },
              }) => {
                let supplier = suppliers.find(
                  supplier => supplier._id === supplier_id
                );
                if (supplier == null) {
                  return <Redirect to={match.url} />;
                }
                return <SupplierEdit supplier_id={supplier_id} />;
              }}
            />
          </Switch>
        </React.Suspense>
      </Flex>
    </Flex>
  );
};

let SupplierCreate = () => {
  let [adminAddSupplierMutation] = useMutation(ADD_SUPPLIER_MUTATION);
  let history = useHistory();

  const weekdays_opening_times = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ].map(weekday => {
    return {
      name: weekday,
      isOpen: false,
      from: "00:00",
      till: "00:00",
    };
  });

  let saveSupplier = async newSupplier => {
    let { _id, ...item } = newSupplier;
    let result = await adminAddSupplierMutation({
      variables: { supplier: item, token: getToken() },
      update: (cache, { data: { adminAddSupplier } }) => {
        // Read the data from our cache for this query.
        const data = cache.readQuery({
          query: SUPPLIERS_QUERY,
          variables: { token: getToken() },
        });
        // Add new supplier from the mutation to the end.
        data.allSuppliers.push(adminAddSupplier);
        // Write our data back to the cache.
        cache.writeQuery({
          query: SUPPLIERS_QUERY,
          variables: { token: getToken() },
          data: data,
        });
      },
    });
    let created_id = result.data.adminAddSupplier._id;
    history.push(`/suppliers/${created_id}`);
  };

  return (
    <Mutation mutation={ADD_SUPPLIER_MUTATION}>
      {(adminAddSupplierMutation, props) => (
        <SupplierCreatePage
          supplier={{
            _id: null,
            supplier: "",
            city: "",
            country: "",
            address: "",
            zipcode: "",
            link: "",
            notes: "",
            rating: "",
            estimatedResponseTime: "",
            handlingDuration: "",
            openingTimes: weekdays_opening_times,
            ubutlerPreferred: false,
            tags: [],
            timesUsed: 0,
            timesChosen: 0,
            contactPerson: {
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              other: "",
              eta: "",
              preferredLanguage: "",
              gender: null,
              reachHours: weekdays_opening_times,
            },
            roadmap: [],
            tradeOff: [],
          }}
          onSave={async newSupplier => saveSupplier(newSupplier)}
        />
      )}
    </Mutation>
  );
};

let SupplierEdit = ({ supplier_id }) => {
  let [updateSupplierMutation] = useMutation(UPDATE_SUPPLIER_MUTATION);
  let {
    data: { supplier },
  } = useQuery(SUPPLIER_QUERY, {
    suspend: true,
    variables: {
      token: getToken(),
      supplierId: supplier_id,
    },
  });
  return (
    <SupplierEditPage
      key={supplier._id}
      supplier={remove_typename(supplier)}
      onSave={updatedSupplier => {
        let { _id, __typename, ...supplier } = updatedSupplier;
        updateSupplierMutation({
          variables: {
            supplier: supplier,
            _id,
            token: getToken(),
          },
        });
      }}
    />
  );
};
