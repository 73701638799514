import React from "react";
import styled from "styled-components";

export const EditingFormGroupSubtitle = ({ isGEField }) => {
  return (
    <Subtitle>
      {!isGEField &&
        "If the GE field is not filled in this will be the default text that the GE will see."}
      {isGEField && "This field is only visible to the GE's!"}
    </Subtitle>
  );
};

const Subtitle = styled("p")`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 6px;
`;
