import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { flatten_ids } from "../../Graphql.js";
import FormRow from "../Styled/FormRow";
import { Steps } from "../Steps/Steps";
import { Content, Info, QA, Supplier, Phases, OldHandling } from "./Sections";

export const SubmitForm = props => {
  return (
    <FormRow style={{ position: "sticky", bottom: 0 }}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={props.handleSubmit}
      >
        Save
      </Button>
    </FormRow>
  );
};

class InnerHandlingPlanForm extends React.Component {
  state = {
    currentTab: "info",
    enabled: false,
    title: "",
    description: "",
    region: "NL",
    requirements: [],
    sensitiveInfo: [],
    suppliers: [],
    content: [],
    steps: [],
    proposalExample: "",
    considerations: "",
    questionsAndAnswers: [],
    serviceId: null,
  };

  get currentSuppliers() {
    // This should be fine just being done using graphql nesting
    return this.state.suppliers.map(supplierId =>
      this.props.suppliers.find(supplier => supplier._id === supplierId)
    );
  }

  addSupplier = ({ _id }) => {
    this.setState({
      suppliers: [...this.state.suppliers, _id],
    });
  };

  removeSupplier = option => {
    this.setState({
      suppliers: this.state.suppliers.filter(
        supplier => supplier !== option._id
      ),
    });
  };

  componentDidMount() {
    const { handlingPlan } = this.props;

    // Overwrite state if handling plan has that property.
    if (handlingPlan != null) {
      this.setState(
        Object.entries(this.state).reduce((newState, [key, value]) => {
          newState[key] = handlingPlan[key] || value;
          return newState;
        }, {})
      );
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    const { currentTab, ...handlingPlan } = this.state;

    this.props.onSubmit(
      flatten_ids(handlingPlan, (value, key) => {
        if (key === "questionsAndAnswers") {
          return { questionAndAnswerIds: value.map(x => x._id) };
        }
      })
    );
  };

  handleChange = (name, targetProperty = "value") => value => {
    this.setState({
      [name]: value.target ? value.target[targetProperty] : value,
    });
  };

  updatePlan = property => newValue => {
    this.setState({
      [property]: newValue,
    });
  };

  render() {
    const tabs = this.props.tabs || [this.state.currentTab];

    return (
      <>
        <Tabs
          value={this.state.currentTab}
          onChange={(e, newValue) => {
            const todo = this.props.location.pathname.split("/")[2];
            const newUrl = `/todos/${todo}/${newValue}`;
            this.setState({ currentTab: newValue });
            this.props.history.push(newUrl);
          }}
          style={{ background: "white", marginBottom: "40px" }}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs.map(tab => (
            <Tab value={tab} label={tab} key={tab} />
          ))}
        </Tabs>
        <Switch>
          <Route
            path="/todos/create/"
            render={() => (
              <Info
                enabled={this.state.enabled}
                title={this.state.title}
                description={this.state.description}
                requirements={this.state.requirements}
                onRequirementsChange={newValue => {
                  this.setState({ requirements: newValue });
                }}
                sensitiveInfo={this.state.sensitiveInfo}
                onSensitiveInfoChange={newValue => {
                  this.setState({ sensitiveInfo: newValue });
                }}
                region={this.state.region}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                serviceId={this.state.serviceId}
                onServiceIdChange={newServiceId => {
                  this.setState({ serviceId: newServiceId });
                }}
              />
            )}
          />
          <Route path="/todos/:id/">
            <Switch>
              <Route
                path="/todos/:id/content"
                render={() => (
                  <Content
                    content={this.state.content}
                    onContentChange={newContent => {
                      this.setState({ content: newContent });
                    }}
                    handleSubmit={this.handleSubmit}
                  />
                )}
              />
              <Route path="/todos/:id/phases" render={() => <Phases />} />
              <Route
                path="/todos/:id/qa"
                render={() => (
                  <QA
                    questionsAndAnswers={this.state.questionsAndAnswers}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                  />
                )}
              />
              <Route
                path="/todos/:id/suppliers"
                render={() => (
                  <Supplier
                    options={this.props.suppliers}
                    selectedOptions={this.currentSuppliers}
                    onUpdate={this.addSupplier}
                    onDelete={this.removeSupplier}
                    handleSubmit={this.handleSubmit}
                  />
                )}
              />
              <Route
                path="/todos/:id/old-handling"
                render={() => (
                  <OldHandling handlingPlan={this.props.handlingPlan} />
                )}
              />

              <Route path="/todos/:id/steps" component={Steps} />
              <Route
                render={() => (
                  <Info
                    enabled={this.state.enabled}
                    title={this.state.title}
                    description={this.state.description}
                    onRequirementsChange={newValue => {
                      this.setState({ requirements: newValue });
                    }}
                    sensitiveInfo={this.state.sensitiveInfo}
                    onSensitiveInfoChange={newValue => {
                      this.setState({ sensitiveInfo: newValue });
                    }}
                    region={this.state.region}
                    requirements={this.state.requirements}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    serviceId={this.state.serviceId}
                    onServiceIdChange={newServiceId => {
                      this.setState({ serviceId: newServiceId });
                    }}
                  />
                )}
              />
            </Switch>
          </Route>
        </Switch>
      </>
    );
  }
}

export const HandlingPlanForm = withRouter(InnerHandlingPlanForm);
