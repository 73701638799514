import TextField from "@material-ui/core/TextField";
import React from "react";

export const ImageInput = ({
  autofocus = false,
  label = "Image url",
  onChange,
  required = false,
  value = "",
}) => {
  return (
    <div>
      <TextField
        className="input input__text"
        label={label}
        required={required}
        fullWidth={true}
        autoFocus={autofocus}
        variant="filled"
        value={value}
        onChange={event => onChange(event.target.value)}
        style={{ marginBottom: 20 }}
      />
      {value.trim() !== "" && (
        <img src={value} alt="Not found" style={{ height: 315 }} />
      )}
    </div>
  );
};
